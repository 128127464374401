import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterProducerRequest } from 'src/app/core/models/api/auth/requests/register/register-producer.request.model';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-master-producer-form',
  styleUrls: ['./producer.component.scss', '../../../../forms.scss'],
  template: `
    <form
      ngClass="custom_form"
      [formGroup]="ProducerSignUpFormGroup"
      (ngSubmit)="OnSubmit(ProducerSignUpFormGroup.value)">
      <div ngClass="form_group_inline">
        <app-form-input
          ngClass="form_item"
          type="your-first-name"
          title="Your First Name"
          [Wide]="true"
          placeholder=" "
          fieldName="yourFirstName"
          [parentForm]="ProducerSignUpFormGroup"
          formControlName="yourFirstName"></app-form-input>

        <app-form-input
          ngClass="form_item"
          type="text"
          [Wide]="true"
          title="Your Last Name"
          placeholder=" "
          fieldName="yourLastName"
          [parentForm]="ProducerSignUpFormGroup"
          formControlName="yourLastName"></app-form-input>
        <app-form-input
          type="text"
          [Wide]="true"
          ngClass="form_item"
          title="Your Company Name"
          placeholder=" "
          fieldName="yourCompanyName"
          [parentForm]="ProducerSignUpFormGroup"
          formControlName="yourCompanyName"></app-form-input>
        <app-form-input
          type="email"
          [Wide]="true"
          title="Your Email"
          ngClass="form_item"
          placeholder=" "
          fieldName="email"
          [parentForm]="ProducerSignUpFormGroup"
          formControlName="email"></app-form-input>

        <app-form-input
          type="text"
          title="TIN/SSN"
          [Wide]="true"
          ngClass="form_item"
          placeholder=" "
          fieldName="tin"
          [parentForm]="ProducerSignUpFormGroup"
          formControlName="tin"></app-form-input>

        <app-form-input
          type="text"
          title="Requested Username"
          [Wide]="true"
          ngClass="form_item"
          placeholder=" "
          fieldName="reqUserName"
          [parentForm]="ProducerSignUpFormGroup"
          formControlName="reqUserName"></app-form-input>
      </div>
      <custom-button
        [forceCapitalization]="true"
        ngClass="form_item forms_button"
        type="submit"
        class="btn"
        label="Sign Up"
        [primary]="true"
        (onClick)="OnSubmit(ProducerSignUpFormGroup.value)"></custom-button>
    </form>
  `,
})
export class ProducerSignUpFormComponent implements OnInit {
  ProducerSignUpFormGroup: FormGroup = this.formBuilder.group({
    yourFirstName: ['', Validators.required],
    yourLastName: ['', Validators.required],
    yourCompanyName: ['', Validators.required],
    email: ['', Validators.required],
    tin: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
    reqUserName: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  @Output()
  OnSubmit(producer: ProducerSignUpForm) {
    if (this.ProducerSignUpFormGroup.invalid) {
      console.log('Invalid', this.ProducerSignUpFormGroup);
      return;
    }

    this.authService
      .Register(
        new RegisterProducerRequest(
          producer.yourFirstName,
          producer.yourLastName,
          producer.email,
          producer.yourCompanyName,
          producer.reqUserName,
          producer.tin
        )
      )
      .subscribe(response => {
        let { status: isRegistred, message } = response;

        if (!isRegistred) {
          this.notificationService.error(message);
          return;
        }

        this.notificationService.success(message);
        this.router.navigate(['/auth/signin']);
      });
  }

  ngOnInit(): void {}
}

type ProducerSignUpForm = {
  username: string;
  yourFirstName: string;
  yourLastName: string;
  yourCompanyName: string;
  yourEmployerNumber: string;
  email: string;
  tin: string;
  reqUserName: string;
};
