import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
  })
export class ModalService{

    constructor(){
      this.errorModal = new BehaviorSubject<boolean>(false);
    }
    errorModal: BehaviorSubject<boolean>;
    errorMessage: string;
    openModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    updateErrorModal(val: boolean){
      this.errorModal.next(val);
    }
}