import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { faCircleNotch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { EmployerService } from 'src/app/core/services/http/employer.service';
import { Observable, concatMap, distinctUntilChanged, map, max, of, switchMap, tap } from 'rxjs';
import { EmployersApiResponse } from 'src/app/core/models/api/employer/employer.response';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { FeatureFlagService } from 'src/app/core/services/http/feature-flag.service';
import {
  Dependent,
  EmployeeFormComponent,
  EmployeeFormFields,
  Plan,
} from '../components/employee-form/employee-form.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import CustomValidator from './validators';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import moment from 'moment';
import { should } from 'chai';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { IDependent1, IPlans, IPostEmployee } from './employee-form.request';
import { EmployeeControlService } from 'src/app/core/services/http/employee-control.service';
import { FormControlWarn, FormGroupWarn } from 'src/app/shared/components/form-elements/form-group-warn';
import { markAllFormFieldsAsTouched } from 'src/app/core/helpers/form-helpers';
import { EditDependent, EditEmployee, EditPlan } from 'src/app/core/models/api/employee/get-employee.response';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { EmployeeFormService } from '../components/employee-form/employee-form.service';
import { product } from 'ramda';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { isString } from 'lodash-es';
import { FileService } from 'src/app/core/services/file.service';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { EmailConfigurationComponent } from '../../email/email-configuration/email-configuration.component';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-add-employee',
  styleUrls: ['./add-employee.component.scss'],
  template: `
    <ng-container *ngIf="employer$ | withLoading | async as employerData">
      <!-- Loading -->
      <div *ngIf="employerData.loading || printing" ngClass="loading_spinner">
        <fa-icon [icon]="spinnerIcon" [spin]="true" size="8x" style="color: var(--brandColor)"></fa-icon>
      </div>
      <app-employee-form
        *ngIf="employerData.value && !printing"
        [viewOnly]="viewOnly"
        [employeeFormGroup]="form"
        (onCreateFormGroup)="onCreateFormGroup($event)"
        (onSubmit)="onSubmit($event)"
        (onReinstateEmployee)="onReinstateEmployee($event)"
        (dependentChanges)="dependentChanges($event)"
        (formFieldsChanges)="onFormFieldsChanges($event)"
        [employerFormFields]="employerFormFields"
        [enableAddDependent]="enableAddDependent"
        [onAddDependent]="onAddDependent"
        [onQualifyingEventAddDependent]="onQualifyingEventAddDependent"
        [submitDisabled]="submitDisabled"
        (onDeleteDependent)="onDeleteDependent($event)"
        (SubmitEmployeeRequest)="submitEmployeeRequest($event)"
        (onReinstateDependent)="onReinstateDependent($event)"
        (onDeleteEmployee)="onDeleteEmployee($event)"
        [employeeId]="employeeId"
        [editing]="isEditing"
        [employeeReplicated]="employeeReplicated"
        [showCobraQuestionsModal]="showCobraQuestionsModal"
        [enableAddQualifyingEventDependent]="enableAddQualifyingEventDependent"
        (generatePdf)="generatePDF()"
        [qualifyingEventDepOptions]="qualifyingEventDepOptions"
        [isGeneratePdfDisabled]="isGeneratePdfDisabled"
        [formCardFirstTitle]="employeeFormTitle"></app-employee-form>
      <app-print-employee
        *ngIf="employerData.value && isEditing"
        [employeeData]="employee"
        [qualifyingEventOptions]="qualifyingEventOptions"
        [terminationReasonOptions]="terminationReasonOptions"
        [qualifyingEventDepOptions]="qualifyingEventDepOptions"></app-print-employee>
    </ng-container>
  `,
})
export class AddEmployeeComponent implements OnInit, AfterViewInit {
  spinnerIcon = faCircleNotch;

  addIcon = faPlus;
  employerId: string;
  employeeId: number;
  isEditing: boolean;
  isAdmin: boolean;
  eeTerminated: boolean;
  reinstateAllowed: boolean;
  verifyAddress: boolean;
  checkCobraOnSave: boolean;
  employeeReplicated: boolean;
  submitDisabled: boolean;
  waitingPeriod: string;
  PPO: boolean;
  P3Mandatory: boolean;
  showCobraQuestionsModal: boolean = false;
  enableAddQualifyingEventDependent: boolean = false;
  enableAddDependent: boolean = false;
  qeFeatureFlag: boolean;
  cobraTypeFeatureFlag: boolean;
  openEnrollmentMonth: number;
  openEnrollmentDate: Date;
  addOE: boolean = false;
  form: FormGroupWarn;
  dependentsDeleted: string[] = [];
  currentPdfY: number = 0;
  isGeneratePdfDisabled: boolean = true;
  printing: boolean = false;
  addingWithTermination: boolean = false;
  editingWithFutureTermination: boolean = false;

  @Input() employeeFormTitle = 'Add Employee Record';
  @Input() qeSelected: boolean = false;
  @Input() qeDate: Date;
  @Input() qualifyingEvent: number;
  @Input() isOpenEnrollment: boolean = false;
  @Output() formEvent: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Input() viewOnly: boolean = false;

  //TODO - MIGRATE THE ACCESS OF THIS TO EMPLOYEE FORM SERVICE SO VIEWCHILD IS NOT NECESSARY
  @ViewChild(EmployeeFormComponent) employeeForm: EmployeeFormComponent;

  employer$: Observable<EmployersApiResponse>;
  employer: EmployersApiResponse;
  employerFormFields: EmployeeFormFields;
  employee: EditEmployee;

  events: {
    onFormChange: Observable<any>;
    onHireDateChange: Observable<any> | undefined;
  };

  terminationDateOptions: SelectOptions<string>[] = [
    { key: moment().startOf('month').format('L'), optionText: moment().startOf('month').format('L') },
    {
      key: moment().add(1, 'month').startOf('month').format('L'),
      optionText: moment().add(1, 'month').startOf('month').format('L'),
    },
    {
      key: moment().add(2, 'month').startOf('month').format('L'),
      optionText: moment().add(2, 'month').startOf('month').format('L'),
    },
  ];

  qualifyingEventDepOptions: SelectOptions<string>[] = [
    {
      key: 'MR',
      optionText: 'Marriage',
    },
    {
      key: 'CB',
      optionText: 'Childbirth',
    },
    {
      key: 'CA',
      optionText: 'Child adoption',
    },
    {
      key: 'LPC',
      optionText: 'Loss of prior coverage',
    },
    {
      key: 'CG',
      optionText: 'Child Guardianship',
    },
  ];

  terminationReasonOptions: SelectOptions<string>[];
  qualifyingEventOptions: SelectOptions<any>[];
  employerPlans: Plan[];

  constructor (
    private formBuilder: FormBuilder,
    private employerService: EmployerService,
    private userviewService: UserViewService,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
    private employeeService: EmployeeControlService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private employeeFormService: EmployeeFormService,
    private fileService: FileService
  ) {}

  ngAfterViewInit (): void {}

  ngAfterViewChecked (): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit () {
    this.isAdmin = this.authService.IsAdmin();
    const id = this.route.snapshot.paramMap.get('id');
    this.addOE = this.isOpenEnrollment;

    if (id != null && id != undefined) {
      this.employeeId = parseInt(id);
      this.isEditing = true;
    } else {
      this.isEditing = false;
    }

    if (this.viewOnly || this.isEditing) this.isGeneratePdfDisabled = false;

    this.enableAddDependent = true;
    this.showCobraQuestionsModal = false;

    this.initForm();

    let employerObservable = this.loadEmployerInfo();

    this.loadSettings();
    if (employerObservable)
      this.employer$ = employerObservable.pipe(
        this.addPlansOnEmployerInfo(),
        switchMap(res => {
          if (this.isEditing === true) {
            return this.employeeService.GetEmployee(this.employeeId, this.employerId).pipe(
              map(response => {
                if (response.status === true) {
                  let employee = response.response;
                  this.employee = employee?.[0];
                  if(this.employee !== undefined && this.employee !== null)
                    {
                      if( IsStringNullOrEmpty(this.employer.Data.ContractTerminationDate) === false &&
                      this.employeeFormService.isDateAfter(
                        moment(this.employer.Data.ContractTerminationDate).format('L'),
                        moment().format('L')) &&
                        (this.employeeFormService.isDateAfter(
                          moment(this.employee.TerminationDate).format('L'),
                          moment().format('L')) || IsStringNullOrEmpty(this.employee.TerminationDate))
                      ){
                        this.editingWithFutureTermination = true;
                      }
                    }


                  this.employeeReplicated = this.employee.REPLICATION === true;
                  this.eeTerminated =
                    this.employee.TerminationDate != undefined && this.employee.TerminationDate != null;
                  this.reinstateAllowed = this.employee.Reinstate;
                  this.enableAddQualifyingEventDependent =
                    this.employeeReplicated &&
                    ((!this.employee.Reinstate) || (this.editingWithFutureTermination)) &&
                    !this.employerService.IsOpenEnrollmentSync(this.employer.Data.Oemonth) ;

                  if (this.isAdmin === false && this.waitingPeriod !== 'X') {
                    this.enableAddDependent =
                      (this.employeeReplicated === false && (this.eeTerminated === false || this.editingWithFutureTermination === true)) ||
                      (this.employerService.IsOpenEnrollmentSync(this.employer.Data.Oemonth) && (this.eeTerminated === false || this.editingWithFutureTermination === true));
                  }else{
                    this.enableAddDependent =
                      (this.eeTerminated === false || this.editingWithFutureTermination)
                  }

                  if (this.isEditing === true && employee?.[0].OE === true) {
                    this.addOE = true;
                  }

                  this.initForm(employee?.[0]);
                } else {
                  this.notificationService.error('Could not load employee information, reason: ' + response.message);
                }

                return res;
              })
            );
          } else {
            if (!IsStringNullOrEmpty(res.Data.ContractTerminationDate)) {
              setTimeout(() => {
                const infoForm = this.form?.get('info');
                infoForm?.patchValue({ terminationDate: this.getDateFromString(res.Data.ContractTerminationDate) });
                this.employeeFormService.employeeFields.value.infoFields.terminationDate.field.show = true;
                this.employeeFormService.employeeFields.value.infoFields.terminationDate.field.readonly = true;
                this.employeeFormService.employeeFields.value.infoFields.terminationDate.showTerminationDateAsSelect =
                  false;
                this.employeeFormService.employeeFields.value.infoFields.terminationReason.field.show = true;
                this.employeeFormService.employeeFields.value.infoFields.terminationReason.field.disable = false;
                this.employeeFormService.employeeFields.value.infoFields.terminationReason.terminationReasonOptions = this.terminationReasonOptions;
                
                this.employeeFormService.employeeFields.value.infoFields.cobra.disable = false;

                this.checkCobraOnSave = true;
                this.form.get(['info','terminationReason'])?.enable()

                if(this.verifyAddress)
                  this.form.get(['info','terminationReason'])?.setValidators([Validators.required, CustomValidator.EmptyStringValidator]);

              }, 100);
            }
          }
          return of(res);
        })
      );
  }

  loadSettings () {
    this.employerService.GetP3Mandatory(this.employerId).subscribe(result => {
      this.P3Mandatory = result;
    });

    this.featureFlagService.IsFlagEnabled(this.featureFlagService.COBRATYPES).subscribe(result => {
      this.cobraTypeFeatureFlag = result;
    });
    this.featureFlagService.IsFlagEnabled(this.featureFlagService.QUALIFYINGEVENT).subscribe(result => {
      this.qeFeatureFlag = result;

      this.loadQualifyingEventOptions();
    });

    if (this.isOpenEnrollment === true || this.isEditing === true) {
      this.employerService.IsOpenEnrollmentAsync(parseInt(this.employerId)).subscribe(result => {
        this.isOpenEnrollment = result;
      });
    }
  }

  loadQualifyingEventOptions () {
    this.qualifyingEventOptions = [];
    if (this.qeFeatureFlag) {
      this.employeeService.GetQualifyingEvent().subscribe(response => {
        response.map(option => {
          this.qualifyingEventOptions.push({ key: option.Id, optionText: option.Description });
        });
      });
    }
  }

  onFormFieldsChanges (fields: EmployeeFormFields) {
    this.employerFormFields = fields;
    console.log('formFieldsChanges', this.employerFormFields);
  }

  onCreateFormGroup (forms: FormGroupWarn) {
    this.form = forms;

    this.formEvent.emit(forms);

    this.events = {
      onFormChange: forms.valueChanges.pipe(distinctUntilChanged()),
      onHireDateChange: forms.get(['info', 'hireDate'])?.valueChanges.pipe(distinctUntilChanged()),
    };

    this.validations(forms);

    this.executeValidations();
  }

  validateHireDateForQE () {
    let hireDate = this.form.get(['info', 'hireDate'])?.value;

    if (this.isEditing) {
      let qualifyingEventDate = this.form.get(['info', 'qualifyingEventDate'])?.value;

      if (qualifyingEventDate !== null && qualifyingEventDate !== undefined && qualifyingEventDate !== '') {
        if (this.employeeFormService.isDateAfter(hireDate, qualifyingEventDate)) {
          this.form
            .get(['info', 'hireDate'])
            ?.setErrors({ customErrorMessage: 'Hire date cannot be beyond the Qualifying Event Date.' });
        } else {
          this.form.get(['info', 'hireDate'])?.setErrors({ customErrorMessage: null });
          this.form.get(['info', 'hireDate'])?.updateValueAndValidity();
        }
      }
    } else {
      if (this.qeSelected === true) {
        if (this.employeeFormService.isDateAfter(hireDate, moment(this.qeDate).format('L'))) {
          this.form
            .get(['info', 'hireDate'])
            ?.setErrors({ customErrorMessage: 'Hire date cannot be beyond the Qualifying Event Date' });
        }
      }
    }
  }

  public executeValidations () {
    //EE validations

    if (this.isAdmin === false && this.eeTerminated === true) {
      (this.form.get(['info', 'terminationDate']) as FormControlWarn).warnings =
        "To make changes to an employee's termination date, please contact Allied Administrators at cs@alliedadministrators.com";
    }

    this.validateWaitingPeriod();
    //EE plans validations

    // this.validateEffectiveDatesWithWaiverDate();

    // DEP validations
    let dependents = (this.form.get(['dependents']) as FormArray).controls;

    dependents.forEach((dependent, index) => {
      //birth date
      let birthDate = dependent.get('birthDate')?.value;

      this.birthDateChanges(birthDate, dependent as FormGroupWarn, false);
      //termination date
      let depField = this.employeeFormService.employeeFields.value.dependents[index];

      this.validateDependentPlanDates(dependent as FormGroupWarn, depField);
    });
  }

  validateWaitingPeriod () {
    if (this.waitingPeriod === 'X' && this.isAdmin === false && this.isEditing === true) {
      (this.form.get(['info', 'hireDate']) as FormControlWarn).warnings =
        'Your group has a non-standard waiting period. To modify any effective date or Hire Date, please contact your Account Coordinator.';
    } else if (this.waitingPeriod === 'X' && this.isAdmin === true) {
      (this.form.get(['info', 'hireDate']) as FormControlWarn).warnings =
        'This employer has a special waiting period. Please enter the effective date manually';
    }
  }

  validations (forms: FormGroupWarn) {
    this.events.onHireDateChange &&
      this.events.onHireDateChange.subscribe(hireDate => {
        this.onHireDateChange(hireDate);
      });

    let ssnChange = forms.get(['info', 'ssn'])?.valueChanges.pipe(distinctUntilChanged());

    // ssnChange &&
    //   ssnChange.subscribe(ssn => {
    //     this.checkDuplicateSSN();
    //   });

    let onCobraChange = forms.get(['info', 'cobra'])?.valueChanges.pipe(distinctUntilChanged());

    onCobraChange &&
      onCobraChange.subscribe(cobra => {
        this.toggleCobra();
      });

    let terminationDateChange = forms.get(['info', 'terminationDate'])?.valueChanges.pipe(distinctUntilChanged());

    terminationDateChange &&
      terminationDateChange.subscribe(terminationDate => {
        this.onTerminationDateChange(terminationDate);
      });

    let qualifyingEventDateChange = forms
      .get(['info', 'qualifyingEventDate'])
      ?.valueChanges.pipe(distinctUntilChanged());

    qualifyingEventDateChange &&
      qualifyingEventDateChange.subscribe(qualifyingEventDate => {
        let qualifyingEventDateControl = this.form.get(['info', 'qualifyingEventDate']);
        let minDate = moment().add(-60, 'days').format('L');

        if (this.isEditing === true) {
          if (
            moment(qualifyingEventDate).startOf('day').isSame(moment(this.employee.QualifyingEventDate).startOf('day'))
          ) {
            return;
          }
        }

        if (qualifyingEventDate === null || qualifyingEventDate === undefined || qualifyingEventDate === '') {
          qualifyingEventDateControl?.setErrors({
            customErrorMessage: 'You need to select the Qualifying event and Date to confirm.',
          });
          return;
        } else {
          qualifyingEventDateControl?.setErrors({ customErrorMessage: null });
          qualifyingEventDateControl?.updateValueAndValidity();
        }

        if (
          this.employeeFormService.isDateAfter(minDate, qualifyingEventDate) ||
          moment(qualifyingEventDate).isAfter(moment())
        ) {
          qualifyingEventDateControl?.setErrors({
            customErrorMessage: 'Please select a date between today and 60 days in the past.',
          });
          return;
        } else {
          qualifyingEventDateControl?.setErrors({ customErrorMessage: null });
          qualifyingEventDateControl?.updateValueAndValidity();
          let hireDate = this.form.get(['info', 'hireDate'])?.value;
          this.onHireDateChange(hireDate);
        }
      });

    let birthDateChanges = forms.get(['info', 'dateOfBirth'])?.valueChanges.pipe(distinctUntilChanged());

    birthDateChanges &&
      birthDateChanges.subscribe(birthDate => {
        this.validateBirthDate(birthDate);
      });

    let plansArray = forms.get(['plans']) as FormArray;

    let plans = plansArray.controls;

    plans.forEach(plan => {
      let doNotEnrollChange = plan.get('doNotEnroll')?.valueChanges.pipe(distinctUntilChanged());

      doNotEnrollChange &&
        doNotEnrollChange.subscribe(doNotEnroll => {
          this.toggleEmployeeDoNotEnroll(plan as FormGroupWarn);
        });

      let effectiveDateChange = plan.get('effectiveDate')?.valueChanges.pipe(distinctUntilChanged());

      effectiveDateChange &&
        effectiveDateChange.subscribe(effectiveDate => {
          let planTermDate = plan.get('terminationDate')?.value;

          if (IsStringNullOrEmpty(planTermDate)) {
            this.onEffectiveDateChange(plan as FormGroupWarn);
          }
        });

      let waiverDateChange = plan.get('waiverDate')?.valueChanges.pipe(distinctUntilChanged());

      waiverDateChange &&
        waiverDateChange.subscribe(waiverDate => {
          let productCode = plan.get('productCode')?.value;
          if (this.employeeReplicated !== true && this.isAdmin !== true) {
            if (moment(waiverDate).isValid() === true) {
              plan.patchValue({ effectiveDate: null });
            } else if (IsStringNullOrEmpty(waiverDate) === true) {
              let eeHireDate = this.form.get(['info', 'hireDate'])?.value;
              let effectiveDate = this.getEmployeeEffectiveDate(eeHireDate);

              plan.patchValue({ effectiveDate: effectiveDate });
            }
          }

          this.validateEmployeePlanDates();

          this.validateAtLeastOnePlan(plansArray, this.form);

          this.updateDependentsWaiverDate(productCode, waiverDate);
        });

      let terminationDateChange = plan.get('terminationDate')?.valueChanges.pipe(distinctUntilChanged());

      terminationDateChange &&
        terminationDateChange.subscribe(terminationDate => {
          this.onPlanTerminationDateChange(plan as FormGroupWarn);
        });
    });

    this.events.onFormChange &&
      this.events.onFormChange.subscribe(form => {
        // let firstName = forms.get(['info', 'firstName']);
        // Form Values change
      });
  }

  onEffectiveDateChange (plan: FormGroupWarn) {
    this.validateEmployeePlan(plan);

    this.updateDependentPlans();
  }

  validateBirthDate (birthDate: any) {
    if (birthDate === null || birthDate === undefined || birthDate === '') return;

    if (!moment(birthDate, 'L').isValid()) return;

    let minDate = new Date('1/1/1900');

    let birthdateControl = this.form.get(['info', 'dateOfBirth']);
    let hireDate = this.form.get(['info', 'hireDate'])?.value;

    if (this.employeeFormService.isDateAfter(moment(minDate).format('L'), birthDate)) {
      this.employeeFormService.setControlError(
        birthdateControl as FormControlWarn,
        'Birthdate cannot be prior to 1900'
      );
    } else if (this.employeeFormService.isDateAfter(birthDate, moment().format('L'))) {
      this.employeeFormService.setControlError(
        birthdateControl as FormControlWarn,
        'Birthdate cannot be in the future.'
      );
      // birthdateControl?.setErrors({ customErrorMessage: 'Birthdate cannot be in the future.' });
    } else if (this.employeeFormService.isDateAfter(birthDate, hireDate)) {
      // birthdateControl?.setErrors({ customErrorMessage: 'Birthdate cannot be after Hire Date.' });
      this.employeeFormService.setControlError(
        birthdateControl as FormControlWarn,
        'Birthdate cannot be after Hire Date.'
      );
    } else {
      this.employeeFormService.setControlError(birthdateControl as FormControlWarn, '');
      // birthdateControl?.updateValueAndValidity();
    }
  }

  onPlanTerminationDateChange (plan: FormGroupWarn) {
    let terminationDate = plan.get('terminationDate')?.value;

    let plans = this.form.get(['plans']) as FormArray;

    this.validateEmployeePlanDates(false);

    this.terminateDependentsPlans(plan);

    setTimeout(() => {
      this.validateAtLeastOnePlan(plans, this.form);
    }, 100);
  }

  onDeleteDependent (dependentId: string) {
    this.dependentsDeleted.push(dependentId);
  }

  validateQEDateEdit () {
    if (this.isEditing === false) return;

    let qualifyingEventDate = this.form.get(['info', 'qualifyingEventDate'])?.value;

    let eeQeDate = this.employee.QualifyingEventDate;

    let eeQe = this.employee.QualifyingEvent;

    let qualifyingEvent = this.form.get(['info', 'qualifyingEvent'])?.value;

    if (qualifyingEvent === undefined && eeQe === undefined) {
      this.form.get(['info', 'qualifyingEvent'])?.setErrors(null);
    }

    if (
      moment(qualifyingEventDate).startOf('day').isSame(moment(eeQeDate).startOf('day')) ||
      (eeQeDate === undefined && qualifyingEventDate === undefined)
    ) {
      // this.employeeFormService.setControlError(this.form.get(['info', 'qualifyingEventDate']) as FormControlWarn, '');
      this.form.get(['info', 'qualifyingEventDate'])?.setErrors(null);
    }
  }

  checkDuplicateSSN (): AsyncValidatorFn {
    return ssn => {
      if (ssn.parent === undefined || ssn.parent === null) return of(null);

      let eeSSN = this.form.get(['info', 'ssn'])?.value;

      if (this.isEditing === true) {
        if (this.employee.SSN === eeSSN) return of(null);
      }

      let employeeid = this.employeeId?.toString() ?? '';
      let employerid = this.employerId;

      return this.employeeService.checkSSNExists(eeSSN, employeeid, employerid).pipe(
        map(response => {
          if (response.status === false) {
            return {
              customErrorMessage: 'SSN already exists',
            };
          }
          return null;
        })
      );
    };
  }

  checkDependentsQualifyingEventDate () {
    let dependents = (this.form.get(['dependents']) as FormArray).controls;

    dependents.forEach((dependent, index) => {
      let depField = this.employeeFormService.employeeFields.value.dependents[index];
      if (depField.reinstateButton.show === true) return;

      if (
        depField.qualifyingEventDate?.value === dependent.get('qualifyingEventDate')?.value &&
        depField.qualifyingEventDate?.value !== undefined
      ) {
        dependent.get('qualifyingEventDate')?.setErrors(null);
        dependent.get('qualifyingEventDate')?.disable({ emitEvent: false });
      }
    });
  }

  onSubmit (form: FormGroupWarn) {
    //TODO - VERIFY HMO OPTIONS
    this.validateQEDateEdit();
    this.checkDependentsQualifyingEventDate();

    this.form.markAllAsTouched();
    this.form.markAsDirty();

    if (this.form.invalid === true) {
      return;
    }

    if (this.verifyAddress === true && this.checkCobraOnSave === true) {
      this.showCobraQuestionsModal = false;
      setTimeout(() => {
        this.showCobraQuestionsModal = true;
      }, 100);
      return;
    }
    this.submitEmployeeRequest(form);
  }

  submitEmployeeRequest (form: FormGroupWarn) {
    if (
      form.get(['info', 'address'])?.value === '' ||
      form.get(['info', 'address'])?.value === undefined ||
      form.get(['info', 'address2'])?.value === '' ||
      form.get(['info', 'address2'])?.value === undefined ||
      form.get(['info', 'state'])?.value === '' ||
      form.get(['info', 'state'])?.value === undefined ||
      form.get(['info', 'city'])?.value === '' ||
      form.get(['info', 'city'])?.value === undefined ||
      form.get(['info', 'zip5'])?.value === '' ||
      form.get(['info', 'zip5'])?.value === undefined ||
      form.get(['info', 'zip4'])?.value === '' ||
      form.get(['info', 'zip4'])?.value === undefined || //TODO - VERIFY DEPENDENTS WITH BDAY WARNING
      form.get(['info', 'phone'])?.value === '' ||
      form.get(['info', 'phone'])?.value === undefined //TODO - VERIFY COBRA WARNING
    ) {
      let warningMessage =
        '<b>"NOTE: This is just a warning message. If you would like to save this record in its current state, please press OK. And If you want to modify this record, please press "CANCEL".</b> <br><br>';

      //TODO - SET WARNING FOR DEPENDENTS
      ///TODO - SET EMPLOYEE DATE OF BIRTH WARNING MESSAGE
      if (form.get(['info', 'address'])?.value === '' || form.get(['info', 'address'])?.value === undefined) {
        warningMessage += ' Street address is blank.  <br>';
      }

      if (form.get(['info', 'address2'])?.value === '' || form.get(['info', 'address2'])?.value === undefined) {
        warningMessage += ' Street address 2 is blank.  <br>';
      }

      if (form.get(['info', 'city'])?.value === '' || form.get(['info', 'city'])?.value === undefined) {
        warningMessage += ' City is blank.  <br>';
      }
      if (form.get(['info', 'state'])?.value === '' || form.get(['info', 'state'])?.value === undefined) {
        warningMessage += ' State is blank.  <br>';
      }

      if (form.get(['info', 'zip5'])?.value === '' || form.get(['info', 'zip5'])?.value === undefined) {
        warningMessage += ' Zip5 is blank.  <br>';
      }

      if (form.get(['info', 'zip4'])?.value === '' || form.get(['info', 'zip4'])?.value === undefined) {
        warningMessage += ' Zip4 is blank.  <br>';
      }

      if (form.get(['info', 'phone'])?.value === '' || form.get(['info', 'phone'])?.value === undefined) {
        warningMessage += ' Phone is blank.  <br>';
      }
      //TODO - set cobra warning for dependents

      this.notificationService.confirmation(warningMessage, () => {
        this.saveEmployeeDetails(form);
      });
    } else {
      this.saveEmployeeDetails(form);
    }
  }

  private mapToEmployeeRequest (form: FormGroupWarn): IPostEmployee {
    let plans = form.get('plans') as FormArray;

    let sex = '';
    switch (form.get(['info', 'gender'])?.value) {
      case 'Male':
        sex = 'M';
        break;
      case 'Female':
        sex = 'F';
        break;
      case 'Non-Binary':
        sex = 'N';
        break;
    }

    let streetAddress = (form.get(['info', 'address'])?.value ?? '').replace(/\s+/g, ' ');
    let streetAddress2 = (form.get(['info', 'address2'])?.value ?? '').replace(/\s+/g, ' ');

    let employeeRequest: IPostEmployee = {
      Reinstated: form.get(['info', 'reinstated'])?.value === true,
      BirthDate: this.mapDateToUTC(form.get(['info', 'dateOfBirth'])?.value),
      City: form.get(['info', 'city'])?.value ?? '',
      Cobra: form.get(['info', 'cobra'])?.value === true ? '1' : '0' ?? '',
      EmployerID: parseInt(this.employerId),
      Firstname: form.get(['info', 'firstName'])?.value,
      HireDate: this.mapDateToUTC(form.get(['info', 'hireDate'])?.value),
      Lastname: form.get(['info', 'lastName'])?.value,
      MiddleName: form.get(['info', 'mI'])?.value ?? '',
      Phone: form.get(['info', 'phone'])?.value ?? '',
      Email: form.get(['info', 'email'])?.value ?? '',
      PlanNR: this.employer.Data.PlanNr,
      REPLICATION: this.isEditing ? (this.employee.REPLICATION ? 'true' : 'false') : 'false',
      TERMINATIONFLAG: '',
      Sex: sex,
      SSN: form.get(['info', 'ssn'])?.value,
      State: form.get(['info', 'state'])?.value ?? '',
      StreetAddress: streetAddress,
      StreetAddress2: streetAddress2,
      TerminationDate: this.mapDateToUTC(form.get(['info', 'terminationDate'])?.value),
      TerminationReason: form.get(['info', 'terminationReason'])?.value,
      Zip4: form.get(['info', 'zip4'])?.value ?? '',
      Zip5: form.get(['info', 'zip5'])?.value ?? '',
      OE: this.addOE,
      CobraEligibility: form.get('isCobraEligible')?.value === 'Yes',
      CobraAddressCorrect: form.get('isAddressCorrect')?.value === 'Yes',
      QualifyingEvent: this.isEditing ? form.get(['info', 'qualifyingEvent'])?.value : this.qualifyingEvent,
      QualifyingEventDate: this.mapDateToUTC(
        this.isEditing ? form.get(['info', 'qualifyingEventDate'])?.value : this.qeDate
      ),
      EffectiveDate: this.getEffectiveDateFromPlans(plans),
      depdel: this.dependentsDeleted,
      dependent: this.mapDependentsToRequest(form),
      plans: this.mapPlansToRequest(plans, null),
      Index: 0,
      EmployeeID: this.employeeId,
    };

    return employeeRequest;
  }

  private saveEmployeeDetails (form: FormGroupWarn) {
    let employeeRequest = this.mapToEmployeeRequest(form);
    this.submitDisabled = true;
    this.employeeService.PostEmployee(employeeRequest).subscribe(response => {
      if (response.status === false) {
        this.submitDisabled = false;
        this.notificationService.error(response.message);
      } else {
        this.notificationService.success(response.message);
        this.router.navigate([RouteEnum.Employees]);
      }
    });
  }

  private mapDependentsToRequest (form: FormGroupWarn): IDependent1[] {
    let dependents = (form.get('dependents') as FormArray).controls;

    let dependentsRequest: IDependent1[] = [];

    dependents.forEach(dependent => {
      let plans = dependent.get('plans') as FormArray;
      dependentsRequest[dependentsRequest.length] = {
        BirthDate: this.mapDateToUTC(dependent.get('birthDate')?.value),
        DependentID: dependent.get('dependentId')?.value,
        FirstName: dependent.get('firstName')?.value,
        LastName: dependent.get('lastName')?.value,
        TerminationDate: this.mapDateToUTC(dependent.get('terminationDate')?.value),
        TerminationReason: dependent.get('terminationReason')?.value,
        RelationshipCode: dependent.get('relationshipCode')?.value,
        Status: dependent.get('status')?.value,
        REPLICATION: dependent.get('depReplicated')?.value === true ? 'true' : 'false',
        Reinstated: dependent.get('reinstated')?.value,
        QualifyEventName: dependent.get('qualifyingEvent')?.value,
        QualifyEventDate: this.mapDateToUTC(dependent.get('qualifyingEventDate')?.value),
        plans: this.mapPlansToRequest(plans, dependent.get('dependentId')?.value),
        CobraEligibility: dependent.get('isCobraEligible')?.value === 'Yes',
        AddressDifferentFromEmployee: dependent.get(['isDependentAdressSameAsEmployee'])?.value === 'No',
        City: dependent.get(['info', 'city'])?.value,
        State: dependent.get(['info', 'state'])?.value,
        StreetAddress: (dependent.get(['info', 'address'])?.value ?? '').replace(/\s+/g, ' '),
        StreetAddress2: (dependent.get(['info', 'address2'])?.value ?? '').replace(/\s+/g, ' '),
        Zip4: dependent.get(['info', 'zip4'])?.value,
        Zip5: dependent.get(['info', 'zip5'])?.value,
        Phone: dependent.get(['info', 'phone'])?.value,
        SSN: '',
        Index: dependentsRequest.length,
      };
    });
    return dependentsRequest;
  }

  private mapPlansToRequest (plansArray: FormArray, dependentId: number | null): IPlans[] {
    let plans = plansArray.controls;

    let plansRequest: IPlans[] = [];

    plans.forEach(plan => {
      plansRequest[plansRequest.length] = {
        DentalOffice: plan.get('dentalOffice')?.value,
        DependentID: dependentId,
        EffectiveDate: this.mapDateToUTC(plan.get('effectiveDate')?.value),
        ProductCode: plan.get('productCode')?.value,
        TerminationDate: this.mapDateToUTC(plan.get('terminationDate')?.value),
        WaiverEffectiveDate: this.mapDateToUTC(plan.get('waiverDate')?.value),
        DoNotEnroll: plan.get('doNotEnroll')?.value,
      };
    });
    plansRequest = plansRequest.filter(
      plan =>
        IsStringNullOrEmpty(plan.EffectiveDate) === false ||
        IsStringNullOrEmpty(plan.WaiverEffectiveDate) === false ||
        IsStringNullOrEmpty(plan.TerminationDate) === false ||
        (plan.DoNotEnroll !== null && plan.DoNotEnroll !== undefined)
    );

    return plansRequest;
  }

  private getEffectiveDateFromPlans (plansArray: FormArray): string {
    let effectiveDate = '';

    plansArray.controls.forEach(plan => {
      if (effectiveDate == '') {
        effectiveDate = this.mapDateToUTC(plan.get('effectiveDate')?.value);
      }
    });

    return effectiveDate;
  }

  toggleCobra () {
    this.validateEmployeePlanDates();

    this.updateDependentPlans(false);
  }

  toggleEmployeeDoNotEnroll (plan: FormGroupWarn) {
    let eeDoNotEnroll = plan.get('doNotEnroll')?.value;
    let eeProductCode = plan.get('productCode')?.value;
    let productCode = plan.get('productCode')?.value;
    let cobra = this.form.get(['info', 'cobra'])?.value === '1';

    if (this.shouldDisplayDoNotEnroll(productCode) === false) return;

    if (eeDoNotEnroll === true) {
      plan.patchValue({ effectiveDate: null });
      plan.patchValue({ terminationDate: null });

      plan.get('effectiveDate')?.disable();
      plan.get('terminationDate')?.disable();
    } else if (eeDoNotEnroll === false) {
      let eeTerminationDate = this.form.get(['info', 'terminationDate'])?.value;
      let plans = (this.form.get('plans') as FormArray).controls;
      //TODO - VERIFY IF VALUE HERE IS BOOLEAN OR NOT

      let isDeltaOnly = plans.length === 1 && plans[0].get('productCode')?.value === 'Dental';

      let shouldEffectiveDateBeDisabled = this.shouldPlanEffectiveDateBeDisabled(
        eeDoNotEnroll,
        eeTerminationDate,
        false
      );
      let shouldPlanTerminationDateBeDisabled = this.shouldPlanTerminationDateBeDisabled(
        eeDoNotEnroll,
        false,
        eeTerminationDate,
        productCode,
        isDeltaOnly,
        cobra
      );

      if (shouldEffectiveDateBeDisabled === false && this.isAdmin === true) plan.get('effectiveDate')?.enable();
      if (shouldPlanTerminationDateBeDisabled === false) plan.get('terminationDate')?.enable();

      let hireDate = this.form.get(['info', 'hireDate'])?.value;

      let planEffectiveDate = this.getEffectiveDate(
        plan.get('productCode')?.value,
        this.getEmployeeEffectiveDate(hireDate)
      );

      plan.patchValue({ effectiveDate: planEffectiveDate });

      if(this.addingWithTermination || this.editingWithFutureTermination)
      {
        const erPlan = this.getEmployerPlan(plan.get('productCode')?.value);

        if(!erPlan) return;

        plan.patchValue({terminationDate: erPlan.TerminationDate})
        plan.get('terminationDate')?.disable()
      }

    }
    this.validateEmployeePlanDates();
    // if(this.isEditing === false ){
    this.toggleDependentPlan(productCode, !eeDoNotEnroll);
    // }

    let dependents = (this.form.get('dependents') as FormArray).controls;

    // let eePlan = this.employee.Plans.find(plan => plan.ProductCode === eeProductCode);

    dependents.forEach((dependent, index) => {
      let isDeltaOnly = (dependent.get('plans') as FormArray).controls.length === 1;
      let plans = (dependent.get('plans') as FormArray).controls.filter(
        x => x.get('productCode')?.value === productCode
      );

      plans.forEach(dependentPlan => {
        if (eeDoNotEnroll === true) {
          dependentPlan.get('effectiveDate')?.disable();
          dependentPlan.get('terminationDate')?.disable();
          dependentPlan.get('doNotEnroll')?.disable();
        } else {
          let doNotEnroll = plan.get('doNotEnroll')?.value;
          let depTerminationDate = dependent.get('terminationDate')?.value;
          let productCode = plan.get('productCode')?.value;
          let shouldDepEffectiveDateBeDisbled = this.shouldPlanEffectiveDateBeDisabled(
            doNotEnroll,
            depTerminationDate,
            false
          );
          let shouldDepTerminationDateBeDisabled = this.shouldPlanTerminationDateBeDisabled(
            doNotEnroll,
            false,
            depTerminationDate,
            productCode,
            isDeltaOnly,
            cobra
          );

          if (shouldDepEffectiveDateBeDisbled === false) {
            dependentPlan.get('effectiveDate')?.enable();
          }
          if (shouldDepTerminationDateBeDisabled === false) {
            dependentPlan.get('terminationDate')?.enable();
          }
          dependentPlan.get('doNotEnroll')?.enable();
        }

        let productCode = dependentPlan.get('productCode')?.value;
        if (productCode == eeProductCode) {
          dependentPlan.patchValue({ doNotEnroll: eeDoNotEnroll });
        }

        this.toggleDependentDoNotEnroll(dependentPlan as FormGroupWarn, dependent as FormGroupWarn, index);
      });
    });
  }

  addPlanToDependent (dependent: any, plan: any, employee?: EditEmployee) {
    let isDeltaOnly = dependent.plans.length === 1 && dependent.plans[0].ProductCode === 'Dental';
    let cobra = employee?.Cobra === '1';
    let erPlanTerminated =
      this.isEditing === false
        ? false
        : employee?.Plans?.some(x => x.ProductCode === plan.ProductCode && x.ErTerminatedPlan === true);
    let xCompany = this.isAdmin === false && this.waitingPeriod === 'X';

    let eePlanHasWaiverDate = employee?.Plans?.some(
      x => x.ProductCode === plan.ProductCode && IsStringNullOrEmpty(x.WaiverEffectiveDate) === false
    );
    let dependentProduct = {
      replicated: {
        disable: false,
        show: true,
        value: plan.Replicated,
      },
      productCode: {
        disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
        show: true,
        value: plan.ProductCode,
        validators: [Validators.required],
      },
      productName: {
        disable: false,
        show: true,
        value: plan.productName,
        validators: [],
      },
      effectiveDate: {
        disable:
          this.shouldPlanEffectiveDateBeDisabled(
            plan.DoNotEnroll,
            dependent.TerminationDate,
            dependent.Reinstate === true
          ) || erPlanTerminated === true,
        readonly: this.isAdmin === false,
        show: true,
        value: this.getDateFromString(plan.EffectiveDate),
      },
      waiverDate: {
        disable:
          dependent.Reinstate ||
          IsStringNullOrEmpty(dependent.TerminationDate) === false ||
          erPlanTerminated === true ||
          xCompany,
        readonly: this.isAdmin === false && dependent.DepReplicated === true,
        show: (plan.ProductCode == 'Dental' || plan.ProductCode == 'EyeMed') && dependent.plans.length > 1,
        value: this.getDateFromString(plan.WaiverEffectiveDate),
        validators: [this.validateDependentWaiverDates.bind(this)],
      },
      dentalOffice: {
        disable:
          dependent.Reinstate ||
          this.isAdmin !== true ||
          IsStringNullOrEmpty(dependent.TerminationDate) === false ||
          xCompany,
        show: this.PPO === false && plan.ProductCode === 'Dental',
        value: plan.DentalOffice,
        validators: [Validators.maxLength(6)],
      },
      terminationDate: {
        field: {
          disable:
            this.shouldPlanTerminationDateBeDisabled(
              plan.DoNotEnroll,
              dependent.Reinstate === true,
              dependent.TerminationDate,
              plan.ProductCode,
              isDeltaOnly,
              cobra
            ) ||
            erPlanTerminated === true ||
            dependent.Reinstate ||
            IsStringNullOrEmpty(dependent.TerminationDate) === false ||
            (this.shouldDisplayDoNotEnroll(plan.ProductCode) && plan.doNotEnroll === true && xCompany),
          show:
            this.isEditing ||
            !this.shouldDisplayDoNotEnroll(plan.ProductCode) ||
            IsStringNullOrEmpty(plan.terminationDate) === false,
          value: IsStringNullOrEmpty(plan.TerminationDate) === false ? moment(plan.TerminationDate).format('L') : '',
          readonly: plan.ProductCode === 'Dental' || (this.isAdmin === false && dependent.DepReplicated === false),
        },
        showTerminationDateAsSelect:
          this.authService.IsAdmin() === false &&
          IsStringNullOrEmpty(dependent.TerminationDate) === true &&
          (IsStringNullOrEmpty(plan.TerminationDate) === true ||
            this.terminationDateOptions.some(x => x.key === moment(plan.TerminationDate).format('L'))),
        terminationDateOptions: this.terminationDateOptions,
      },
      doNotEnroll: {
        disable:
          dependent.Reinstate ||
          IsStringNullOrEmpty(dependent.TerminationDate) === false ||
          erPlanTerminated === true ||
          xCompany,
        show:
          this.shouldDisplayDoNotEnroll(plan.ProductCode) &&
          ((this.isEditing &&
            plan.DoNotEnroll === true &&
            (dependent.DepReplicated === false || plan.Replicated == false)) ||
            this.isEditing === false ||
            (this.isEditing === true && plan.Replicated === false) ||
            this.isAdmin === true),
        value: plan.DoNotEnroll,
      },
    } as Plan;

    if (eePlanHasWaiverDate === true) {
      dependentProduct.waiverDate.validators?.push(Validators.required);
    }

    return dependentProduct;
  }

  validateAtLeastOnePlan (plans: FormArray, controlGroup: FormGroupWarn) {
    let plansActive = plans.controls.some(
      plan =>
        (((plan as FormGroupWarn).controls['terminationDate']?.value === '' ||
          (plan as FormGroupWarn).controls['terminationDate']?.value === null ||
          (plan as FormGroupWarn).controls['terminationDate']?.value === undefined ||
          (plan as FormGroupWarn).controls['terminationDate']?.value === 'Invalid Date') || (this.addingWithTermination || this.editingWithFutureTermination) ) &&
        ((plan as FormGroupWarn).controls['doNotEnroll']?.value === false ||
          (plan as FormGroupWarn).controls['doNotEnroll']?.value === null ||
          (plan as FormGroupWarn).controls['doNotEnroll']?.value === undefined) &&
        ((plan as FormGroupWarn).controls['waiverDate']?.value === '' ||
          (plan as FormGroupWarn).controls['waiverDate']?.value === null ||
          (plan as FormGroupWarn).controls['waiverDate']?.value === undefined ||
          (plan as FormGroupWarn).controls['waiverDate']?.value === 'Invalid Date')
    );

    if(!this.addingWithTermination && !this.editingWithFutureTermination)
      {
        if (IsStringNullOrEmpty(this.form.get(['info', 'terminationDate'])?.value) === false) {
      plansActive = true;
    }
    if (IsStringNullOrEmpty(controlGroup.get('terminationDate')?.value) === false) {
      plansActive = true;
    }

      }

    if (plansActive === false) {
      this.employeeFormService.setControlGroupPlansError(controlGroup, 'At least one plan must be active.');

      return;
    } else {
      controlGroup?.setErrors({ plansError: null });
      controlGroup?.updateValueAndValidity();
    }
  }

  validateHireDate (hireDate: Date, forms: FormGroupWarn) {
    var birthDate = forms.get(['info', 'dateOfBirth'])?.value;
    if (birthDate && this.employeeFormService.isDateAfter(birthDate, moment(hireDate).format('L'))) {
      forms.get(['info', 'hireDate'])?.setErrors({ customErrorMessage: 'Hire date cannot be prior to Birth Date' });
      return;
    } else {
      forms.get(['info', 'hireDate'])?.setErrors({ customErrorMessage: null });
      forms.get(['info', 'hireDate'])?.updateValueAndValidity();
    }
  }

  validateRangeDate (hireDate: Date) {
    let maxDate = moment().add(4, 'month').startOf('month').format('L');
    if (this.isAdmin === true) {
      maxDate = moment().add(12, 'month').startOf('month').format('L');
    }
    let minDate = moment().add(-60, 'year').startOf('month').format('L');

    if (this.isAdmin === false && this.isOpenEnrollment === false) {
      let rangeDates = this.employeeFormService.getHireDateRangeFromWaitingPeriod(this.waitingPeriod);

      maxDate = rangeDates.maxDate;
      minDate = rangeDates.minDate;
    }

    let hireDateErrorMessage = 'Hire date must be between ' + minDate + ' and ' + maxDate + '.';

    if (this.isAdmin === false) {
      hireDateErrorMessage += ' Please contact Allied Administrators at cs@alliedadministrators.com';
    }

    if (moment(hireDate, 'L').isAfter(maxDate) || moment(hireDate, 'L').isBefore(minDate)) {
      this.form.get(['info', 'hireDate'])?.setErrors({ customErrorMessage: hireDateErrorMessage });
      return;
    }
  }

  calculateQeEffectiveDate (): Date {
    if (this.isEditing && this.qeSelected) {
      let qualifyingEventDate = this.form.get(['info', 'qualifyingEventDate'])?.value;
      return new Date(moment(qualifyingEventDate, 'L').add(1, 'month').startOf('month').format('L'));
    }

    return new Date(moment(this.qeDate, 'L').add(1, 'month').startOf('month').format('L'));
  }

  calculateWaitingPeriod (baseDate: Date): Date {
    let effectiveDate: Date;
    effectiveDate = baseDate;
    switch (this.waitingPeriod) {
      case 'X':
        //TODO - IMPLEMENT X CASE
        break;
      case 'C':
      case '0':
        effectiveDate = baseDate;
        break;
      case 'N':
        effectiveDate = new Date(moment(baseDate).add(1, 'month').startOf('month').format('L'));
        break;
      default:
        let wpDays = parseInt(this.waitingPeriod);
        if (moment(baseDate).format('D') == '1') {
          effectiveDate = new Date(moment(baseDate).add(wpDays, 'month').startOf('month').format('L'));
        } else {
          effectiveDate = new Date(
            moment(baseDate)
              .add(wpDays + 1, 'month')
              .startOf('month')
              .format('L')
          );
        }
        break;
    }

    return effectiveDate;
  }

  validateDependentWaiverDates (waiverDate: AbstractControl) {
    let plan = waiverDate.parent;

    if (plan === null || plan === undefined) return null;

    let dependent = plan?.parent?.parent as FormGroupWarn;

    let effectiveDate = plan.get('effectiveDate')?.value;

    let dependentId = dependent.get('dependentId')?.value;

    //check if effective or waiver date changed
    let depFields = this.employeeFormService.employeeFields.value.dependents.filter(
      x => x.dependentId.value === dependentId
    );

    if (depFields.length > 0) {
      let depfield = depFields[0];
      let depPlan = depfield.plans.filter(x => x.productCode.value === plan?.get('productCode')?.value);

      if (depPlan !== undefined) {
        if (depPlan[0].waiverDate.value === plan?.get('waiverDate')?.value) {
          return null;
        }
      }
    }

    if (this.isAdmin === true) {
      if (this.employeeFormService.isDateAfter(effectiveDate, plan.get('waiverDate')?.value)) {
        return {
          customErrorMessage: ` Waiver Date cannot be prior to Dependent effective date ${moment(effectiveDate).format(
            'L'
          )}.`,
        };
      } else {
        return null;
      }
    }

    var wpDate = this.getDependentPlanEffectiveDate(dependent, plan.get('productCode')?.value);

    if (
      moment(wpDate).format('L') !== moment(effectiveDate).format('L') &&
      IsStringNullOrEmpty(effectiveDate) === false
    ) {
      wpDate = effectiveDate;
    }

    if (wpDate !== undefined) {
      if (this.employeeFormService.isDateAfter(moment(wpDate).format('L'), plan.get('waiverDate')?.value)) {
        return {
          customErrorMessage: ` Waiver Date cannot be prior to Dependent effective date ${moment(wpDate).format('L')}.`,
        };
      }
      if (this.employeeFormService.isDateAfter(plan.get('waiverDate')?.value, moment(wpDate).format('L'))) {
        return {
          customErrorMessage: `Waiver Date cannot be after Dependent effective date ${moment(wpDate).format('L')}.`,
        };
      }
    }

    return null;
  }

  validateDependentPlanDates (dependent: FormGroupWarn, depField: Dependent) {
    let plans = (dependent.get('plans') as FormArray).controls;

    let dependentBirthDate = dependent.get('birthDate')?.value;
    let cobra = this.form.get(['info', 'cobra'])?.value;

    let dependentTerminationDate = dependent.get('terminationDate')?.value;

    let employeeTerminationDate = this.form.get(['info', 'terminationDate'])?.value;

    plans.forEach(plan => {
      let productCode = plan.get('productCode')?.value;
      //clean errors
      this.employeeFormService.setControlError(plan.get('effectiveDate') as FormControlWarn, '');

      // this.employeeFormService.setControlError(plan.get('waiverDate') as FormControlWarn, '');
      this.employeeFormService.setControlError(plan.get('terminationDate') as FormControlWarn, '');

      let effectiveDate = plan.get('effectiveDate')?.value;

      let erPlanTerminated =
        this.isEditing === false
          ? false
          : this.employee.Plans.some(x => x.ProductCode === productCode && x.ErTerminatedPlan === true);

      let shouldTerminationDateBeDisabled = this.shouldPlanTerminationDateBeDisabled(
        plan.get('doNotEnroll')?.value,
        this.reinstateAllowed,
        employeeTerminationDate,
        productCode,
        plans.length == 1,
        cobra
      );

      let termDateControl = plan.get('terminationDate');
      if (erPlanTerminated) {
        termDateControl?.disable();
      } else if (shouldTerminationDateBeDisabled && this.isAdmin === false) {
        if (this.isEditing === true) {
          let depPlanField = depField.plans.find(x => x.productCode.value === productCode);
          if (depPlanField !== undefined) depPlanField.terminationDate.field.readonly = true;
        }
      }

      //validate if effective date is prior to EE plan eff date
      let eePlan = this.getEmployeePlan(plan.get('productCode')?.value);
      let eeEffDate = eePlan.get('effectiveDate')?.value;
      let eeTermDate = eePlan.get('terminationDate')?.value;

      if (this.employeeFormService.isDateAfter(dependentBirthDate, effectiveDate)) {
        this.employeeFormService.setControlError(
          plan.get('effectiveDate') as FormControlWarn,
          'Effective date cannot be prior to Dependent birth date'
        );
      }

      if (this.employeeFormService.isDateAfter(eeEffDate, effectiveDate)) {
        this.employeeFormService.setControlError(
          plan.get('effectiveDate') as FormControlWarn,
          'Effective date cannot be prior to Employee effective date'
        );
      }

      //validate if termination date is prior to plan eff date
      let terminationDate = plan.get('terminationDate')?.value;

      if (this.employeeFormService.isDateAfter(effectiveDate, terminationDate)) {
        this.employeeFormService.setControlError(
          plan.get('terminationDate') as FormControlWarn,
          'Termination date cannot be prior to Effective date'
        );
      }

      //validate if termination date is prior to plan waiver date
      let waiverDate = plan.get('waiverDate')?.value;
      if (this.employeeFormService.isDateAfter(waiverDate, terminationDate)) {
        this.employeeFormService.setControlError(
          plan.get('terminationDate') as FormControlWarn,
          'Termination date cannot be prior to Waiver date'
        );
      }

      if (this.employeeFormService.isDateAfter(terminationDate, dependentTerminationDate)) {
        this.employeeFormService.setControlError(
          plan.get('terminationDate') as FormControlWarn,
          'Termination date cannot be after Dependent termination date'
        );
      }

      if (this.employeeFormService.isDateAfter(terminationDate, employeeTerminationDate)) {
        this.employeeFormService.setControlError(
          plan.get('terminationDate') as FormControlWarn,
          'Termination date cannot be after Employee termination date'
        );
      }

      if (this.employeeFormService.isDateAfter(terminationDate, eeTermDate)) {
        this.employeeFormService.setControlError(
          plan.get('terminationDate') as FormControlWarn,
          'Termination date cannot be after Employee plan termination date'
        );
      }
    });
  }

  validateEmployeePlan (plan: FormGroupWarn, validateEffDate: boolean = true) {
    let plansFields = (this.form.get(['plans']) as FormArray).controls;
    let hireDate = this.form.get(['info', 'hireDate'])?.value;
    let cobra = this.form.get(['info', 'cobra'])?.value;
    let eeTerminationDate = this.form.get(['info', 'terminationDate'])?.value;

    let isValid: boolean;
    isValid = true;
    let productCode = plan.get('productCode')?.value;
    let planEffectiveDate = plan.get('effectiveDate')?.value;
    let waiverDate = plan.get('waiverDate')?.value;
    let erEffectiveDate = this.getEmployerEffectiveDate(productCode);
    let terminationDate = plan.get('terminationDate')?.value;
    let doNotEnroll = plan.get('doNotEnroll')?.value;

    let shouldTerminationDateBeDisabled = this.shouldPlanTerminationDateBeDisabled(
      doNotEnroll,
      this.reinstateAllowed,
      eeTerminationDate,
      productCode,
      plansFields.length == 1,
      cobra
    );
    let erPlanTerminated =
      this.isEditing === false
        ? false
        : this.employee.Plans.some(x => x.ProductCode === productCode && x.ErTerminatedPlan === true);
    let termDateControl = plan.get('terminationDate');

    if (erPlanTerminated) {
      termDateControl?.disable();
    } else if (shouldTerminationDateBeDisabled && this.isAdmin === false) {
      if (this.isEditing === true) {
        let eePlan = this.employeeFormService.employeeFields.value.plans.find(x => x.productCode.value === productCode);
        if (eePlan !== undefined) eePlan.terminationDate.field.readonly = true;
      }
    }

    // plan.setValidators(Validators.required);
    if ((productCode == 'Dental' || productCode == 'EyeMed') && IsStringNullOrEmpty(terminationDate)) {
      if (this.employeeFormService.isDateAfter(planEffectiveDate, plan.get('waiverDate')?.value)) {
        this.employeeFormService.setControlError(
          plan.get('waiverDate') as FormControlWarn,
          ' Waiver Date cannot be prior to effective date.'
        );
      }

      if (this.isAdmin === false) {
        var wpDate = this.getEmployeeEffectiveDate(hireDate);

        if (this.employeeFormService.isDateAfter(moment(wpDate).format('L'), plan.get('waiverDate')?.value)) {
          this.employeeFormService.setControlError(
            plan.get('waiverDate') as FormControlWarn,
            ` Waiver Date cannot be prior to Employee effective date ${moment(wpDate).format('L')}.`
          );
        }
        if (this.employeeFormService.isDateAfter(plan.get('waiverDate')?.value, moment(wpDate).format('L'))) {
          this.employeeFormService.setControlError(
            plan.get('waiverDate') as FormControlWarn,
            ` Waiver Date cannot be after Employee effective date ${moment(wpDate).format('L')}.`
          );
        }
      }

      if (plansFields.length == 1 && cobra === false) {
        plan.get('effectiveDate')?.setValidators(Validators.required);
      }
    } else if (
      productCode == 'VSP' ||
      productCode == 'DeltaVision' ||
      (productCode == 'PPP' && this.P3Mandatory == false)
    ) {
      //TODO VERIFIY IF EFFECTIVEORDONOTENROLL IS NEEDED
    }

    //EFF DATE CANNOT BE PRIOR TO HIRE DATE

    //check if Effective or waiver date changed
    if (this.isEditing === true) {
      let eePlan = this.employeeFormService.employeeFields.value.plans.find(x => x.productCode.value === productCode);

      if (
        moment(eePlan?.effectiveDate?.value).format('L') === moment(planEffectiveDate).format('L') &&
        moment(eePlan?.waiverDate?.value).format('L') === moment(waiverDate).format('L')
      ) {
        validateEffDate = false;
      }
    }

    if (validateEffDate === true && IsStringNullOrEmpty(terminationDate)) {
      if (moment(hireDate, 'L').startOf('day').isAfter(moment(planEffectiveDate, 'L').startOf('day'))) {
        this.employeeFormService.setControlError(
          plan.get('effectiveDate') as FormControlWarn,
          'Effective Date cannot be prior to Hire Date.'
        );
        isValid = false;
      }

      //WAIVER DATE CANNOT BE PRIOR TO HIRE DATE
      if (this.employeeFormService.isDateAfter(hireDate, waiverDate)) {
        this.employeeFormService.setControlError(
          plan.get('effectiveDate') as FormControlWarn,
          'Waiver Date cannot be prior to Hire Date.'
        );
        isValid = false;
      }
      let momentErEffectiveDate = moment(erEffectiveDate, 'L');
      if (this.employeeFormService.isDateAfter(moment(erEffectiveDate).format('L') ?? '', planEffectiveDate)) {
        let control = plan.get('effectiveDate');
        this.employeeFormService.setControlError(
          control as FormControlWarn,
          `Effective date cannot be prior to Employer Effective date ${moment(erEffectiveDate).format('L')} `
        );
        isValid = false;
      }

      if (this.employeeFormService.isDateAfter(moment(erEffectiveDate).format('L') ?? '', waiverDate)) {
        this.employeeFormService.setControlError(
          plan.get('WaiverDate') as FormControlWarn,
          'Waiver date cannot be prior to Employer Effective date.'
        );
        isValid = false;
      }
    }

    if (this.employeeFormService.isDateAfter(planEffectiveDate, terminationDate)) {
      this.employeeFormService.setControlError(
        termDateControl as FormControlWarn,
        'Termination date cannot be prior to effective date'
      );
      // termDateControl?.updateValueAndValidity();
      isValid = false;
    }

    if (this.employeeFormService.isDateAfter(terminationDate, eeTerminationDate)) {
      this.employeeFormService.setControlError(
        termDateControl as FormControlWarn,
        'Termination date cannot be after to Employee termination Date'
      );
      isValid = false;
    }

    // if (isValid == true) {
    //   plan.setErrors({ customErrorMessage: null });
    // }
    // plan.updateValueAndValidity();
  }
  validateEmployeePlanDates (validateEffDate: boolean = true) {
    let plansArray = this.form.get(['plans']) as FormArray;
    let plansFields = plansArray.controls;
    plansFields.forEach(plan => {
      this.validateEmployeePlan(plan as FormGroupWarn, validateEffDate);
    });

    setTimeout(() => {
      this.validateAtLeastOnePlan(plansArray, this.form);
    }, 100);
  }

  updateEmployeePlans (effectiveDate: Date) {
    let plansFields = (this.form.get(['plans']) as FormArray).controls;
    plansFields.forEach(plan => {
      let productCode = plan.get('productCode')?.value;

      if (plan.get('doNotEnroll')?.value === true || (!IsStringNullOrEmpty(plan.get('terminationDate')?.value) && this.addingWithTermination === false)) {
        return;
      }
      const hireDate = this.form.get(['info','hireDate'])?.value;
      if(this.employeeFormService.isDateAfter(moment(hireDate).format('L'), moment(plan.get('effectiveDate')?.value).format('L')) === false)
        {
          if(this.form.get(['info','reinstated'])?.value) return;
        }

      let planEffectiveDate = this.getEffectiveDate(productCode, effectiveDate);

      plan.patchValue({ effectiveDate: planEffectiveDate });
    });
  }

  updateDependentQEPlans (dependent: FormGroupWarn) {
    let plans = (dependent.get('plans') as FormArray).controls;
    plans.forEach(plan => {
      let effectiveDate = this.getDependentPlanEffectiveDate(
        dependent as FormGroupWarn,
        plan.get('productCode')?.value
      );

      plan.patchValue({ effectiveDate: effectiveDate });

      if (IsStringNullOrEmpty(plan.get('waiverDate')?.value) === false) {
        plan.patchValue({ waiverDate: effectiveDate });
        plan.patchValue({ effectiveDate: null });
      }
    });
  }

  terminateDependentPlans (dependent: FormGroupWarn, index: number) {
    let plans = (dependent.get('plans') as FormArray).controls;
    plans.forEach(plan => {
      let terminationDate = dependent.get('terminationDate')?.value;
      let employeeTerminationDate = this.form.get(['info', 'terminationDate'])?.value;
      //verify if it's terminating employee, then enable field
      if (this.reinstateAllowed === false && IsStringNullOrEmpty(employeeTerminationDate) === false) {
        plan.get('terminationDate')?.setValidators(Validators.required);
        plan.get('terminationDate')?.updateValueAndValidity();
      }

      if (this.isEditing === true) {
        let depField = this.employee.dependent[index];

        if (
          depField.plans.some(
            x => x.ProductCode === plan.get('productCode')?.value && IsStringNullOrEmpty(x.TerminationDate) === false
          )
        ) {
          return;
        }
      }

      plan.patchValue({ terminationDate: terminationDate });
    });
  }

  terminateDependentsPlans (plan: FormGroupWarn) {
    let dependents = (this.form.get(['dependents']) as FormArray).controls;

    dependents.forEach((dependent, index) => {
      let depField = this.employeeFormService.employeeFields.value.dependents[index];
      if (
        depField.reinstateButton.show === true ||
        (depField.terminationDate.field.value !== null && depField.terminationDate.field.value !== undefined)
      )
        return;
      let plans = (dependent.get('plans') as FormArray).controls;
      plans.forEach(depPlan => {
        if (depPlan.get('productCode')?.value !== plan.get('productCode')?.value) {
          return;
        }

        depPlan.patchValue({ terminationDate: plan.get('terminationDate')?.value });
      });
    });
  }

  updateDependentsWaiverDate (productCode: string, waiverDate: any) {
    let dependents = (this.form.get(['dependents']) as FormArray).controls;
    dependents.forEach((dependent, index) => {
      let plans = (dependent.get('plans') as FormArray).controls;

      let depTerminationDate = dependent.get('terminationDate')?.value;

      if (IsStringNullOrEmpty(depTerminationDate) === false) return;

      plans.forEach(plan => {
        let planproductCode = plan.get('productCode')?.value;

        if (planproductCode === productCode) {
          plan.patchValue({ waiverDate: waiverDate });

          if (IsStringNullOrEmpty(waiverDate)) {
            plan.get('waiverDate')?.removeValidators([Validators.required]);
            plan.get('waiverDate')?.updateValueAndValidity();
          } else {
            plan.get('waiverDate')?.setValidators([Validators.required, this.validateDependentWaiverDates.bind(this)]);
          }
        }
      });
    });
  }

  updateDependentPlans (update: boolean = true) {
    let dependents = (this.form.get(['dependents']) as FormArray).controls;
    dependents.forEach((dependent, index) => {
      let depTerminationDate = dependent.get('terminationDate')?.value;

      if (IsStringNullOrEmpty(depTerminationDate) === false) return;

      if (update === true) {
        this.updateDependentPlan(dependent as FormGroupWarn);
      }
      let depField = this.employeeFormService.employeeFields.value.dependents[index];
      this.validateDependentPlanDates(dependent as FormGroupWarn, depField);
    });
  }

  updateDependentPlan (dependent: FormGroupWarn, depField?: Dependent, depCreated: boolean = false) {
    let depPlans = (dependent.get('plans') as FormArray).controls;
    let birthDate = dependent.get('birthDate')?.value;
    let cobra = this.form.get(['info', 'cobra'])?.value;

    depPlans.forEach(depPlan => {
      if(!IsStringNullOrEmpty(depPlan.get('terminationDate')?.value)) return;

      let eePlan = this.getEmployeePlan(depPlan.get('productCode')?.value);

      if (eePlan == null) return;

      if (IsStringNullOrEmpty(eePlan.get('terminationDate')?.value) === false) {
        if (depCreated === false) return;

        let depFieldPlan = depField?.plans.find(x => x.productCode.value === depPlan.get('productCode')?.value);
        if (depFieldPlan !== undefined) {
          depFieldPlan.terminationDate.field.show = true;
        }
      }

      let eeEffectiveDate = eePlan.get('effectiveDate')?.value;

      let birthDateValid = moment(birthDate, 'L').isValid();
      let depEffectiveDate = this.getDependentPlanEffectiveDate(dependent, depPlan.get('productCode')?.value);

      if (birthDateValid === true) {
        if (this.employeeFormService.isDateAfter(birthDate, eePlan.get('effectiveDate')?.value)) {
          depPlan.setErrors({ customErrorMessage: 'Effective date cannot be prior to date of birth.' });
        }
        eePlan.get('waiverDate')?.value != null;
        {
          if (this.employeeFormService.isDateAfter(birthDate, eePlan.get('waiverDate')?.value)) {
            depPlan.setErrors({ customErrorMessage: 'Waiver date cannot be prior to date of birth.' });
          }
        }

        if(dependent.get('reinstated')?.value === true)
          {
            depPlan.patchValue({effectiveDate: eeEffectiveDate, waiverDate: eePlan.get('waiverDate')?.valeu})
            return;
          }

        //VERIFY IF DEPENDENT WAS BORN AFTER EE EFF DATE
        if (this.employeeFormService.isDateAfter(birthDate, eeEffectiveDate)) {
          if (cobra === false) {
            setTimeout(() => {
              depPlan.get('effectiveDate')?.setValidators(Validators.required);
            }, 100);
          }
        }

        if (
          (depPlan.get('productCode')?.value == 'Dental' || depPlan.get('productCode')?.value == 'EyeMed') &&
          IsStringNullOrEmpty(eePlan.get('waiverDate')?.value) === false
        ) {
          if (IsStringNullOrEmpty(eePlan.get('waiverDate')?.value) === true) {
            depPlan.patchValue({ effectiveDate: depEffectiveDate });
          } else {
            depPlan.patchValue({ waiverDate: depEffectiveDate });
          }
        }
        depPlan.patchValue({ terminationDate: eePlan.get('terminationDate')?.value });
      } else {
        if (IsStringNullOrEmpty(eePlan.get('waiverDate')?.value) === true) {
          if (this.employeeReplicated === true) {
            if (this.isOpenEnrollment === true) {
              if (
                this.employeeFormService.isDateAfter(
                  eePlan.get('effectiveDate')?.value,
                  moment(this.openEnrollmentDate).format('L')
                )
              ) {
                depPlan.patchValue({ effectiveDate: eePlan.get('effectiveDate')?.value });
              } else {
                depPlan.patchValue({ effectiveDate: this.openEnrollmentDate });
              }
            }
            else{
                depPlan.patchValue({ effectiveDate: eePlan.get('effectiveDate')?.value });
            }
          } else {
            depPlan.patchValue({ effectiveDate: eePlan.get('effectiveDate')?.value });
          }
        }

        if (
          (depPlan.get('productCode')?.value == 'Dental' || depPlan.get('productCode')?.value == 'EyeMed') &&
          IsStringNullOrEmpty(eePlan.get('waiverDate')?.value) === false
        ) {
          if (depCreated === true) {
            let depFieldPlan = depField?.plans.find(x => x.productCode.value === depPlan.get('productCode')?.value);
            if (depFieldPlan !== undefined) {
              depPlan
                .get('waiverDate')
                ?.setValidators([Validators.required, this.validateDependentWaiverDates.bind(this)]);
            }
          }

          let newWaiverDate = eePlan.get('waiverDate')?.value;
          if (this.employeeFormService.isDateAfter(depEffectiveDate, newWaiverDate)) {
            newWaiverDate = depEffectiveDate;
          }
          if (this.employeeReplicated === true) {
            if (this.isOpenEnrollment === true) {
              if (this.employeeFormService.isDateAfter(newWaiverDate, moment(this.openEnrollmentDate).format('L'))) {
                depPlan.patchValue({ waiverDate: newWaiverDate });
              } else {
                depPlan.patchValue({ waiverDate: this.openEnrollmentDate });
              }
            } else {
              depPlan.patchValue({ waiverDate: newWaiverDate });
            }
          } else {
            depPlan.patchValue({ waiverDate: newWaiverDate });
          }
        }

        depPlan.patchValue({ terminationDate: eePlan.get('terminationDate')?.value });
      }
    });
  }

  getDependentPlanEffectiveDate (dependent: FormGroupWarn, productCode: string) {
    let eePlan = this.getEmployeePlan(productCode);
    let birthDate = dependent.get('birthDate')?.value;
    let eeHireDate = this.form.get(['info', 'hireDate'])?.value;
    let eeEffectiveDate = eePlan.get('effectiveDate')?.value;
    let depEffectiveDate = eeEffectiveDate;

    if (
      this.isOpenEnrollment === true &&
      this.employeeFormService.isDateAfter(moment(this.openEnrollmentDate).format('L'), eeHireDate) === true
    ) {
      if (this.employeeFormService.isDateAfter(eeEffectiveDate, moment(this.openEnrollmentDate).format('L'))) {
        return eeEffectiveDate;
      }
      return this.openEnrollmentDate;
    }

    let qeDate = dependent.get('qualifyingEventDate')?.value;

    if (this.employeeFormService.isDateAfter(birthDate, eeEffectiveDate)) {
      depEffectiveDate = this.calculateWaitingPeriod(birthDate);
    }

    if (qeDate) {
      depEffectiveDate = new Date(moment(qeDate).startOf('month').add(1, 'month').format('L'));
    }

    if (this.employeeFormService.isDateAfter(eeEffectiveDate, moment(depEffectiveDate).format('L'))) {
      return eeEffectiveDate;
    }

    return depEffectiveDate;
  }

  isDateValid (date: any) {
    if (date) {
      return date.replace(/_/g, '').length == 10 && moment(date, 'L').isValid();
    }
    return false;
  }

  onDeleteEmployee (form: FormGroupWarn) {
    this.notificationService.confirmation('Are you sure you want to Delete this employee?', () => {
      this.employeeService.DeleteEmployee(this.employeeId).subscribe(response => {
        if (response.status === true) {
          this.notificationService.success('Employee deleted successfully');
          this.router.navigate([RouteEnum.Employees]);
        } else {
          this.notificationService.error(response.message);
        }
      });
    });
  }

  onReinstateEmployee (form: FormGroupWarn) {
    this.notificationService.confirmation('Are you sure you want to reinstate the Employee?', () => {
      const infoForm = form.get('info');

      this.reinstateAllowed = false;
      infoForm?.patchValue({ terminationDate: null, terminationReason: null, reinstated: true }, {emitEvent: false});

      this.employerFormFields.reinstateButton.show = false;

      const plansArray = this.form.get(['plans']) as FormArray;
      const plansFields = plansArray.controls;
      plansFields.forEach(plan => {
        const erTerminationDate = this.getEmployerTerminationDate(plan.get('productCode')?.value);

        if (IsStringNullOrEmpty(erTerminationDate?.toString())) {
          plan.patchValue({ terminationDate: null }, {emitEvent: false});
        }

        if (plan.get('doNotEnroll')?.value !== true && this.isAdmin === true) {
          plan.get('effectiveDate')?.enable();
          plan.get('terminationDate')?.enable();
        }
      });

      infoForm?.get('firstName')?.enable();
      infoForm?.get('mI')?.enable();
      infoForm?.get('lastName')?.enable();
      infoForm?.get('sex')?.enable();
      infoForm?.get('dateOfBirth')?.enable();
      infoForm?.get('address')?.enable();
      infoForm?.get('address2')?.enable();
      infoForm?.get('city')?.enable();
      infoForm?.get('state')?.enable();
      infoForm?.get('zip5')?.enable();
      infoForm?.get('zip4')?.enable();
      infoForm?.get('phone')?.enable();
      infoForm?.get('email')?.enable();
      infoForm?.get('cobra')?.enable();
      infoForm?.get('terminationDate')?.enable();

      if(this.isAdmin === true)
        {
          infoForm?.get('ssn')?.enable();
          infoForm?.get('hireDate')?.enable();

        }
      // (dependent.get('plans') as FormArray).controls;
      const dependents = (form.get('dependents') as FormArray).controls;

      dependents.forEach(dep => {
        const depData = this.employee.dependent.filter(x => x.DependentID === dep.get('dependentId')?.value)

        if (!depData) return;

        if(!depData[0].Reinstate) return;

        const depField = this.getDependentFieldFromId(depData[0].DependentID)
        if(!depField) return;

        depField.reinstateButton.show = true;
      })
      //reinstateButton: { show: dependent.Reinstate && !this.employerFormFields.reinstateButton.show, disable: false },
    });
  }

  onQualifyingEventAddDependent = () => {
    return this.onAddDependent(true) as Dependent;
  };
  onAddDependent = (qualifyingEventAdd: boolean = false) => {
    let { EmployerProducts } = this.employer.Data;

    EmployerProducts = EmployerProducts.filter(
      product => product.ProductCode != 'LTD' && product.ProductCode != 'STD' && product.ProductCode != 'Life' && 
      this.getEmployeePlan(product.ProductCode) !== undefined && this.getEmployeePlan(product.ProductCode)?.get('doNotEnroll')?.value !== true
    );

    let employeeLastName = this.form.get(['info', 'lastName'])?.value;
    let employeeTerminationDate = this.form.get(['info', 'terminationDate'])?.value;

    let newDependent = {
      qualifyingEventAdd: { disable: qualifyingEventAdd === false, show: qualifyingEventAdd },
      qualifyingEvent: { disable: false, show: qualifyingEventAdd },
      qualifyingEventDate: { disable: false, show: false },
      dependentId: { disable: false, show: false },
      depReplicated: { disable: false, show: false, value: false },
      firstName: { disable: false, show: true, validators: [Validators.required,Validators.maxLength(12), CustomValidator.StringWithNumbersValidator, CustomValidator.SpecialCharactersValidator] },
      relationshipCode: { disable: false, show: true, validators: [Validators.required] },
      lastName: { value: employeeLastName, disable: false, show: true, validators: [Validators.required,Validators.maxLength(20),CustomValidator.StringWithNumbersValidator, CustomValidator.SpecialCharactersValidator] },
      birthDate: { disable: false, show: true, validators: [Validators.required] },
      // plans: [],
      plans: EmployerProducts.map<Plan>(plan => ({
        replicated: { disable: false, show: false, value: false },
        productCode: {
          disable: false,
          show: !(this.getEmployeePlan(plan.ProductCode)?.get('doNotEnroll')?.value === true),
          value: plan.ProductCode,
          validators: [Validators.required],
        },
        productName: { disable: false, show: true, value: plan.Description, validators: [] },
        effectiveDate: {
          disable: this.getEmployeePlan(plan.ProductCode)?.get('doNotEnroll')?.value === true,
          readonly: this.isAdmin === false,
          show: true,
        },
        waiverDate: {
          disable: this.getEmployeePlan(plan.ProductCode)?.get('doNotEnroll')?.value === true,
          show: (plan.ProductCode == 'Dental' || plan.ProductCode == 'EyeMed') && EmployerProducts.length > 1,
          validators: [this.validateDependentWaiverDates.bind(this)],
        },
        dentalOffice: {
          disable: this.getEmployeePlan(plan.ProductCode)?.get('doNotEnroll')?.value === true,
          show: this.PPO === false && plan.ProductCode === 'Dental',
          validators: [Validators.maxLength(6)],
        },
        terminationDate: {
          field: {
            disable: this.getEmployeePlan(plan.ProductCode)?.get('doNotEnroll')?.value === true,
            readonly: IsStringNullOrEmpty(employeeTerminationDate) === false,
            show: this.shouldDisplayDoNotEnroll(plan.ProductCode) && this.authService.IsAdmin(),
          },
          showTerminationDateAsSelect: this.authService.IsAdmin() === false && this.eeTerminated === false,
          terminationDateOptions: this.terminationDateOptions,
        },
        doNotEnroll: {
          disable: this.getEmployeePlan(plan.ProductCode)?.get('doNotEnroll')?.value === true,
          show: this.shouldDisplayDoNotEnroll(plan.ProductCode),
        },
      })),

      info: {
        address: { disable: false, show: false, validators: [CustomValidator.SpecialCharactersValidator] },
        address2: { disable: false, show: false, validators: [CustomValidator.SpecialCharactersValidator] },
        city: { disable: false, show: false, validators: [] },
        phone: { disable: false, show: false, validators: [] },
        state: { disable: false, show: false, validators: [] },
        zip: { disable: false, show: false, validators: [] },
        zip4: { disable: false, show: false, validators: [] },
        zip5: { disable: false, show: false, validators: [] },
      },

      isCobraEligible: { disable: false, show: false, validators: [] },
      isDependentAdressSameAsEmployee: { disable: false, show: false, validators: [] },

      status: { disable: false, show: false },
      terminationDate: { field: { disable: false,readonly:  IsStringNullOrEmpty(employeeTerminationDate) === false, show: IsStringNullOrEmpty(employeeTerminationDate) === false, value: employeeTerminationDate }, showTerminationDateAsSelect: false },
      terminationReason: { field: { disable: false, show: false } },
      cobraQuestions: { disable: false, show: false },
      reinstateButton: { disable: false, show: false },
      Reinstated: { disable: false, show: false },
    } as Dependent;

    //verify if waiverdateIsRequired

    newDependent.plans.forEach(plan => {
      let productCode = plan?.productCode?.value ?? '';

      let eePlan = this.getEmployeePlan(productCode);

      if (eePlan === undefined || eePlan === null) return;
      if (IsStringNullOrEmpty(eePlan.get('waiverDate')?.value) === false) {
        plan.waiverDate.validators?.push(Validators.required);
      }
    });

    return newDependent;
  };

  dependentChanges (depChange: DependentChanges) {
    let dependent = depChange.dependent;
    let depIndex = depChange.index;

    let depField = this.employeeFormService.employeeFields.value.dependents[depIndex];

    let birthDateChanges = dependent.get('birthDate')?.valueChanges.pipe(distinctUntilChanged());
    let terminationDateChange = dependent.get('terminationDate')?.valueChanges.pipe(distinctUntilChanged());
    let plans = (dependent.get('plans') as FormArray).controls;

    birthDateChanges &&
      birthDateChanges.subscribe(birthDate => {
        this.birthDateChanges(birthDate, dependent, false);

        this.validateDependentPlanDates(dependent, depField);
      });

    let relationshipChange = dependent.get('relationshipCode')?.valueChanges.pipe(distinctUntilChanged());

    relationshipChange &&
      relationshipChange.subscribe(relationshipCode => {
        depField.status.show = this.shouldShowDependentStatus(relationshipCode);
        let birthDate = dependent.get('birthDate')?.value;
        this.validateDependentBirthDate(birthDate, dependent);
        this.validateDependentPlanDates(dependent, depField);
      });

    terminationDateChange &&
      terminationDateChange.subscribe(terminationDate => {
        let depId = dependent.get('dependentId')?.value;

        let depReinstated = dependent.get('reinstated')?.value === true;

        let editDependent = this.employee.dependent.find(dep => {
          return dep.DependentID === depId;
        });

        let showCobra =
          terminationDate !== null &&
          terminationDate !== undefined &&
          terminationDate !== '' &&
          this.verifyAddress === true &&
          this.checkCobraOnSave !== true &&
          !editDependent?.Reinstate;

        let employeeTerminationDate = this.form.get(['info', 'terminationDate'])?.value;
        dependent.get('terminationReason')?.enable();

        if (
          terminationDate !== undefined &&
          terminationDate !== null &&
          this.verifyAddress === true &&
          IsStringNullOrEmpty(employeeTerminationDate) === true
        ) {
          dependent
            .get('terminationReason')
            ?.setValidators([Validators.required, CustomValidator.EmptyStringValidator]);
          dependent.get('terminationReason')?.updateValueAndValidity();
        } else {
          if (
            this.form.get(['info', 'terminationDate'])?.value === undefined ||
            this.form.get(['info', 'terminationDate'])?.value === null
          ) {
            dependent.get('terminationReason')?.removeValidators(Validators.required);
            dependent.get('terminationReason')?.removeValidators(CustomValidator.EmptyStringValidator);
          }
        }

        if (this.employeeFormService.isDateAfter(terminationDate, employeeTerminationDate)) {
          this.employeeFormService.setControlError(
            dependent.get('terminationDate') as FormControlWarn,
            'Termination date cannot be beyond employee termination date'
          );
        }

        if (depReinstated === false) {
          this.terminateDependentPlans(dependent, depIndex);
        }

        this.validateDependentPlanDates(dependent, depField);

        if (depField === undefined || depReinstated === true) return;

        depField.cobraQuestions.show = showCobra;

        if (showCobra) {
          dependent.get('isCobraEligible')?.addValidators(Validators.required);
          dependent.get('isDependentAdressSameAsEmployee')?.addValidators(Validators.required);
        } else {
          dependent.get('isCobraEligible')?.removeValidators(Validators.required);
          dependent.get('isDependentAdressSameAsEmployee')?.removeValidators(Validators.required);

          this.toggleDependentContactFields(depField, showCobra, dependent);
        }
        dependent.get('isCobraEligible')?.updateValueAndValidity();
        dependent.get('isDependentAdressSameAsEmployee')?.updateValueAndValidity();
      });

    let cobraEligibleChange = dependent.get(['isCobraEligible'])?.valueChanges.pipe(distinctUntilChanged());

    cobraEligibleChange &&
      cobraEligibleChange.subscribe(cobraEligible => {
        let showCobraFields = cobraEligible === 'Yes';

        this.toggleDependentCobraEligible(depField, showCobraFields, dependent);
      });

    let addressSameAsEmployeeChange = dependent
      .get(['isDependentAdressSameAsEmployee'])
      ?.valueChanges.pipe(distinctUntilChanged());

    addressSameAsEmployeeChange &&
      addressSameAsEmployeeChange.subscribe(isAddressSameAsEmployee => {
        let showContactFields = isAddressSameAsEmployee === 'No';

        this.toggleDependentContactFields(depField, showContactFields, dependent);
      });

    let plansArray = dependent.get('plans') as FormArray;

    plans.forEach(plan => {
      this.planChanges(plan as FormGroupWarn, dependent, plansArray, depIndex);
    });

    let qualifyignEventChange = dependent.get('qualifyingEvent')?.valueChanges.pipe(distinctUntilChanged());

    qualifyignEventChange &&
      qualifyignEventChange.subscribe(qualifyingEvent => {
        this.onDependentQEChanges(depField);
      });

    let qualifyingEventDateChanges = dependent.get('qualifyingEventDate')?.valueChanges.pipe(distinctUntilChanged());

    qualifyingEventDateChanges &&
      qualifyingEventDateChanges.subscribe(qualifyingEventDate => {
        this.onDependentQEDateChanges(qualifyingEventDate, dependent, depField);
      });

    if (depChange.dependentCreated === true) {
      this.updateDependentPlan(depChange.dependent, depField, depChange.dependentCreated);
    }
  }

  planChanges (plan: FormGroupWarn, dependent: FormGroupWarn, plansArray: FormArray, depIndex: number) {
    let depField = this.employeeFormService.employeeFields.value.dependents[depIndex];
    let effectiveDateChange = plan.get('effectiveDate')?.valueChanges.pipe(distinctUntilChanged());

    effectiveDateChange &&
      effectiveDateChange.subscribe(effectiveDate => {
        this.validateDependentPlanDates(dependent, depField);
      });

    let waiverDateChange = plan.get('waiverDate')?.valueChanges.pipe(distinctUntilChanged());

    waiverDateChange &&
      waiverDateChange.subscribe(waiverDate => {
        if (this.isAdmin !== true) {
          if (moment(waiverDate).isValid() === true && waiverDate !== undefined) {
            plan.patchValue({ effectiveDate: null });
          } else if (IsStringNullOrEmpty(waiverDate) === true) {
            let birthdate = dependent.get('birthDate')?.value;
            if (IsStringNullOrEmpty(birthdate) === false) {
              let effectiveDate = this.getDependentPlanEffectiveDate(dependent, plan.get('productCode')?.value);

              plan.patchValue({ effectiveDate: effectiveDate });
            }
          }
        }

        this.validateDependentPlanDates(dependent, depField);
        setTimeout(() => {
          this.validateAtLeastOnePlan(plansArray, dependent as FormGroupWarn);
        }, 100);
      });

    let doNotEnrollChange = plan.get('doNotEnroll')?.valueChanges.pipe(distinctUntilChanged());

    doNotEnrollChange &&
      doNotEnrollChange.subscribe(doNotEnroll => {
        this.toggleDependentDoNotEnroll(plan as FormGroupWarn, dependent as FormGroupWarn, depIndex);
        setTimeout(() => {
          this.validateAtLeastOnePlan(plansArray, dependent);
        }, 100);
      });

    let terminationDateChange = plan.get('terminationDate')?.valueChanges.pipe(distinctUntilChanged());
    terminationDateChange &&
      terminationDateChange.subscribe(terminationDate => {
        if (terminationDate !== undefined && terminationDate !== null && terminationDate !== '') {
          setTimeout(() => {
            this.validateAtLeastOnePlan(plansArray, dependent);
          }, 100);
          this.validateDependentPlanDates(dependent, depField);
        }
      });
  }

  onDependentQEChanges (depField: Dependent) {
    depField.qualifyingEventDate.show = true;
  }

  onDependentQEDateChanges (qualifyingEventDate: any, dependent: FormGroupWarn, depField: Dependent) {
    if (depField === undefined) return;

    if (qualifyingEventDate === null || qualifyingEventDate === undefined) return;
    let startDate = moment().add(-60, 'day').startOf('day').format('L');
    let endDate = moment().add(60, 'day').startOf('day').format('L');

    let qeDateControl = dependent.get('qualifyingEventDate');

    var qeDateMoment = moment(qualifyingEventDate, 'L');

    if (qeDateMoment.isSameOrAfter(startDate) && qeDateMoment.isSameOrBefore(endDate)) {
      qeDateControl?.setErrors({ customErrorMessage: null });
      qeDateControl?.updateValueAndValidity();

      depField.qualifyingEventAdd.show = false;

      this.updateDependentQEPlans(dependent);
    } else {
      qeDateControl?.setErrors({
        customErrorMessage: 'Qualifying Event Date must be between ' + startDate + ' and ' + endDate + '.',
      });
    }
  }

  validateDependentBirthDate (birthDate: any, dependent: FormGroupWarn) {
    let birthDateControl = dependent.get('birthDate');
    let valid = true;
    let minDate = new Date('1/1/1900');

    if (moment(minDate, 'L').isSameOrAfter(moment(birthDate, 'L'))) {
      birthDateControl?.setErrors({ customErrorMessage: 'Date of Birth cannot be prior to 1900.' });
      valid = false;
    }

    if (moment(birthDate, 'L').isSameOrAfter(moment(new Date(), 'L'))) {
      birthDateControl?.setErrors({ customErrorMessage: 'Date of Birth cannot be a future date.' });
      valid = false;
    }
    let relationship = dependent.get('relationshipCode')?.value;

    if (relationship === 'S' || relationship === 'D' || relationship === 'C') {
      let isDependentOver26Yo = this.isDependentOver26Yo(birthDate);

      if (isDependentOver26Yo === true) {
        if (this.authService.IsAdmin()) {
          (dependent.controls['birthDate'] as FormControlWarn).warnings = 'Dependent is over 26 years old.';
        } else {
          this.employeeFormService.setControlError(
            birthDateControl as FormControlWarn,
            'Dependents over the age of 26 are only covered if they are disabled.',
            'Click for more information.'
          );
        }
      } else {
        if (this.authService.IsAdmin()) {
          (dependent.controls['birthDate'] as FormControlWarn).warnings = '';
        } else {
          birthDateControl?.setErrors({ customErrorMessage: null });
          birthDateControl?.updateValueAndValidity();
        }
      }
    }
  }

  birthDateChanges (birthDate: any, dependent: FormGroupWarn, updateValues: boolean = true) {
    this.validateDependentBirthDate(birthDate, dependent);

    if (updateValues && (this.isAdmin === false || this.isOpenEnrollment === true)) this.updateDependentPlan(dependent);
  }

  onHireDateChange (hireDate: any) {
    this.validateWaitingPeriod();

    let birthDate = this.form.get(['info', 'dateOfBirt'])?.value;
    this.validateBirthDate(birthDate);
    //VALIDATE HIRE DATE
    this.validateHireDate(hireDate, this.form);

    //VALIDATE QUALIFYING EVENT DATE
    this.validateHireDateForQE();

    if (
      (this.isEditing === false && this.qeSelected === false) ||
      (this.isEditing === true && IsStringNullOrEmpty(this.form.get(['info', 'qualifyingEventDate'])?.value) === true)
    ) {
      this.validateRangeDate(hireDate);
    }

    let effectiveDate = this.getEmployeeEffectiveDate(hireDate);

    //UPDATE EE PLANS

    this.updateEmployeePlans(effectiveDate);

    this.validateEmployeePlanDates();

    //UPDATE DEPENDENT PLANS
    this.updateDependentPlans();

    this.validateEffectiveDatesWithWaiverDate();
  }

  validateEffectiveDatesWithWaiverDate () {
    if (this.isAdmin === true) return;

    let eeDental = this.getEmployeePlan('Dental');

    let dependents = (this.form.get('dependents') as FormArray).controls;

    if (IsStringNullOrEmpty(eeDental.get('waiverDate').value) === true) return;

    dependents.forEach(dependent => {
      let dentalPlan = (dependent.get('plans') as FormArray).controls.find(
        plan => plan.get('productCode')?.value === 'Dental'
      );

      if (dentalPlan === undefined) return;

      dentalPlan.patchValue({ waiverDate: eeDental.get('waiverDate').value, effectiveDate: null });
      dentalPlan.get('effectiveDate')?.removeValidators(Validators.required);
      dentalPlan.get('effectiveDate')?.updateValueAndValidity();
    });
  }

  private getEmployeeEffectiveDate (hireDate: any) {
    let effectiveDate: Date;
    effectiveDate = hireDate;

    if (this.isOpenEnrollment === true) {
      if (moment(hireDate, 'L').isSameOrBefore(moment(this.openEnrollmentDate, 'L'))) {
        effectiveDate = this.openEnrollmentDate;
      } else {
        if (this.waitingPeriod === 'X' && this.isAdmin === false) {
          hireDate.setErrors({
            customErrorMessage:
              'Your group has a non-standard waiting period. Please contact your Account Coordinator to enroll new members.',
          });
        }
        effectiveDate = this.calculateWaitingPeriod(hireDate);
      }
    } else {
      //VALIDATE QUALIFYING EVENT
      if (this.qeSelected === true) {
        effectiveDate = this.calculateQeEffectiveDate();
      } else {
        //CALCULATE EFFECTIVE DATE BASED ON WAITING PERIOD
        effectiveDate = this.calculateWaitingPeriod(hireDate);
      }
    }

    return effectiveDate;
  }

  private onTerminationDateChange (terminationDate: any) {
    if (!this.isEditing) return;

    let terminationDateControl = this.form.get(['info', 'terminationDate']);
    if (terminationDate !== null && terminationDate !== undefined && terminationDate !== '') {
      let hireDate = this.form.get(['info', 'hireDate'])?.value;

      let terminationBeforeHireDate = this.employeeFormService.isDateAfter(hireDate, terminationDate);

      if (terminationBeforeHireDate) {
        terminationDateControl?.setErrors({
          customErrorMessage: "Termination date cannot be prior to Employee's hire date.",
        });
        terminationDateControl?.markAllAsTouched();
      }
      let dependents = (this.form.get('dependents') as FormArray).controls;

      let dependentsNotReplicated = dependents.filter(dependent => {
        return dependent.get('depReplicated')?.value === false;
      });

      if (dependentsNotReplicated.length > 0) {
        this.employeeFormService.setControlError(
          terminationDateControl as FormControlWarn,
          'There are new dependents, please delete the new dependents before terminating Employee.'
        );
      }

      if (dependentsNotReplicated.length === 0 && !terminationBeforeHireDate) {
        (terminationDateControl as FormGroupWarn).warnings =
          'Employee and all the plans and dependents will be terminated';

        this.checkCobraOnSave = true;
        this.form.get(['info', 'terminationReason'])?.enable();
        if (this.verifyAddress === true) {
          this.form
            .get(['info', 'terminationReason'])
            ?.setValidators([Validators.required, CustomValidator.EmptyStringValidator]);
          this.form.get(['info', 'terminationReason'])?.updateValueAndValidity();
        }

        let eePlans = (this.form.get('plans') as FormArray).controls;

        eePlans.forEach(plan => {
          let erPlanTerminated = this.employee?.Plans.some(
            x => x.ProductCode === plan.get('productCode')?.value && x.ErTerminatedPlan === true
          );

          if (erPlanTerminated === true) return;

          let eePlanEffectiveDate = plan.get('effectiveDate')?.value;

          if (this.employeeFormService.isDateAfter(eePlanEffectiveDate, terminationDate)) {
            this.employeeFormService.setControlError(
              terminationDateControl as FormControlWarn,
              'Termination Date cannot be prior to any plan effective dates'
            );
          }

          if (this.isEditing === true) {
            var fieldPlan = this.employee.Plans.find(x => x.ProductCode === plan.get('productCode')?.value);
            if (IsStringNullOrEmpty(fieldPlan?.TerminationDate) === false) return;
          }

          if (this.shouldDisplayDoNotEnroll(plan.get('productCode')?.value)) {
            if (plan.get('doNotEnroll')?.value !== true) {
              plan.patchValue({ terminationDate: terminationDate });
              plan.get('terminationDate')?.setValidators(Validators.required);
            } else {
              plan.get('terminationDate')?.removeValidators(Validators.required);
            }
          } else {
            plan.patchValue({ terminationDate: terminationDate });
            plan.get('terminationDate')?.setValidators(Validators.required);
          }

          this.validatePlanTerminationDate(plan);
          // plan.get('terminationDate')?.updateValueAndValidity();
        });

        //UPDATE DEPENDENT TERMINATION DATE
        dependents.forEach((dependent, index) => {
          let depField = this.employeeFormService.GetDependentField(index);
          let dependentData = this.employee.dependent.find(x => x.DependentID === dependent.get('dependentId')?.value);

          if (
            depField.reinstateButton.show === true ||
            (this.isAdmin === false && depField.terminationDate.field.disable === true) ||
            (depField.terminationDate.field.value !== null && depField.terminationDate.field.value !== undefined)
          ) {
            return;
          }

          let depTerminationDateControl = dependent.get('terminationDate');

          depTerminationDateControl?.addValidators(Validators.required);

          dependent.patchValue({ terminationDate: terminationDate });

          let depPlans = (dependent.get('plans') as FormArray).controls;

          //UPDATE DEPENDENT PLANS TERMINATION DATE
          depPlans.forEach(depPlan => {
            var dependentPlanData = dependentData?.plans.find(x => x.ProductCode === depPlan.get('productCode')?.value);

            if (IsStringNullOrEmpty(dependentPlanData?.TerminationDate) === false) return;

            let depPlanEffectiveDate = depPlan.get('effectiveDate')?.value;

            if (this.isAdmin === false) {
              let depFieldPlan = depField.plans.find(x => x.productCode.value === depPlan.get('productCode')?.value);
              if (depFieldPlan !== undefined) depFieldPlan.terminationDate.field.readonly = true;
            }

            if (this.isEditing === true) {
              var fieldPlan = this.employee.dependent[index].plans.find(
                x => x.ProductCode === depPlan.get('productCode')?.value
              );
              if (IsStringNullOrEmpty(fieldPlan?.TerminationDate) === false) return;
            }

            if (this.employeeFormService.isDateAfter(depPlanEffectiveDate, terminationDate)) {
              terminationDateControl?.setErrors({
                customErrorMessage: 'Termination Date cannot be prior to any plan effective dates',
              });
            }

            depPlan.get('terminationDate')?.setErrors({ customErrorMessage: null });

            if (
              (this.shouldDisplayDoNotEnroll(depPlan.get('productCode')?.value) &&
                depPlan.get('doNotEnroll')?.value !== true) ||
              this.shouldDisplayDoNotEnroll(depPlan.get('productCode')?.value) === false
            ) {
              depPlan.get('terminationDate')?.setValidators(Validators.required);

              depPlan.patchValue({ terminationDate: terminationDate });

              this.validatePlanTerminationDate(depPlan);
            } else {
              depPlan.get('terminationDate')?.removeValidators(Validators.required);
            }
            depPlan.get('terminationDate')?.updateValueAndValidity();
          });
        });
      }
    } else {
      (terminationDateControl as FormControlWarn).warnings = null;
      this.checkCobraOnSave = false;
      this.form.get(['info', 'terminationReason'])?.removeValidators(Validators.required);
      this.form.get(['info', 'terminationReason'])?.removeValidators(CustomValidator.EmptyStringValidator);
      this.form.get(['info', 'terminationReason'])?.updateValueAndValidity();
    }
  }

  private validatePlanTerminationDate (plan: AbstractControl) {
    if (this.employeeFormService.isDateAfter(plan.get('effectiveDate')?.value, plan.get('terminationDate')?.value)) {
      plan
        .get('terminationDate')
        ?.setErrors({ customErrorMessage: 'Termination date cannot be prior to Effective date' });
      plan.get('terminationDate')?.markAllAsTouched();
    }

    if (this.employeeFormService.isDateAfter(plan.get('waiverDate')?.value, plan.get('terminationDate')?.value)) {
      plan
        .get('terminationDate')
        ?.setErrors({ customErrorMessage: 'Termination date cannot be prior to Waiver Effective date' });
      plan.get('terminationDate')?.markAllAsTouched();
    }
  }

  toggleDependentDoNotEnroll (plan: FormGroupWarn, dependent: FormGroupWarn, index: number) {
    let doNotEnroll = plan.get('doNotEnroll')?.value;
    let productCode = plan.get('productCode')?.value;

    if (this.shouldDisplayDoNotEnroll(productCode) === false) return;

    let depField = this.employeeFormService.GetDependentField(index);

    if (doNotEnroll === true) {
      plan.patchValue({ effectiveDate: null });
      plan.patchValue({ terminationDate: null });

      plan.get('effectiveDate')?.disable();
      plan.get('terminationDate')?.disable();
    } else if (doNotEnroll === false) {
      let depTerminationDate = dependent.get('terminationDate')?.value;
      let plans = (dependent.get('plans') as FormArray).controls;
      //TODO - VERIFY IF VALUE HERE IS BOOLEAN OR NOT
      let cobra = this.form.get(['info', 'cobra'])?.value === '1';

      let isDeltaOnly = plans.length === 1 && plans[0].get('productCode')?.value === 'Dental';

      let shouldEffectiveDateBeDisabled = this.shouldPlanEffectiveDateBeDisabled(
        doNotEnroll,
        depTerminationDate,
        false
      );
      let shouldPlanTerminationDateBeDisabled = this.shouldPlanTerminationDateBeDisabled(
        doNotEnroll,
        false,
        depTerminationDate,
        productCode,
        isDeltaOnly,
        cobra
      );

      if (shouldEffectiveDateBeDisabled === false && this.isAdmin === true) plan.get('effectiveDate')?.enable();
      let planField = depField.plans.find(x => x.productCode.value === productCode);

      if (this.isAdmin === false) {
        if (planField !== undefined) planField.effectiveDate.readonly = true;
      }

      if (
        shouldPlanTerminationDateBeDisabled === false &&
        (this.isAdmin === true || dependent.get('depReplicated')?.value === true)
      )
        plan.get('terminationDate')?.enable();

      let effectiveDate = this.getDependentPlanEffectiveDate(dependent, productCode);

      plan.patchValue({ effectiveDate: effectiveDate });

      let eeplan = this.getEmployeePlan(productCode);

      if (IsStringNullOrEmpty(eeplan.get('terminationDate')?.value) === false) {
        plan.patchValue({ terminationDate: eeplan.get('terminationDate')?.value });
        if(this.addingWithTermination || this.editingWithFutureTermination){
          plan.get('terminationDate')?.disable();
        }
        if (planField !== undefined) planField.terminationDate.field.validators = [Validators.required];
      } else {
        if (planField !== undefined) planField.terminationDate.field.validators = [];
      }

      //check if EE plan is terminated and then set term date as required
    }
  }

  private getDependentFieldFromId (dependentId: number): Dependent | undefined {
    let dependent = this.employerFormFields.dependents.find(dependent => {
      return dependent.dependentId.value === dependentId;
    });
    return dependent;
  }

  private toggleDependentCobraEligible (dep: Dependent | undefined, showField: boolean, control: FormGroupWarn) {
    if (dep === undefined) return;

    dep.isDependentAdressSameAsEmployee.show = showField;

    if (showField === false) {
      //remove required from address validation
      control.get('isDependentAdressSameAsEmployee')?.removeValidators(Validators.required);
      control.get('isDependentAdressSameAsEmployee')?.updateValueAndValidity();

      this.toggleDependentContactFields(dep, showField, control);
    } else {
      control.get('isDependentAdressSameAsEmployee')?.addValidators(Validators.required);
      control.get('isDependentAdressSameAsEmployee')?.updateValueAndValidity();
    }
  }

  private toggleDependentContactFields (dep: Dependent | undefined, showField: boolean, control: FormGroupWarn) {
    if (dep === undefined) return;

    dep.info.address.show = showField;
    dep.info.address2.show = showField;
    dep.info.city.show = showField;
    dep.info.phone.show = showField;
    dep.info.state.show = showField;
    dep.info.zip5.show = showField;
    dep.info.zip4.show = showField;

    if (showField) {
      control.get(['info', 'address'])?.addValidators(Validators.required);
      control.get(['info', 'city'])?.addValidators(Validators.required);
      control.get(['info', 'state'])?.addValidators(Validators.required);
      control.get(['info', 'zip5'])?.addValidators(Validators.required);
    } else {
      control.get(['info', 'address'])?.removeValidators(Validators.required);
      control.get(['info', 'address'])?.updateValueAndValidity();
      control.get(['info', 'city'])?.removeValidators(Validators.required);
      control.get(['info', 'city'])?.updateValueAndValidity();

      control.get(['info', 'state'])?.removeValidators(Validators.required);
      control.get(['info', 'state'])?.updateValueAndValidity();
      control.get(['info', 'zip5'])?.removeValidators(Validators.required);
      control.get(['info', 'zip5'])?.updateValueAndValidity();
    }
  }

  isDependentOver26Yo (birthDate: Date): boolean {
    let limitDate = moment().add(-26, 'years').format('L');

    var bdayString = moment(birthDate).format('L');

    return this.employeeFormService.isDateAfter(limitDate, bdayString);
  }

  private loadEmployerInfo () {
    let userViewState = this.userviewService.GetCurrentUserViewState();
    if (!userViewState?.EmployerId) {
      this.notificationService.error('Something went wrong, please contact an administrator');
      return;
    }
    this.employerId = userViewState.EmployerId;

    return this.employerService.GetEmployerInfoById(this.employerId);
  }

  validateOpenEnrollment () {}

  getEmployeePlan (productCode: string) {
    let plans = (this.form.get('plans') as FormArray).controls;
    let employeePlan: any;
    employeePlan = null;
    employeePlan = plans.find(x => x.get('productCode')?.value === productCode);

    return employeePlan;
  }

  getEmployerPlan(productCode: string)
  {
    const plans = this.employer.Data;

    const plan = plans?.EmployerProducts?.find(x => x.ProductCode === productCode);

    return plan;
  }

  getEmployerTerminationDate (productCode: string) {
    let terminationDate: Date | undefined;

    this.employer.Data.EmployerProducts.forEach(plan => {
      if (plan.ProductCode === productCode) {
        terminationDate = this.getDateFromString(plan.TerminationDate?.toString());
      }
    });

    return terminationDate;
  }

  getEmployerEffectiveDate (productCode: string) {
    let effectiveDate: Date | undefined;

    this.employer.Data.EmployerProducts.forEach(plan => {
      if (plan.ProductCode == productCode) {
        effectiveDate = this.getDateFromString(plan.EffectiveDate?.toString());
      }
    });
    return effectiveDate;
  }

  getEffectiveDate (productCode: string, effectiveDate: Date): Date {
    this.employer.Data.EmployerProducts.forEach(plan => {
      if (plan.ProductCode == productCode) {
        if (
          this.employeeFormService.isDateAfter(
            moment(plan.EffectiveDate).format('L'),
            moment(effectiveDate).format('L')
          )
        )
          effectiveDate = plan.EffectiveDate;
      }
    });
    return effectiveDate;
  }

  private mapGender (gender: string | undefined) {
    switch (gender) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      case 'N':
        return 'Non-Binary';
      default:
        return '';
    }
  }

  private initForm (employeeData?: EditEmployee) {
    if (this.isEditing && employeeData?.QualifyingEventDate != null) {
      this.qeSelected = true;
    }
    let isEmployeeDisabledForSpecialWP = this.isAdmin === false && this.waitingPeriod === 'X';

    let employerTerminated = false;
    if (!IsStringNullOrEmpty(this.employer?.Data?.ContractTerminationDate) && employeeData && IsStringNullOrEmpty(employeeData?.TerminationDate)) {
      employerTerminated = true;
      this.eeTerminated = true;
      employeeData.TerminationDate = this.employer?.Data?.ContractTerminationDate;

      employeeData.Plans.forEach((plan) => {
        if(plan.DoNotEnroll !== true && IsStringNullOrEmpty(plan.TerminationDate))
          {
            plan.TerminationDate = this.employer?.Data?.ContractTerminationDate;
          }
      })

      employeeData.dependent.forEach(dep => {
        if(IsStringNullOrEmpty(dep.TerminationDate)) {
          dep.TerminationDate = this.employer?.Data?.ContractTerminationDate;

          dep.plans.forEach(plan => {
            if(plan.DoNotEnroll !== true && IsStringNullOrEmpty(plan.TerminationDate))
              {
                plan.TerminationDate = this.employer?.Data?.ContractTerminationDate;
              }
          })
        }


      })
    }

    let terminationDateOnOptions = true;

    if (employeeData?.TerminationDate) {
      terminationDateOnOptions = this.terminationDateOptions.some(x => x.key === employeeData?.TerminationDate);
    }

    let employeeFormFields = {
      infoFields: {
        reinstated: {
          disable: false,
          value: false,
          show: true
        },
        qualifyingEvent: {
          field: {
            disable: !this.isAdmin,
            show: this.isEditing && employeeData?.QualifyingEvent != null,
            value: employeeData?.QualifyingEvent,
          },
          qualifyingEventOptions: this.qualifyingEventOptions,
        },
        qualifyingEventDate: {
          disable: !this.authService.IsAdmin(),
          show: this.isEditing && employeeData?.QualifyingEventDate != null,
          value: this.getDateFromString(employeeData?.QualifyingEventDate),
        },
        firstname: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.Firstname,
          validators: [Validators.required, CustomValidator.SpecialCharactersValidator,CustomValidator.StringWithNumbersValidator, Validators.maxLength(12)],
        },
        mI: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.MiddleName,
          validators: [Validators.maxLength(1)],
        },
        lastName: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.Lastname,
          validators: [Validators.required, CustomValidator.SpecialCharactersValidator,CustomValidator.StringWithNumbersValidator, Validators.maxLength(20)],
        },
        ssn: {
          disable:
            this.reinstateAllowed ||
            this.eeTerminated === true ||
            (this.isAdmin === false && this.employeeReplicated === true),
          show: true,
          value: employeeData?.SSN,
          validators: [Validators.required, Validators.minLength(9), Validators.maxLength(9)],
          asyncValidators: [this.checkDuplicateSSN()],
        },
        sex: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: this.mapGender(employeeData?.Sex),
          validators: [Validators.required],
        },
        dateOfBirth: {
          disable: this.reinstateAllowed || this.eeTerminated === true || isEmployeeDisabledForSpecialWP,
          show: true,
          value: this.getDateFromString(employeeData?.BirthDate),
          validators: [Validators.required],
        },
        address: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.StreetAddress,
          validators: [CustomValidator.SpecialCharactersValidator, Validators.maxLength(30)],
        },
        address2: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.StreetAddress2,
          validators: [CustomValidator.SpecialCharactersValidator, Validators.maxLength(30)],
        },
        city: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.City,
          validators: [CustomValidator.SpecialCharactersValidator, Validators.maxLength(17)],
        },
        state: { disable: this.reinstateAllowed || this.eeTerminated === true, show: true, value: employeeData?.State },
        zip5: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.Zip5,
          validators: [Validators.maxLength(5)],
        },
        zip4: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.Zip4,
          validators: [Validators.maxLength(4)],
        },
        zip: { disable: this.reinstateAllowed || this.eeTerminated === true, show: true },
        phone: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.Phone,
          validators: [Validators.maxLength(10)],
        },
        email: {
          disable: this.reinstateAllowed || this.eeTerminated === true,
          show: true,
          value: employeeData?.Email,
          validators: [Validators.email],
        },
        cobra: {
          disable: (this.reinstateAllowed || this.eeTerminated === true) && (!this.editingWithFutureTermination) ,
          show: this.isAdmin,
          value: employeeData?.Cobra === 'Y',
        },
        hireDate: {
          disable: this.reinstateAllowed || this.eeTerminated === true || isEmployeeDisabledForSpecialWP,
          readonly: this.isAdmin === false && this.employeeReplicated === true,
          show: true,
          value: this.getDateFromString(employeeData?.HireDate),
          validators: [Validators.required],
        },
        terminationReason: {
          field: {
            disable: true,
            show: (this.isEditing && employeeData?.REPLICATION === true) || employeeData?.TerminationDate !== undefined,
            value: employeeData?.TerminationReason,
          },
          terminationReasonOptions: this.terminationReasonOptions,
        },
        terminationDate: {
          field: {
            disable: this.reinstateAllowed || this.eeTerminated === true,
            show: (this.isEditing && employeeData?.REPLICATION === true) || employeeData?.TerminationDate !== undefined,
            value: this.getDateFromString(employeeData?.TerminationDate),
            readonly: employerTerminated
          },
          showTerminationDateAsSelect:
            this.authService.IsAdmin() === false && this.eeTerminated === false && terminationDateOnOptions,
          terminationDateOptions: this.terminationDateOptions,
        },
        OE: { disable: this.reinstateAllowed, show: true, value: employeeData?.OE },
        employernr: { disable: this.reinstateAllowed, show: true },
        cobraEligible: { disable: false, show: false },
        addressCorrect: { disable: false, show: false },
      },
      plans: [],
      dependents: [],
      customFormValidators: [],
      reinstateButton: { disable: false, show: this.reinstateAllowed },
    } as EmployeeFormFields;

    if (this.isEditing && employeeData?.HireDateReq === false) {
      employeeFormFields.infoFields.hireDate.validators = [];
    }

    if (this.authService.IsAdmin() === false) {
      employeeFormFields.infoFields.address.validators = [
        CustomValidator.SpecialCharactersValidator,
        Validators.required,
        Validators.maxLength(30),
      ];
      employeeFormFields.infoFields.city.validators = [
        CustomValidator.SpecialCharactersValidator,
        Validators.required,
        Validators.maxLength(17),
      ];
      employeeFormFields.infoFields.state.validators = [Validators.required];
      employeeFormFields.infoFields.zip5.validators = [Validators.maxLength(5), Validators.required];
    }

    if (employeeData !== null && employeeData !== undefined) {
      if (employeeData?.QualifyingEvent !== null && employeeData?.QualifyingEventDate !== null) {
        employeeFormFields.infoFields.qualifyingEvent.field.validators = [Validators.required];
        employeeFormFields.infoFields.qualifyingEventDate.validators = [Validators.required];
      }
    }

    if(employerTerminated)
      {
        this.checkCobraOnSave = true;
        employeeFormFields.infoFields.terminationReason.field.disable = false;
        employeeFormFields.infoFields.terminationReason.field.validators = [Validators.required, CustomValidator.EmptyStringValidator];
      }

    this.employerFormFields = employeeFormFields;
    this.employeeFormService.UpdateEmployeeFormFields(_ => employeeFormFields);

    if (employeeData !== undefined && employeeData !== null) {
      this.initPlans(employeeData, employeeData?.Plans);
      this.initDependents(employeeData?.dependent, employeeData);
    }
  }

  private getDateFromString (date?: string) {
    return date ? moment(new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }), 'L').toDate() : undefined;
  }

  private initPlans (employee: EditEmployee, plans?: EditPlan[]) {
    if (plans === undefined || plans === null) return;

    if (this.employerPlans.length > plans.length && (IsStringNullOrEmpty(employee.TerminationDate) === true || this.editingWithFutureTermination)) {
      this.employerPlans.forEach(erPlan => {
        if (plans.some(x => x.ProductCode == erPlan.productCode.value) === true) return;

        let effDate = this.calculateWaitingPeriod(
          employee.HireDate == null ? new Date() : moment(employee.HireDate).toDate()
        );

        const erDataPlan = this.employer.Data.EmployerProducts.find(x => x.ProductCode === erPlan.productCode.value)

        effDate = this.getEffectiveDate(erPlan.productCode?.value ?? '', effDate);
        plans.push({
          ProductCode: erPlan.productCode.value,
          EffectiveDate: moment(effDate).format('L'),
          WaiverEffectiveDate: '',
          DentalOffice: '',
          DoNotEnroll: false,
          productName: erPlan.productName.value,
          PlanName: erPlan.productName.value,
          TerminationDate: erDataPlan?.TerminationDate,
          Replicated: false,
          DependentID: 0,
          sort: 20,
          PlanValid: true,
          ErTerminatedPlan: false,
          EmployeeID: employee.EmployeeID,
        } as EditPlan);
      });
    }

    let xCompany = this.waitingPeriod === 'X' && this.isAdmin === false;

    let isDeltaOnly = plans.length === 1 && plans[0].ProductCode === 'Dental';

    plans.sort((a, b) => a.sort - b.sort);

    let mappedPlans = plans.map<Plan>(plan => {
      let cobra = employee.Cobra === '1';

      let donotEnrollEnabled = plan.DoNotEnroll === true;

      let isTerminationDateSelectable =
        this.terminationDateOptions.some(
          x => moment(x.key).format('L') === moment(plan.TerminationDate).format('L')
        ) === true;

      let showTerminationDateAsSelect =
        this.authService.IsAdmin() === false &&
        (IsStringNullOrEmpty(plan.TerminationDate) === true || isTerminationDateSelectable === true);

      let shouldTerminationBeReadOnly =
        this.authService.IsAdmin() === false &&
        isTerminationDateSelectable === false &&
        IsStringNullOrEmpty(plan.TerminationDate) === false &&
        plan.ProductCode != 'Dental';

      return {
        replicated: {
          disable: false,
          show: true,
          value: plan.Replicated,
        },
        doNotEnroll: {
          disable:
            this.reinstateAllowed ||
            IsStringNullOrEmpty(employee?.TerminationDate) === false ||
            plan.ErTerminatedPlan === true ||
            xCompany,
          value: plan.DoNotEnroll,
          show:
            this.shouldDisplayDoNotEnroll(plan.ProductCode) &&
            ((this.isEditing &&
              plan.DoNotEnroll === true &&
              this.employeeReplicated === true &&
              plan.Replicated === true) ||
              this.isEditing === false ||
              (this.isEditing === true && (this.employeeReplicated === false || plan.Replicated === false)) ||
              this.isAdmin === true),
        },
        effectiveDate: {
          disable:
            this.shouldPlanEffectiveDateBeDisabled(
              plan.DoNotEnroll,
              employee?.TerminationDate ?? '',
              this.reinstateAllowed
            ) || plan.ErTerminatedPlan === true,
          readonly: this.isAdmin === false,
          show: true,
          value: this.getDateFromString(plan.EffectiveDate),
        },
        productCode: { disable: this.reinstateAllowed, show: true, value: plan.ProductCode },
        productName: { disable: false, show: true, value: plan.PlanName, validators: [] },
        terminationDate: {
          field: {
            disable:
              this.shouldPlanTerminationDateBeDisabled(
                plan.DoNotEnroll,
                this.reinstateAllowed,
                employee?.TerminationDate ?? '',
                plan.ProductCode,
                isDeltaOnly,
                cobra
              ) ||
              plan.ErTerminatedPlan === true ||
              this.eeTerminated === true ||
              (this.shouldDisplayDoNotEnroll(plan.ProductCode) && plan.DoNotEnroll === true && xCompany),
            show:
              (this.isEditing && this.employeeReplicated === true) ||
              (!this.shouldDisplayDoNotEnroll(plan.ProductCode) &&
                this.authService.IsAdmin() &&
                this.employeeReplicated === true),
            value: IsStringNullOrEmpty(plan.TerminationDate) === false ? moment(plan.TerminationDate).format('L') : '',
            readonly: plan.ProductCode === 'Dental' || shouldTerminationBeReadOnly,
          },
          showTerminationDateAsSelect: showTerminationDateAsSelect,
          terminationDateOptions: this.terminationDateOptions,
        },
        dentalOffice: {
          disable: this.reinstateAllowed || this.isAdmin !== true || xCompany || IsStringNullOrEmpty(plan.TerminationDate) === false,
          show: plan.ProductCode === 'Dental' && this.PPO === false,
          value: plan.DentalOffice,
          validators: [Validators.maxLength(6)],
        },
        waiverDate: {
          disable: this.reinstateAllowed || plan.ErTerminatedPlan === true || xCompany || IsStringNullOrEmpty(plan.TerminationDate) === false,
          readonly: this.isAdmin === false && this.employeeReplicated === true,
          show: (plan.ProductCode == 'Dental' || plan.ProductCode == 'EyeMed') && plans.length > 1,
          value: this.getDateFromString(plan.WaiverEffectiveDate),
        },
      };
    });
    this.employerFormFields.plans = mappedPlans;
    this.employeeFormService.UpdateEmployeeFormFields(prevState => ({ ...prevState, plans: mappedPlans }));
  }

  isTerminationPlanDisabled (
    productCode: string,
    isDeltaOnly: boolean,
    cobra: boolean,
    terminationDate: Date | undefined
  ) {
    let isTerminationDateDisabled = false;

    if (isDeltaOnly && (productCode === 'Dental' || productCode === 'EyeMed')) {
      isTerminationDateDisabled = true;
    } else if ((productCode === 'Dental' || productCode === 'EyeMed') && isDeltaOnly === false) {
      isTerminationDateDisabled = false;
    }

    if (!(productCode === 'Dental' || productCode === 'EyeMed')) {
      isTerminationDateDisabled = true;
    }

    if (this.shouldDisplayDoNotEnroll(productCode) === true) {
      isTerminationDateDisabled = false;
    }

    if (isTerminationDateDisabled === false || cobra) {
      isTerminationDateDisabled = false;
    }

    if (isTerminationDateDisabled === true && cobra === false) {
      isTerminationDateDisabled = true;
    } else {
      isTerminationDateDisabled = false;
    }

    if (
      this.isEditing &&
      this.reinstateAllowed === false &&
      terminationDate !== null &&
      terminationDate !== undefined
    ) {
      isTerminationDateDisabled = false;
    }

    return isTerminationDateDisabled;
  }

  onReinstateDependent (dependent: FormGroupWarn) {
    this.notificationService.confirmation('Are you sure you want to reinstate the dependent?', () => {
      let dependentId = dependent.get('dependentId')?.value;
      let depField = this.getDependentFieldFromId(dependentId);

      if (depField === undefined) return;
      dependent.patchValue({ Reinstate: false });
      dependent.patchValue({ terminationDate: null });
      dependent.patchValue({ terminationReason: null });
      dependent.patchValue({ reinstated: true });

      depField.reinstateButton.show = false;
      depField.terminationDate.showTerminationDateAsSelect = this.isAdmin === false;
      this.enableField(dependent, 'firstName');
      this.enableField(dependent, 'relationshipCode');
      this.enableField(dependent, 'lastName');
      this.enableField(dependent, 'birthDate');
      this.enableField(dependent, 'terminationDate');
      this.enableField(dependent, 'status');

      let plansArray = dependent.get('plans') as FormArray;
      let plans = plansArray.controls;

      let isDeltaOnly = plans.length === 1 && plans[0].get('productCode')?.value === 'Dental';
      let cobra = this.form.get(['info', 'cobra'])?.value === '1';

      let plansFields = depField.plans;

      plansFields.forEach(plan => {
        if (this.isAdmin === false) {
          plan.terminationDate.showTerminationDateAsSelect = true;
        }
      });

      plans.forEach(plan => {
        let employeePlan = this.getEmployeePlan(plan.get('productCode')?.value);

        if (IsStringNullOrEmpty(employeePlan?.get('terminationDate')?.value)) {
          plan.patchValue({ terminationDate: null });
        }

        if (IsStringNullOrEmpty(employeePlan?.get('waiverDate')?.value)) {
          plan.patchValue({ waiverDate: null });
        }

        let doNotEnroll = plan.get('doNotEnroll')?.value;
        let depTerminationDate = dependent.get('terminationDate')?.value;
        let productCode = plan.get('productCode')?.value;
        let shouldDepEffectiveDateBeDisbled = this.shouldPlanEffectiveDateBeDisabled(
          doNotEnroll,
          depTerminationDate,
          false
        );
        let shouldDepTerminationDateBeDisabled = this.shouldPlanTerminationDateBeDisabled(
          doNotEnroll,
          false,
          depTerminationDate,
          productCode,
          isDeltaOnly,
          cobra
        );

        if (this.isAdmin === true) this.enableField(plan, 'effectiveDate');

        if (shouldDepTerminationDateBeDisabled === false) this.enableField(plan, 'terminationDate');

        this.enableField(plan, 'waiverDate');
        plan.get('terminationDate')?.removeValidators(Validators.required);
        plan.get('terminationDate')?.updateValueAndValidity();

        this.enableField(plan, 'doNotEnroll');
      });

      var eePlans = (this.form.get(['plans']) as FormArray).controls;

      if (eePlans.length > plans.length) {
        eePlans.forEach(eePlan => {
          // product.ProductCode != 'LTD' && product.ProductCode != 'STD' && product.ProductCode != 'Life'
          if (
            eePlan.get('productCode')?.value === 'LTD' ||
            eePlan.get('productCode')?.value === 'STD' ||
            eePlan.get('productCode')?.value === 'Life'
          )
            return;
          if (plans.some(x => x.get('productCode')?.value == eePlan.get('productCode')?.value) === true) return;

          let index = this.employeeFormService.employeeFields.value.dependents
            .map(x => x.dependentId.value)
            .indexOf(dependent.controls.dependentId?.value);

          //get new eff date

          this.addNewPlanToDependent(dependent, plansArray, eePlan as FormGroupWarn, index);
        });
      }
    });
  }

  private addNewPlanToDependent (
    dependent: AbstractControl,
    plans: FormArray,
    employeePlan: FormGroupWarn,
    index: number
  ) {
    let dep = {
      Reinstate: IsStringNullOrEmpty(dependent?.get('terminationDate')?.value) ? false : true,
      TerminationDate: dependent?.get('terminationDate')?.value,
      depReplicated: dependent?.get('depReplicated')?.value,
    } as unknown as EditDependent;

    dep.plans = plans.controls.map<EditPlan>(plan => ({
      DependentID: dependent.get('dependentId')?.value,
      ProductCode: plan?.get('productCode')?.value,
      productName: plan?.get('productName')?.value,
      WaiverEffectiveDate: plan?.get('waiverDate')?.value,
      TerminationDate: plan?.get('terminationDate')?.value,
      DentalOffice: plan?.get('dentalOffice')?.value,
      EffectiveDate: plan?.get('effectiveDate')?.value,
      DoNotEnroll: plan?.get('doNotEnroll')?.value,
      Replicated: plan.get('replicated')?.value,
      sort: 1,
      PlanValid: true,
      PlanName: plan?.get('productName')?.value,
      ErTerminatedPlan: false,
    }));

    let effDate = this.getEffectiveDate(
      employeePlan?.get('productCode')?.value,
      employeePlan?.get('effectiveDate')?.value
    );

    let depPlan = {
      DependentID: dependent.get('dependentId')?.value,
      ProductCode: employeePlan?.get('productCode')?.value,
      productName: employeePlan?.get('productName')?.value,
      WaiverEffectiveDate: employeePlan?.get('waiverDate')?.value,
      TerminationDate: employeePlan?.get('terminationDate')?.value,
      DentalOffice: employeePlan?.get('dentalOffice')?.value,
      EffectiveDate: moment(effDate).format('L'),
      DoNotEnroll: employeePlan?.get('doNotEnroll')?.value,
      Replicated: false,
    } as EditPlan;
    let employee = {
      Cobra: this.form.get(['info', 'cobra'])?.value,
      TerminationDate: this.form.get(['info', 'terminationDate'])?.value,
    } as EditEmployee;
    let dependentPlan = this.addPlanToDependent(dep, depPlan, employee);
    this.employeeFormService.employeeFields.value.dependents[index].plans.push(dependentPlan);

    //ADD PLAN TO FORMCONTROL
    let planControl = this.employeeForm.createDependentPlanControl(dependentPlan);

    plans.push(planControl);

    this.planChanges(planControl, dependent as FormGroupWarn, plans, index);
  }

  private enableField (control: AbstractControl, fieldName: string) {
    setTimeout(() => {
      control.get(fieldName)?.enable();
    }, 1);
    // control.get(fieldName)?.updateValueAndValidity();
  }

  private initDependents (dependents?: EditDependent[], employee?: EditEmployee) {
    if (dependents === null || dependents === undefined) return;

    let mappedDependents = dependents.map<Dependent>(dependent => {
      dependent.plans.sort((a, b) => a.sort - b.sort);

      //check EE plans to see if they match
      if ((employee?.Plans.length ?? 0 > dependent.plans.length) && (IsStringNullOrEmpty(dependent.TerminationDate) || this.editingWithFutureTermination)) {
        employee?.Plans.forEach(eePlan => {
          // product.ProductCode != 'LTD' && product.ProductCode != 'STD' && product.ProductCode != 'Life'
          if (eePlan.ProductCode === 'LTD' || eePlan.ProductCode === 'STD' || eePlan.ProductCode === 'Life') return;
          if (eePlan.DoNotEnroll === true) return;
          if ((IsStringNullOrEmpty(eePlan.TerminationDate) === false) && this.editingWithFutureTermination === false) return;
          if (dependent.plans.some(x => x.ProductCode == eePlan.ProductCode) === true) return;

          if(this.editingWithFutureTermination)
            {
              if(this.employeeFormService.isDateAfter(
                moment(moment()).format('L'),
                moment(dependent.TerminationDate).format('L'))) 
                return
            }


          dependent.plans.push({
            ProductCode: eePlan.ProductCode,
            EffectiveDate: moment(eePlan.EffectiveDate).format('L'),
            WaiverEffectiveDate: '',
            TerminationDate: eePlan.TerminationDate,
            DentalOffice: '',
            DoNotEnroll: false,
            productName: eePlan.productName,
            PlanName: eePlan.productName,
            Replicated: false,
            DependentID: dependent.DependentID,
            sort: eePlan.sort,
            PlanValid: true,
            ErTerminatedPlan: false,
          } as EditPlan);
        });
      }

      return {
        dependentId: { disable: dependent.Reinstate, show: false, value: dependent.DependentID },
        depReplicated: { disable: dependent.Reinstate, show: false, value: dependent.DepReplicated },
        firstName: {
          disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
          show: true,
          value: dependent.FirstName,
          validators: [Validators.required, Validators.maxLength(12),CustomValidator.StringWithNumbersValidator, CustomValidator.SpecialCharactersValidator],
        },
        relationshipCode: {
          disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
          show: true,
          value: dependent.RelationshipCode,
          validators: [Validators.required],
        },
        lastName: {
          disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
          show: true,
          validators: [Validators.required, Validators.maxLength(20),CustomValidator.StringWithNumbersValidator, CustomValidator.SpecialCharactersValidator],
          value: dependent.LastName,
        },
        birthDate: {
          disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
          show: true,
          validators: [Validators.required],
          value: this.getDateFromString(dependent.Birthdate),
        },
        plans: dependent.plans.map<Plan>(plan => this.addPlanToDependent(dependent, plan, employee)),

        info: {
          address: {
            disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            show: false,
            validators: [CustomValidator.SpecialCharactersValidator, Validators.maxLength(30)],
          },
          address2: {
            disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            show: false,
            validators: [CustomValidator.SpecialCharactersValidator, Validators.maxLength(30)],
          },
          city: {
            disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            show: false,
            validators: [Validators.maxLength(17)],
          },
          phone: {
            disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            show: false,
            validators: [Validators.maxLength(10)],
          },
          state: {
            disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            show: false,
            validators: [],
          },
          zip: {
            disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            show: false,
            validators: [],
          },
          zip4: {
            disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            show: false,
            validators: [Validators.maxLength(4)],
          },
          zip5: {
            disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            show: false,
            validators: [Validators.maxLength(5)],
          },
        },

        isCobraEligible: {
          disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
          show: false,
          validators: [],
        },
        isDependentAdressSameAsEmployee: {
          disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
          show: false,
          validators: [],
        },
        cobraQuestions: { show: false, disable: false },
        status: {
          disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
          show: this.shouldShowDependentStatus(dependent.RelationshipCode),
          value: dependent.Status,
        },
        terminationDate: {
          field: {
            disable: dependent.Reinstate || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            show: dependent.DepReplicated || IsStringNullOrEmpty(dependent.TerminationDate) === false,
            readonly: IsStringNullOrEmpty(dependent.TerminationDate) === false,
            value: this.getDateFromString(dependent.TerminationDate),
          },
          terminationDateOptions: this.terminationDateOptions,
          showTerminationDateAsSelect:
            this.isAdmin === false && IsStringNullOrEmpty(dependent.TerminationDate) === true,
        },
        terminationReason: {
          field: {
            disable: true,
            show: dependent.DepReplicated && this.verifyAddress === true,
            value: dependent.TerminationReason,
          },
        },
        reinstateButton: { show: dependent.Reinstate && !this.employerFormFields.reinstateButton.show, disable: false },
        Reinstated: { show: false, disable: false },
        qualifyingEventAdd: { show: false, disable: false, value: false },
        qualifyingEvent: {
          show: dependent.QualifyEventName !== null && dependent.QualifyEventName !== '',
          disable:
            this.authService.IsAdmin() === false ||
            dependent.Reinstate === true ||
            IsStringNullOrEmpty(dependent.TerminationDate) === false,
          value: dependent.QualifyEventName,
        },
        qualifyingEventDate: {
          show: dependent.QualifyEventName !== null && dependent.QualifyEventName !== '',
          disable:
            this.authService.IsAdmin() === false ||
            dependent.Reinstate === true ||
            IsStringNullOrEmpty(dependent.TerminationDate) === false,
          value: this.getDateFromString(dependent.QualifyEventDate),
        },
      };
    });
    this.employerFormFields.dependents = mappedDependents;
    this.employeeFormService.UpdateEmployeeFormFields(prevState => ({ ...prevState, dependents: mappedDependents }));
  }

  private shouldShowDependentStatus (relationshipCode: string) {
    return (
      (relationshipCode === 'S' || relationshipCode === 'D' || relationshipCode === 'N' || relationshipCode === 'C') &&
      this.isAdmin
    );
  }

  private addPlansOnEmployerInfo () {
    return map<EmployersApiResponse, EmployersApiResponse>(res => {
      this.openEnrollmentMonth = res.Data.Oemonth;

      if (this.openEnrollmentMonth > 0) {
        let oeMonth = moment(this.openEnrollmentMonth, 'M');

        let currentMonth = moment().startOf('month');

        if (oeMonth.isSameOrAfter(currentMonth)) {
          this.openEnrollmentDate = new Date(moment(this.openEnrollmentMonth, 'M').startOf('month').format('L'));
        } else {
          this.openEnrollmentDate = new Date(
            moment(this.openEnrollmentMonth, 'M').startOf('month').add(1, 'year').format('L')
          );
        }
      }

      this.PPO = parseInt(res.Data.PlanNr) % 2 == 0 ? true : false;
      this.waitingPeriod = res.Data.WaitingPeriod;

      if (this.waitingPeriod === 'X' && this.isAdmin === false) {
        this.enableAddDependent = false;
      }
      this.verifyAddress = res.Data.CobraType.VerifyAddress;
      this.employer = res;

      if (this.verifyAddress) {
        this.terminationReasonOptions = [
          {
            optionText: 'Termination-Voluntary',
            key: 'TV',
          },
          {
            optionText: 'Termination-Involuntary',
            key: 'TI',
          },
          {
            optionText: 'Status Change',
            key: 'SC',
          },
          {
            optionText: 'Retirement',
            key: 'RE',
          },
          {
            optionText: 'Loss Of Eligibility',
            key: 'LE',
          },
          {
            optionText: 'Other Reason',
            key: 'OR',
          },
        ];
      } else {
        this.terminationReasonOptions = [
          {
            optionText: 'Employment Termination',
            key: 'ET',
          },
          {
            optionText: 'Leave of Absence',
            key: 'LA',
          },
        ];
      }

      res.Data.EmployerProducts = res.Data.EmployerProducts.filter(
        x =>
          x.TerminationDate == null ||
          x.TerminationDate == undefined ||
          this.employeeFormService.isDateAfter(moment(x.TerminationDate).format('L'), moment().format('L'))
      );

      res.Data.EmployerProducts = res.Data.EmployerProducts.sort((a, b) => (a.Sort > b.Sort ? 1 : -1));
      let employerPlans = res.Data.EmployerProducts.map<Plan>(employerProduct => {
        // let employerPlanTerminationDate = this.getEmployerTerminationDate(plan.ProductCode);

        if (
          employerProduct.TerminationDate !== undefined &&
          this.employeeFormService.isDateAfter(
            moment(employerProduct.TerminationDate).format('L'),
            moment().format('L')
          )
        ) {
          if(this.isEditing === false)
            this.addingWithTermination = true;
        }

        return {
          replicated: { disable: false, show: false, value: false },
          doNotEnroll: { disable: false, show: this.shouldDisplayDoNotEnroll(employerProduct.ProductCode) },
          effectiveDate: { disable: this.authService.IsAdmin() === false, show: true },
          productCode: { disable: false, show: true, value: employerProduct.ProductCode },
          productName: { disable: false, show: true, value: employerProduct.Description, validators: [] },
          terminationDate: {
            field: {
              disable: false,
              show:
                (this.shouldDisplayDoNotEnroll(employerProduct.ProductCode) && this.authService.IsAdmin()) ||
                this.addingWithTermination,
              readonly: this.addingWithTermination,
              value: (this.addingWithTermination|| this.editingWithFutureTermination) ? moment(employerProduct.TerminationDate).format('L') : undefined,
            },
            showTerminationDateAsSelect: this.authService.IsAdmin() === false && this.eeTerminated === false,
            terminationDateOptions: this.terminationDateOptions,
          },
          dentalOffice: {
            disable: false,
            show: employerProduct.ProductCode === 'Dental' && this.PPO === false,
            validators: [Validators.maxLength(6)],
          },
          waiverDate: {
            disable: false,
            show:
              (employerProduct.ProductCode == 'Dental' || employerProduct.ProductCode == 'EyeMed') &&
              res.Data.EmployerProducts.length > 1,
          },
        };
      });

      this.employerPlans = employerPlans;

      this.employeeFormService.UpdateEmployeeFormFields(prevState => ({ ...prevState, plans: employerPlans }));
      return res;
    });
  }

  toggleDependentPlan (productCode: string, show: boolean) {
    // let dependents = this.employeeFormService.employeeFields.value.dependents;

    // dependents.forEach(dependent => {
    //   let depPlan = dependent.plans.find(x => x.productCode.value == productCode);
    //   if(depPlan === undefined) return;

    //   depPlan.productCode.show = show;

    // });

    if (show) {
      let dependents = (this.form.get('dependents') as FormArray).controls;
      dependents.forEach((dependent, index) => {
        //ADD PLAN TO FORMfIELD

        let depTermDate = dependent.get('terminationDate')?.value;

        if (IsStringNullOrEmpty(depTermDate) === false) return;
        let plans = dependent.get('plans') as FormArray;

        if (plans.controls.some(plan => plan?.get('productCode')?.value === productCode)) return;

        //get employeePlan
        let employeePlan = this.getEmployeePlan(productCode);

        /*
  Reinstate
  TerminationDate
  depReplicated
   */
        this.addNewPlanToDependent(dependent, plans, employeePlan, index);
      });
    } else {
      //delete from formFields
      let dependents = (this.form.get('dependents') as FormArray).controls;

      dependents.forEach((dependent, index) => {
        let plans = dependent.get('plans') as FormArray;

        let depTermDate = dependent.get('terminationDate')?.value;

        if (IsStringNullOrEmpty(depTermDate) === false) return;

        if (
          plans.controls.some(
            plan => plan?.get('productCode')?.value === productCode && plan?.get('replicated')?.value === true
          )
        )
          return;

        let depField = this.employeeFormService.employeeFields.value.dependents[index];

        this.employeeFormService.employeeFields.value.dependents[index].plans = depField.plans.filter(
          x => x.productCode.value !== productCode
        );

        plans.removeAt(plans.controls.findIndex(x => x.get('productCode')?.value === productCode));
      });
    }
  }

  shouldPlanTerminationDateBeDisabled (
    doNotEnroll: boolean,
    reinstateAllowed: boolean,
    terminationDate: string,
    productCode: string,
    isDeltaOnly: boolean,
    cobra: boolean
  ): boolean {
    let isTerminationDateDisabled = this.isTerminationPlanDisabled(
      productCode,
      isDeltaOnly,
      cobra,
      this.getDateFromString(terminationDate)
    );
    return (
      this.authService.IsAdmin() === false &&
      (reinstateAllowed === true ||
        doNotEnroll === true ||
        isTerminationDateDisabled === true ||
        this.shouldDisplayDoNotEnroll(productCode) === false ||
        IsStringNullOrEmpty(terminationDate) === false)
    );
  }
  shouldPlanEffectiveDateBeDisabled (doNotEnroll: boolean, terminationDate: string, reinstateAllowed: boolean): boolean {
    return (
      reinstateAllowed === true ||
      doNotEnroll === true ||
      IsStringNullOrEmpty(terminationDate) === false ||
      (this.waitingPeriod === 'X' && this.isAdmin === false)
    );
  }

  mapDateToUTC (date: string) {
    if (date === null || date === undefined || date === '' || date === 'Invalid Date') {
      return '';
    }
    return `${moment(date, 'L').format('yyyy-MM-DD')}T00:00:00.000Z`;
  }

  mapDateToUTCDate (date: string) {
    return moment(moment(date, 'L').format('yyyy-MM-DD')).toDate();
  }

  shouldDisplayDoNotEnroll (productCode: string): boolean {
    return productCode == 'VSP' || productCode == 'DeltaVision' || (productCode == 'PPP' && this.P3Mandatory === false);
  }

  generatePDF () {
    this.printing = true;

    setTimeout(() => {
      let element = document.getElementById('employee-print');
      this.printing = false;

      if (element === null) return;

      html2canvas(element, {
        onclone: document => {
          let printElement = document.getElementById('employee-print');

          if (printElement === null) return;

          printElement.style.display = 'block';
        },
      }).then(canvas => {
        let imgData = canvas.toDataURL('image/png');
        let pdf = new jsPDF('l', 'px', [canvas.width, canvas.height > 1000 ? canvas.height : 1000]);

        pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
        pdf.save('Enrollee Information - ' + this.employee.Firstname + ' ' + this.employee.Lastname + '.pdf');
      });
    }, 100);
  }

  AddContentToPdf (employee: any, pdf: jsPDF, x: number) {
    pdf.beginFormObject(0, 0, 595, 20, {
      name: 'Employee Record',
      align: 'center',
      orientation: 'portrait',
      unit: 'pt',
      format: [595, 842],
    });
    pdf.endFormObject('Employee Record');

    const addValue = 20;
    Object.keys(employee).map((key, index) => {
      const value = employee[key];

      if (this.currentPdfY >= 800) {
        pdf.addPage();
        this.currentPdfY = 0;
      }

      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        if (Number.isNaN(Number(key))) {
          this.currentPdfY = this.currentPdfY + addValue;
          pdf.setFont('', 'bold').text(`${key}:`, x, this.currentPdfY).setFont('', 'normal');

          this.AddContentToPdf(value, pdf, x + addValue);
        } else {
          this.AddContentToPdf(value, pdf, x + addValue);
        }
      } else {
        if (IsStringNullOrEmpty(value) === false) {
          this.currentPdfY = this.currentPdfY + addValue;
          pdf.text(`${key}: ${value}`, x, this.currentPdfY);
        }
      }
    });
  }

  // MapEmployeeToPdfObject()
  // {
  //   let employeeObject = this.mapToEmployeeRequest(this.form);

  //   let termDate = this.getPdfFormatDate(employeeObject.TerminationDate?.toString())
  //   return {
  //     'Employee Record': {
  //       'First Name': employeeObject.Firstname,
  //       'MI': employeeObject.MiddleName,
  //       'Last Name': employeeObject.Lastname,
  //       'SSN': employeeObject.SSN,
  //       'Date of Birth': this.getPdfFormatDate(employeeObject.BirthDate?.toString()),
  //       'Gender': employeeObject.Sex,
  //       'Hire Date': this.getPdfFormatDate(employeeObject.HireDate?.toString()),
  //       'Termination Date': this.getPdfFormatDate(employeeObject.TerminationDate?.toString()),
  //       'Qualifying Event': employeeObject.QualifyingEvent ?? 0 > 0 ? this.qualifyingEventOptions.filter(x => x.key === employeeObject.QualifyingEvent)[0].optionText : '',
  //       'Qualifying Event Date': this.getPdfFormatDate(employeeObject.QualifyingEventDate?.toString()),
  //     },
  //     'Contact': {
  //       'Address': employeeObject.StreetAddress,
  //       'Address Line 2': employeeObject.StreetAddress2,
  //       'City': employeeObject.City,
  //       'State': employeeObject.State,
  //       'Zip5': employeeObject.Zip5,
  //       'Zip4': employeeObject.Zip4,
  //       'Phone': employeeObject.Phone,
  //       'Email': employeeObject.Email
  //     },
  //     'Benefits': employeeObject.plans?.map(plan =>
  //        ({
  //         'Product Code': plan.ProductCode,
  //         'Effective Date': this.getPdfFormatDate(plan.EffectiveDate),
  //         'Waiver Date': this.getPdfFormatDate(plan.WaiverEffectiveDate),
  //         'Do Not Enroll': plan.DoNotEnroll === true ? 'Yes' : 'No',
  //         'Dental Office': plan.DentalOffice ?? '',
  //         'Termination Date': this.getPdfFormatDate(plan.TerminationDate),

  //     })),
  //     'Dependents': employeeObject.dependent?.map(dependent => ({
  //         'Relationship': this.getRelationShipName(dependent.RelationshipCode),
  //         'First Name': dependent.FirstName,
  //         'Last Name': dependent.LastName,
  //         'Birth Date': this.getPdfFormatDate(dependent.BirthDate?.toString()),
  //         'Qualifying Event': IsStringNullOrEmpty(dependent.QualifyEventName) ? '' : this.qualifyingEventDepOptions.filter(x => x.key === dependent.QualifyEventName)[0].optionText,
  //         'Qualifying Event Date': this.getPdfFormatDate(dependent.QualifyEventDate?.toString()),
  //         'Benefits': dependent.plans?.map(plan => ({
  //           'Product Code': plan.ProductCode,
  //           'Effective Date': this.getPdfFormatDate(plan.EffectiveDate),
  //           'Waiver Date': this.getPdfFormatDate(plan.WaiverEffectiveDate),
  //           'Do Not Enroll': plan.DoNotEnroll === true ? 'Yes' : 'No',
  //           'Dental Office': plan.DentalOffice ?? '',
  //           'Termination Date': this.getPdfFormatDate(plan.TerminationDate),
  //         }))
  //     }))
  //   }
  // }

  private createFormFields () {}
}

export interface DependentChanges {
  index: number;
  dependentCreated: boolean;
  dependent: FormGroupWarn;
}
