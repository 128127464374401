import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { request } from 'playwright';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { InterceptorSkipRegionHeader } from 'src/app/core/interceptors/region.interceptor';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import { AuthenticateRequest } from 'src/app/core/models/api/auth/requests/authenticate.request.model';
import { RegisterBaseRequest } from 'src/app/core/models/api/auth/requests/register/register-base.request.model';
import { ResetRequest } from 'src/app/core/models/api/auth/requests/reset.request.model';
import {
  AuthenticationResponse,
  AuthenticationResponseResponse,
} from 'src/app/core/models/api/auth/responses/authentication.response.model';
import {
  RegionCodes,
  GetRegionsResponse,
  RegionEnum,
} from 'src/app/core/models/api/auth/responses/get-regions.response';
import {
  UserResponse,
  UsersAPIResponse,
} from 'src/app/core/models/api/auth/responses/user/info/user-info.response.model';
import { UsersPrivilegeAPIResponse } from 'src/app/core/models/api/auth/responses/user/info/user-privileges.response.model';
import { UserRightCodeEnum } from 'src/app/core/models/api/auth/responses/user/user-rights/user-right-code.enum';
import { UserRightsResponse } from 'src/app/core/models/api/auth/responses/user/user-rights/user-rights.response.model';
import { SsoRegionEndpointEnum } from 'src/app/core/models/api/auth/SsoRegionEndpointEnum';
import { environment } from 'src/environments/environment';
import { JwtTokenService } from './jwt-token.service';
import { SsoPrivilegeApiResponse, SsoPrivilegeResponse } from 'src/app/core/models/api/sso/sso-privilege.response';
import { SsoApiResponse } from 'src/app/core/models/api/sso/sso.response';
import { RightsApiResponse } from 'src/app/core/models/api/auth/responses/rights.response';
import { AdminUserApiRequest } from 'src/app/core/models/api/auth/requests/update-create-admin-user.request';
import { SSOPostApiResponse } from 'src/app/core/models/api/sso/sso-post.response';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private AUTHENTICATION_RESPONSE_STORAGE_NAME: string = 'session';
  private USER_RIGHTS_STORAGE_NAME: string = 'user_rights';
  private EXPIRE_AT_STORAGE_NAME: string = 'expire_at';
  private USER_INFO_STORAGE_NAME: string = 'user_info';
  private SSO_PRIVILEGES: string = 'ssoPrivileges';

  private userRightsSubject: BehaviorSubject<UserRightsResponse | null>;
  public userRights: Observable<UserRightsResponse | null>;

  private userSessionSubject: BehaviorSubject<AuthenticationResponseResponse | null>;
  public userSession: Observable<AuthenticationResponseResponse | null>;

  private userInfoSubject: BehaviorSubject<UserResponse | null>;
  public userInfo: Observable<UserResponse | null>;

  constructor(private http: HttpClient, private jwtTokenService: JwtTokenService) {
    this.userRightsSubject = new BehaviorSubject<UserRightsResponse | null>(this.getUserRightsFromLocalStorage());
    this.userRights = this.userRightsSubject.asObservable();

    this.userSessionSubject = new BehaviorSubject<AuthenticationResponseResponse | null>(
      this.getUserFromLocalStorage()
    );
    this.userSession = this.userSessionSubject.asObservable();

    this.userInfoSubject = new BehaviorSubject<UserResponse | null>(this.getUserInfoFromLocalStorage());
    this.userInfo = this.userInfoSubject.asObservable();
  }

  Login(username: string, password: string): Observable<AuthenticationResponse> {
    return this.http
      .post<AuthenticationResponse>(
        `${environment.alliedApi.baseUrl}/authenticate`,
        new AuthenticateRequest(username, password)
      )
      .pipe(tap(val => val.status && this.setToken(val.response[0])));
  }

  Logout() {
    this.http.get(`${environment.alliedApi.baseUrl}/Userctrl/Logoff`).subscribe(reponse => {});
    localStorage.removeItem(this.EXPIRE_AT_STORAGE_NAME);

    localStorage.removeItem(this.USER_RIGHTS_STORAGE_NAME);

    localStorage.removeItem(this.AUTHENTICATION_RESPONSE_STORAGE_NAME);

    localStorage.removeItem(this.USER_INFO_STORAGE_NAME);

    localStorage.removeItem(this.SSO_PRIVILEGES);

    this.userInfoSubject.next(null);
    this.userRightsSubject.next(null);
    this.userSessionSubject.next(null);
  }

  Reset(userName: string, email: string) {
    console.log('Reset');

    return this.http.post<UserRightsResponse>(
      `${environment.alliedApi.baseUrl}/authenticate/reset`,
      new ResetRequest(userName, email)
    );
  }

  GetRights() {
    return this.http.get<RightsApiResponse>(`${environment.alliedApi.baseUrl}/UserPrivileges/rights`);
  }

  Register(request: RegisterBaseRequest) {
    return this.http.post<UserRightsResponse>(`${environment.alliedApi.baseUrl}/authenticate/register`, request);
  }

  LoginWithMFA(userId: string, mfaCode: string, region: RegionEnum) {
    let request = { userId, mfaCode, region };
    return this.http
      .post<AuthenticationResponse>(`${environment.alliedApi.baseUrl}/authenticate/mfaLogin`, request)
      .pipe(tap(val => val.status && this.setToken(val.response[0])));
  }

  SubmitMfaOptin(opted: boolean, region: RegionCodes) {
    return this.http.post<UserRightsResponse>(
      `${environment.alliedApi.baseUrl}/authenticate/mfaOptin`,
      {
        MfaOpted: opted,
      },
      { headers: { region: region.toString() } }
    );
  }

  IsLoggedIn() {
    return !!localStorage.getItem(this.AUTHENTICATION_RESPONSE_STORAGE_NAME) && moment().isBefore(this.GetExpiration());
  }

  IsAdmin() {
    let user = this.getUserFromLocalStorage();
    if (user?.Type != AuthenticationUserType.Admin) return false;
    return true;
  }

  IsEr() {
    let user = this.getUserFromLocalStorage();
    if (user?.Type != AuthenticationUserType.Employer) return false;
    return true;
  }

  IsMP() {
    let user = this.getUserFromLocalStorage();
    if (user?.Type != AuthenticationUserType.Producer) return false;
    return true;
  }

  GetSSOEndpoint(region: RegionCodes): SsoRegionEndpointEnum {
    return (
      new Map<RegionCodes, SsoRegionEndpointEnum>([
        [RegionCodes.DDCA, SsoRegionEndpointEnum.DDCASSO],
        [RegionCodes.DDIC, SsoRegionEndpointEnum.DDICSSO],
        [RegionCodes.DDPA, SsoRegionEndpointEnum.DDMASSO],
      ]).get(region) || SsoRegionEndpointEnum.DDNJSSO
    );
  }

  GetExpiration() {
    const expiration = localStorage.getItem(this.EXPIRE_AT_STORAGE_NAME);
    if (expiration == null) return null;

    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt * 1000);
  }

  HasUserRight(rightCode: UserRightCodeEnum) {
    let rights = this.getUserRightsFromLocalStorage();
    if (!rights) return false;
    return rights.response[0].rights.find(x => x.RightCode == rightCode);
  }

  GetUserRights(userId: number): Observable<UserRightsResponse | null> {
    return this.http
      .get<UserRightsResponse>(`${environment.alliedApi.baseUrl}/UserPrivileges/userInfoRights?id=${userId}`)
      .pipe(tap(userRightResponse => this.setUserRights(userRightResponse)));
  }

  GetRegions(): Observable<GetRegionsResponse> {
    return this.http.get<GetRegionsResponse>(`${environment.alliedApi.baseUrl}/UserPrivileges/GetRegions`);
  }

  GetUserPrivileges() {
    let user = this.getUserFromLocalStorage();
    if (user?.UserID)
      return this.GetUser(user?.UserID).pipe(
        map(res => {
          let userResponse = res.response.map(user => Object.assign(new UserResponse(), user));
          return new UsersPrivilegeAPIResponse(res.status, userResponse, res.message);
        })
      );
    return null;
  }

  GetUser(userId: number) {
    return this.http.get<UsersPrivilegeAPIResponse>(
      `${environment.alliedApi.baseUrl}/UserPrivileges/user?id=${userId}`
    );
  }

  CheckUser(request: { UserID?: string; UserName: string }) {
    let { UserID = '', UserName } = request;
    return this.http.post<{ status: boolean; message: string }>(
      `${environment.alliedApi.baseUrl}/UserPrivileges/checkUser`,
      { UserID, UserName }
    );
  }

  CreateOrUpdateUser(request: AdminUserApiRequest) {
    return this.http.post<{ status: boolean; message: string }>(
      `${environment.alliedApi.baseUrl}/UserPrivileges/user`,
      request
    );
  }

  UnlockUser(userId: number){
    return this.http.post<{ status: boolean; response: any }>(
      `${environment.alliedApi.baseUrl}/Userctrl/unlockUser`,
      {
        UserId: userId,
        IsLockOut: false,
        Type: 'A'
      }
    );
  }

  ResetPassword(password: string, userId: string, userName: string, type: AuthenticationUserType, mpId?: number) {
    return this.http.post<{ status: boolean; message: string }>(
      `${environment.alliedApi.baseUrl}/Userctrl/resetpassword`,
      {
        resetpassword: password,
        UserId: Number(userId),
        Username: userName,
        _MasterProducerId: mpId,
        Type: type,
      }
    );
  }

  GetUserInfo() {
    let user = this.getUserFromLocalStorage();
    let headers = new HttpHeaders();

    if (this.IsAdmin()) headers = headers.set(InterceptorSkipRegionHeader, '');

    if (user?.UserID)
      return this.http
        .get<UsersAPIResponse>(`${environment.alliedApi.baseUrl}/Userctrl/user?id=${user.UserID}`, {
          headers: headers,
        })
        .pipe(
          map(res => {
            let userResponse = res.response.map(user => Object.assign(new UserResponse(), user));
            return new UsersAPIResponse(res.status, userResponse, res.message);
          }),
          tap(res => {
            console.log('userinfos');
            res.response.length && this.setUserInfos(res.response[0]);
          })
        );
    else console.error('user not found');
    return null;
  }

  GetToken() {
    let user = this.getUserFromLocalStorage();
    return user?.token;
  }

  PostSSOAuthentication(encryptedData: string) {
    let headers = new HttpHeaders();
    headers.append('InterceptorSkipRegionHeader', '');
    headers.append('Region', 'Admin');

    return this.http.post<SSOPostApiResponse>(
      `${environment.alliedApi.baseUrl}/authenticate/sso`,
      {
        payload: encryptedData,
      },
      {
        headers: headers,
      }
    ).pipe(tap(val => val.status && this.setToken(val.response[0])));;
  }

  GetSSOUrl() {
    let headers = new HttpHeaders();
    headers.append('InterceptorSkipRegionHeader', '');
    headers.append('Region', 'Admin');
    return this.http.get<SsoApiResponse>(`${environment.alliedApi.baseUrl}/authenticate/sso`, {
      headers: headers,
    });
  }

  GetCMSUrl() {
    let headers = new HttpHeaders();
    headers.append('InterceptorSkipRegionHeader', '');
    headers.append('Region', 'Admin');
    return this.http.get<SsoApiResponse>(`${environment.alliedApi.baseUrl}/authenticate/cms`, {
      headers: headers,
    });
  }

  GetSSOPrivilege() {
    let headers = new HttpHeaders();
    headers.append('InterceptorSkipRegionHeader', '');
    headers.append('Region', 'Admin');
    return this.http
      .get<SsoPrivilegeApiResponse>(`${environment.alliedApi.baseUrl}/authenticate/sso/privilege`, {
        headers: headers,
      })
      .pipe(
        tap(res => {
          if (res.Status) localStorage.setItem(this.SSO_PRIVILEGES, JSON.stringify(res.Data));
        })
      );
  }

  get SsoPrivilege() {
    let privilege = localStorage.getItem(this.SSO_PRIVILEGES);
    if (!privilege) return null;
    return JSON.parse(privilege) as SsoPrivilegeResponse;
  }

  private setToken(authenticationResponse: AuthenticationResponseResponse) {
    this.jwtTokenService.setToken(authenticationResponse.token);

    localStorage.setItem(this.AUTHENTICATION_RESPONSE_STORAGE_NAME, JSON.stringify(authenticationResponse));

    this.userSessionSubject.next(authenticationResponse);
    localStorage.setItem(this.EXPIRE_AT_STORAGE_NAME, JSON.stringify(moment().add(30, 'minutes').valueOf()));
  }

  private setUserRights(userRights: UserRightsResponse) {
    localStorage.setItem(this.USER_RIGHTS_STORAGE_NAME, JSON.stringify(userRights));
    this.userRightsSubject.next(userRights);
  }

  private getUserFromLocalStorage = () => {
    let item = localStorage.getItem(this.AUTHENTICATION_RESPONSE_STORAGE_NAME);
    if (item == null) return null;
    return JSON.parse(item) as AuthenticationResponseResponse;
  };

  private getUserRightsFromLocalStorage = () => {
    let item = localStorage.getItem(this.USER_RIGHTS_STORAGE_NAME);
    if (item == null) return null;
    return JSON.parse(item) as UserRightsResponse | null;
  };

  private getUserInfoFromLocalStorage = () => {
    let item = localStorage.getItem(this.USER_INFO_STORAGE_NAME);
    if (item == null) return null;
    return Object.assign(new UserResponse(), JSON.parse(item)) as UserResponse | null;
  };
  private setUserInfos(userResponse: UserResponse) {
    localStorage.setItem(this.USER_INFO_STORAGE_NAME, JSON.stringify(userResponse));
    this.userInfoSubject.next(userResponse);
  }
}
