<div style="display: flex; flex-direction: column; gap: 10px" *ngIf="dashData$ | withLoading | async  as dashData">

  <!-- Loading -->
  <div *ngIf="dashData.loading" ngClass="loading_spinner">
    <fa-icon [icon]="spinnerIcon" [spin]="true" size="8x" style="color: var(--brandColor)"></fa-icon>
  </div>

  <!-- Card Numbers -->
  <div style="display: flex; gap: 20px" *ngIf="dashData.value">
    <app-card-number title="Employers (Active)" [value]="userCountStatistics.employersCount"></app-card-number>

    <app-card-number title="Employees (Active)" [value]="userCountStatistics.employeeCount"></app-card-number>

    <app-card-number title="Dependents (Active)" [value]="userCountStatistics.dependentsCount"></app-card-number>
  </div>

  <!-- Statistics -->
  <div style="display: flex; gap: 20px" *ngIf="dashData.value">
    <div ngClass="chart_card" style="padding-top: 10px; flex: 1">
      <app-bar-chart
        [labels]="userLoginStatistics.labels"
        [series]="userLoginStatistics.series"
        [colors]="userLoginStatistics.colors"
        [title]="'User`s Login Statistics - ' + todayDate"></app-bar-chart>
    </div>
    <div ngClass="chart_card" style="padding-top: 10px; flex: 1">
      <app-donut-chart
        [labels]="totalEmployeesStatistics.labels"
        [series]="totalEmployeesStatistics.series"
        [colors]="totalEmployeesStatistics.colors"
        title="Total Employees Statistics"></app-donut-chart>
    </div>
  </div>
  <div ngClass="chart_card" style="padding-top: 10px; flex: 1" *ngIf="dashData.value">
    <app-line-chart title="Employee Enrollment Statistics for last 15 days" [series]="employeeEnrollmentStatistics.series"></app-line-chart>
  </div>
</div>
