import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, distinctUntilChanged, of, tap } from 'rxjs';
import AvailableStates from 'src/app/core/common/AvailableStates';
import { RegionCodes } from 'src/app/core/models/api/auth/responses/get-regions.response';
import { State, SupplyApiResponse, SupplyData, SupplyPlan } from 'src/app/core/models/api/supply/supply.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { SupplyService } from 'src/app/core/services/http/supply.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { FileHandle } from 'src/app/shared/directives/dragDrop.directive';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-add-edit-supply',
  styleUrls: ['./add-edit-supply.component.scss'],
  template: `
    <app-content-card CustomTitle="Add Supplies">
      <ng-container *ngIf="supplyData$ | withLoading | async as supplyData">
        <div ngClass="flex column gap" style="padding: 20px">
          <div style="align-self: flex-end;" ngClass="flex gap">
            <custom-button
              [forceCapitalization]="true"
              *ngIf="supplyData.value"
              [routerLink]="[manageSuppliesUrl]"
              style="align-self: flex-end;"
              color="secondary"
              label="BACK"></custom-button>
          </div>
        </div>

        <form [formGroup]="suppliesForm" ngClass="flex column gap" style="padding: 20px;">
          <app-form-input
            [loading]="supplyData.loading ?? false"
            [Wide]="true"
            title="Name"
            fieldName="name"
            formControlName="name"
            [parentForm]="suppliesForm"></app-form-input>
          <div ngClass="flex gap">
            <app-form-input
              [loading]="supplyData.loading ?? false"
              formControlName="limitsPerEmployer"
              style="flex: 1;"
              title="Limits Per Employer"
              [Wide]="true"
              fieldName="limitsPerEmployer"
              [parentForm]="suppliesForm"></app-form-input>
            <app-form-input
              [loading]="supplyData.loading ?? false"
              formControlName="limitsPerEmployee"
              style="flex: 1;"
              title="Limits Per Employee"
              [Wide]="true"
              fieldName="limitsPerEmployee"
              [parentForm]="suppliesForm"></app-form-input>
          </div>
          <app-form-input
            [loading]="supplyData.loading ?? false"
            formControlName="description"
            title="Description"
            [Wide]="true"
            fieldName="description"
            [parentForm]="suppliesForm"></app-form-input>

          Upload file:
          <app-skeleton-input *ngIf="supplyData.loading"></app-skeleton-input>
          <app-drag-drop-download
            *ngIf="supplyData.value"
            (onFileDropped)="onFileUploaded($event)"
            [errorMessage]="fileErrorMessage"
            [file]="file"></app-drag-drop-download>

          <div ngClass="flex gap">
            <span ngClass="flexFlow">Plan(s)</span>
            <app-skeleton-input *ngIf="supplyData.loading"></app-skeleton-input>
            <div ngClass="flexFlow" *ngIf="supplyData.value">
              <app-form-checkbox
                *ngFor="let planForm of planForms.controls; let indexOfElement = index"
                [parentForm]="planForms.at(indexOfElement)"
                [name]="planForms.at(indexOfElement).get('plan')?.value"
                [checked]="planForms.at(indexOfElement).get('active')?.value"
                fieldName="active"
                formGroupName="plans"></app-form-checkbox>
            </div>
          </div>
          <div ngClass="flex column gap">
            <app-skeleton-input *ngIf="supplyData.loading"></app-skeleton-input>
            <app-form-select
              formControlName="state"
              *ngIf="supplyData.value"
              [parentForm]="suppliesForm"
              [Options]="options"
              Label="State(s)"
              FieldName="state"
              [Multiple]="true"></app-form-select>
          </div>
          <div style="align-self: flex-end;" ngClass="flex gap">
            <custom-button
              [forceCapitalization]="true"
              *ngIf="supplyData.value"
              style="align-self: flex-end;"
              label="SAVE"
              (onClick)="onSave(suppliesForm.value, supplyData.value.Data)"></custom-button>
          </div>
        </form>
      </ng-container>
    </app-content-card>
  `,
})
export class AddEditSupplyComponent implements OnInit {
  suppliesForm: FormGroup;
  options: SelectOptions<any>[];
  actualRegion: string;
  supplyId: number;
  isEditting: boolean;
  file: FileHandle;
  fileErrorMessage: string;
  manageSuppliesUrl = RouteEnum.ManageSUpplies;

  supplyData$: Observable<SupplyApiResponse>;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private userViewService: UserViewService,
    private supplyService: SupplyService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.supplyId = Number(this.route.snapshot.paramMap.get('id'));
    this.isEditting = this.supplyId > 0;
    console.log('iseditting', this.isEditting);

    var view = this.userViewService.GetCurrentUserViewState();
    if (!view) console.log('view not found');
    this.actualRegion = view?.ActualRegion ?? '';

    this.suppliesForm = this.initForm({ plans: this.defaultFiltredPlanList } as SupplyForm);

    this.suppliesForm.get('state')?.addValidators(Validators.required);
    // this.suppliesForm.get('plans')?.addValidators(Validators.required);
    // this.suppliesForm.get('plans')?.addValidators(Validators.required);

    this.options = this.stateListFiltredByRegion.map<SelectOptions<any>>(state => ({
      key: state.StateName,
      optionText: state.StateName,
    }));

    if (this.isEditting) {
      this.supplyData$ = this.supplyService.GetSupply(this.supplyId).pipe(
        tap(supply => {
          let { LimitAbs, LimitRel, Description, Name, States, SupplyPlans, FileName } = supply.Data;

          let markedPlans = this.defaultFiltredPlanList.map(plan => {
            let hasPlan =
              SupplyPlans.findIndex(responsePlan => plan.planNumber === Number(responsePlan.PlanNumber)) >= 0;

            if (hasPlan) return { ...plan, active: true };
            return plan;
          });

          this.file = { file: new File(this.base64ToBlobParts(supply.Data.File), FileName ?? ''), url: '' };

          this.validateFileRequired();

          this.suppliesForm = this.initForm({
            description: Description,
            limitsPerEmployee: LimitRel + '',
            limitsPerEmployer: LimitAbs + '',
            name: Name,
            plans: markedPlans,
            state: States.map<string>(x => x.State),
            // uploadFile: '',
          } as SupplyForm);
        })
      );
    } else this.supplyData$ = of({} as SupplyApiResponse);

    this.changeHandler();
  }

  base64ToBlobParts(base64: string): BlobPart[] {
    if (base64 === null || base64 === undefined) return [];

    const binaryData = Buffer.from(base64, 'base64');
    const blob = new Blob([binaryData]);
    const chunkSize = 1024; // Chunk size in bytes

    const parts: BlobPart[] = [];
    let offset = 0;

    while (offset < blob.size) {
      const chunk = blob.slice(offset, offset + chunkSize);
      parts.push(chunk);
      offset += chunk.size;
    }

    return parts;
  }

  changeHandler() {
    let plansArray = (this.suppliesForm.get('plans') as FormArray).controls;

    plansArray.forEach(plan => {
      let planChanges = plan.get('active')?.valueChanges.pipe(distinctUntilChanged());

      planChanges &&
        planChanges.subscribe(plan => {
          if (plan === null) return;

          this.setPlansErrors(plan);
        });
    });
  }

  onSave(supplyForm: SupplyForm, supplyApiData: SupplyData) {
    this.suppliesForm.markAllAsTouched();

    this.validateSupplyPlans();

    this.validateFileRequired();

    if (this.suppliesForm.valid && this.isFileValid()) {
      var reader = new FileReader();
      var filerAsDataUrl;
      reader.readAsDataURL(this.file.file);
      reader.onload = () => {
        filerAsDataUrl = reader.result;

        let data = {
          ...supplyApiData,
          File: filerAsDataUrl,
          FileName: this.file.file.name,
          Description: supplyForm.description,
          LimitAbs: Number(supplyForm.limitsPerEmployer),
          LimitRel: Number(supplyForm.limitsPerEmployee),
          Name: supplyForm.name,
          SupplyPlans: supplyForm.plans.map<SupplyPlan>(x => ({
            Plan: x.plan,
            PlanNumber: x.planNumber.toString(),
            Status: x.active ? true : false,
          })),
          States: supplyForm.state.map<State>(x => ({ State: x })),
        } as SupplyData;

        this.supplyService.AddSupply(data).subscribe(res => {
          if (res.Status) {
            this.notificationService.success(
              res.Message ?? this.isEditting ? 'Supply edited Successfully' : 'Supply added Successfully'
            );
            this.router.navigateByUrl(RouteEnum.ManageSUpplies);
          } else
            this.notificationService.error(res.Message ?? 'Some error ocurred. Please, contact allied administrators');
        });
      };
    }
  }
  isFileValid() {
    if (this.file === null || this.file === undefined) return false;

    if (this.file.file === null || this.file.file === undefined) return false;

    if (this.file.file.size <= 0) return false;

    return true;
  }

  validateFileRequired() {
    //verify if file is actually filled

    if (this.file === undefined || this.file === null) {
      this.fileErrorMessage = 'File is required';
      return;
    } else if (this.file.file.size <= 0) {
      this.fileErrorMessage =
        'File is not loaded correctly, please upload the document again, or contact Allied Administrators support.';
      return;
    } else {
      this.fileErrorMessage = '';
    }
  }
  validateSupplyPlans() {
    let plansArray = (this.suppliesForm.get('plans') as FormArray).controls;

    let hasActivePlan = plansArray.some(plan => plan.value.active);

    this.setPlansErrors(hasActivePlan);
  }

  setPlansErrors(valid: boolean) {
    let plansArray = (this.suppliesForm.get('plans') as FormArray).controls;

    plansArray.forEach(plan => {
      let control = plan?.get('active');

      if (valid) {
        control?.setErrors({ customErrorMessage: null });
        control?.updateValueAndValidity();
      } else {
        plan?.get('active')?.setErrors({ customErrorMessage: 'At least one plan should be selected.' });
      }
    });
  }
  onFileUploaded(event: FileHandle) {
    this.file = event;
    this.validateFileRequired();
  }

  private initForm(supplyForm: SupplyForm) {
    let plans: FormArray<FormGroup> = this.fb.array<FormGroup>([]);

    supplyForm.plans.forEach(plan => {
      plans.push(
        this.fb.group({
          planNumber: [plan.planNumber],
          plan: [plan.plan],
          active: [plan.active],
        })
      );
    });

    return this.fb.group({
      name: [supplyForm.name, Validators.required],
      limitsPerEmployer: [supplyForm.limitsPerEmployer, Validators.required],
      limitsPerEmployee: [supplyForm.limitsPerEmployee, Validators.required],
      description: [supplyForm.description, Validators.required],
      plans: plans,
      state: [supplyForm.state],
    });
  }

  get planForms() {
    return this.suppliesForm.get('plans') as FormArray<FormGroup>;
  }

  get stateListFiltredByRegion() {
    var ddcaRule = (state: string): boolean => state === 'CA';
    var ddicRule = (state: string): boolean => ['AL', 'FL', 'GA', 'LA', 'MT', 'NV', 'TX', 'UT'].includes(state);
    var ddpaRule = (state: string): boolean => ['DC', 'DE', 'MD', 'NY', 'PA', 'WV'].includes(state);

    let getFilterByRegion = (state: string): boolean => {
      if (this.actualRegion === RegionCodes.DDCA) return ddcaRule(state);
      if (this.actualRegion === RegionCodes.DDIC) return ddicRule(state);
      if (this.actualRegion === RegionCodes.DDPA) return ddpaRule(state);
      return false;
    };

    return AvailableStates.filter(state => getFilterByRegion(state.StateName));
  }

  get defaultFiltredPlanList() {
    var ddicRule = (planNumber: number): boolean => 90 === planNumber;
    var ddnjRule = (planNumber: number): boolean => [91, 92].includes(planNumber);
    var ddpaRule = (planNumber: number): boolean => [93, 94].includes(planNumber);
    var ddcaRule = (planNumber: number): boolean => [95, 96].includes(planNumber);

    let getFilterByRegion = (planNumber: number): boolean => {
      if (this.actualRegion === RegionCodes.DDCA) return ddcaRule(planNumber);
      if (this.actualRegion === RegionCodes.DDIC) return ddicRule(planNumber);
      if (this.actualRegion === RegionCodes.DDPA) return ddpaRule(planNumber);
      if (this.actualRegion === RegionCodes.DDNJ) return ddnjRule(planNumber);
      return false;
    };
    return this.plansList.filter(plan => getFilterByRegion(plan.planNumber));
  }

  private plansList: Plan[] = [
    {
      planNumber: 90,
      plan: '90 - DELTA',
    },
    {
      planNumber: 91,
      plan: '91 - DeltaCare USA',
    },
    {
      planNumber: 92,
      plan: '92 -DELTA',
    },
    {
      planNumber: 93,
      plan: '93 - DeltaCare USA',
    },
    {
      planNumber: 94,
      plan: '94 - DELTA',
    },
    {
      planNumber: 95,
      plan: '95 - DeltaCare USA',
    },
    {
      planNumber: 96,
      plan: '96 - DELTA',
    },
  ];
}

type SupplyForm = {
  name: string;
  limitsPerEmployer: string;
  limitsPerEmployee: string;
  description: string;
  uploadFile: string;
  plans: Plan[];
  state: string[];
};

type Plan = {
  planNumber: number;
  plan: string;
  active?: boolean;
};
