<div ngClass="sign_up_container">
  <div ngClass="sign_up_navigation">
    <a
      class="go_back_link"
      (click)="showUserSelectType()"
      routerLink="/auth/signin">
      <fa-icon [icon]="faArrowLeft"></fa-icon>
    </a>
  </div>
  <div *ngIf="!ShowForm; else selectedTitle" ngClass="sign_up_title">
    Create a new account
  </div>
  <ng-template #selectedTitle>
    <div ngClass="sign_up_title">
      Create a new {{ GetUserTypeText(UserTypeSelected) }} account
    </div>
  </ng-template>
  <ng-template [ngIf]="UserTypeSelected === 'producer' && ShowForm">
    <app-master-producer-form></app-master-producer-form>
  </ng-template>
  <ng-template [ngIf]="UserTypeSelected === 'employer' && ShowForm">
    <app-employer-sign-up-form></app-employer-sign-up-form>
  </ng-template>

  <ng-template [ngIf]="!ShowForm">
    <div ngClass="sign_up_select_user_type_container">
      <app-select
        #select
        Label="Select user type"
        (onSelectOutput)="onSelectOutput(select.SelectedItem)"
        [value]="UserTypeSelected"
        [OptionList]="Options"></app-select>
      <button ngClass="sign_up_button" (click)="showForm()">Next</button>
    </div>
  </ng-template>
</div>
