import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, map, of, take, tap } from 'rxjs';
import { AdminUsersApiResponse } from 'src/app/core/models/api/user/admin-users.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { FileService } from 'src/app/core/services/file.service';
import { AdminUsersService } from 'src/app/core/services/http/admin-users.service';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-admin-users',
  styleUrls: ['./manage-admin-users.component.scss'],
  template: `
    <app-content-card CustomTitle="Admin Users">
      <div style="display: flex; justify-content: space-between; padding: 10px;">
        <app-radio-button
          [labels]="['Active', 'Disable', 'All']"
          [checked]="this.statusFilter.value"
          (onRadioButtonClick)="onStatusFilter($event)"></app-radio-button>
        <custom-button
          [forceCapitalization]="true"
          style="align-self: flex-end;"
          label="+ ADD NEW USER"
          [routerLink]="[addAdminUrl]"></custom-button>
      </div>
      <ng-container *ngIf="data$ | withLoading | async as data">
        <app-table
          (onSort)="onSort($event)"
          (onSearchEvent)="onSearch($event)"
          [EnableSearch]="true"
          (PaginationEvent)="onPagination($event)"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [Rows]="data.value"
          [IsLoading]="data.loading ?? false"
          [EnablePagination]="true"
          [Pagination]="pagination"
          [searchedText]="searchText.value"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionColumnTemplate let-id>
      <a [routerLink]="[editAdminUserUrl, id]">
        <fa-icon [icon]="editIcon" style="color: var(--brandColor);"></fa-icon>
      </a>
    </ng-template>

    <ng-template #checkboxColumnTemplate let-state>
      <app-checkbox [checked]="state" [disableAndKeepColor]="true"></app-checkbox>
    </ng-template>
  `,
})
export class ManageAdminUsersComponent implements OnInit {
  addAdminUrl = RouteEnum.AddAdminUser;
  editIcon = faPencil;
  editAdminUserUrl = RouteEnum.EditAdminUser;

  pagination: ManualPagination;
  searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  statusFilter: BehaviorSubject<string> = new BehaviorSubject<string>('Active');
  displayedColumns: DisplayedColumns[];
  downloadButtons: TableDownloadButtons;
  data$: Observable<AdminTable[]>;
  sortColumn?: string;
  sortDirection: 'asc' | 'dsc';

  //Column Templates
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('checkboxColumnTemplate', { static: true }) checkboxColumnTemplate: TemplateRef<unknown>;

  constructor(private adminUsersService: AdminUsersService, private fileService: FileService) {}

  ngOnInit(): void {
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              UserName: 'UserName',
              FirstName: 'FirstName',
              LastName: 'LastName',
              Disabled: 'Disabled',
              ManageCustomerProfile: 'Manage Customer Profile',
              ManageEmployeeData: 'Manage Employee Data',
              SupplyAdministration: 'Supply Administration',
              ManageInstructions: 'Manage Instructions',
              ManageUserRegistration: 'Manage User Registration',
              ManageEmailTemplate: 'Manage Email Template',
              ManageErUser: 'Manage Er User',
              DownloadLanveraList: 'Download Lanvera List',
              ManageMasterProducerEdit: 'Manage Master Producer Edit',
              ManageMasterProducerView: 'Manage Master Producer View',
              ManageMasterProducerUsers: 'Manage Master Producer Users',
              ManageEmailLog: 'Manage Email Log',
              ViewGA: 'View GA',
              EditGA: 'Edit GA',
              ManageRegionProducts: 'Manage Region Products',
              ManageWebAnalytical: 'Manage Web Analytical',
              ManageReports: 'Manage Reports',
              AccessToAlliedAdministratorsGroups: 'Access To Allied Administrators Groups',
              ManageEmails: 'Manage Emails',
              ManageEligibility: 'Manage Eligibility',
              Onboarding: 'Onboarding',
              OnboardingAdmin: 'Onboarding Admin',
              OnboardingSA: 'Onboarding SA',
              ManageXML: 'Manage XML',
              ManageEligibilityUpload: 'Manage Elibility Upload',
              replicationStatus: 'Replication Status',
              Cms: 'CMS',
              CmsAdmin: 'CMS Admin',
              TotalLogins: 'TotalLogins',
              FailedLogins: 'FailedLogins',
            },
          ];

          this.data$.pipe(take(1)).subscribe(res => {
            this.fileService
              .GeneratePDF('Allied Administrators - Manage Admin Users', header, this.mapDataToTable(res))
              .save('Allied Administrators - Manage Admin Users');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.data$.pipe(take(1)).subscribe(res => {
            this.fileService.GenerateXLSX('Allied Administrators - Manage Admin Users', this.mapDataToTable(res));
          });
        },
      },
    };

    this.displayedColumns = [
      { columnName: 'Action', label: 'Action', template: this.actionColTemplate, overrideValueFromColumn: 'UserId' },
      { columnName: 'UserName', label: 'UserName', sortable: true },
      { columnName: 'FirstName', label: 'First Name', sortable: true },
      { columnName: 'LastName', label: 'Last Name', sortable: true },
      { columnName: 'Disabled', label: 'Disabled' },
      { columnName: 'ManageCustomerProfile', label: 'Manage Customer Profile', template: this.checkboxColumnTemplate },
      { columnName: 'ManageEmployeeData', label: 'Manage Employee Data', template: this.checkboxColumnTemplate },
      { columnName: 'SupplyAdministration', label: 'Supply Administration', template: this.checkboxColumnTemplate },
      { columnName: 'ManageInstructions', label: 'Manage Instructions', template: this.checkboxColumnTemplate },
      {
        columnName: 'ManageUserRegistration',
        label: 'Manage User Registration',
        template: this.checkboxColumnTemplate,
      },
      { columnName: 'ManageEmailTemplate', label: 'Manage Email Template', template: this.checkboxColumnTemplate },
      { columnName: 'ManageErUser', label: 'Manage Er User', template: this.checkboxColumnTemplate },
      { columnName: 'DownloadLanveraList', label: 'Download Lanvera List', template: this.checkboxColumnTemplate },
      {
        columnName: 'ManageMasterProducerEdit',
        label: 'Manage Master Producer Edit',
        template: this.checkboxColumnTemplate,
      },
      {
        columnName: 'ManageMasterProducerView',
        label: 'Manage Master Producer View',
        template: this.checkboxColumnTemplate,
      },
      {
        columnName: 'ManageMasterProducerUsers',
        label: 'Manage Master Producer Users',
        template: this.checkboxColumnTemplate,
      },
      { columnName: 'ManageEmailLog', label: 'Manage Email Log', template: this.checkboxColumnTemplate },
      { columnName: 'ViewGA', label: 'View GA', template: this.checkboxColumnTemplate },
      { columnName: 'EditGA', label: 'Edit GA', template: this.checkboxColumnTemplate },
      { columnName: 'ManageRegionProducts', label: 'Manage Region Products', template: this.checkboxColumnTemplate },
      { columnName: 'ManageWebAnalytical', label: 'Manage Web Analytical', template: this.checkboxColumnTemplate },
      { columnName: 'ManageReports', label: 'Manage Reports', template: this.checkboxColumnTemplate },
      {
        columnName: 'AccessToAlliedAdministratorsGroups',
        label: 'Access To Allied Administrators Groups',
        template: this.checkboxColumnTemplate,
      },
      { columnName: 'ManageEmails', label: 'Manage Emails', template: this.checkboxColumnTemplate },
      { columnName: 'ManageEligibility', label: 'Manage Eligibility', template: this.checkboxColumnTemplate },
      { columnName: 'Onboarding', label: 'Onboarding', template: this.checkboxColumnTemplate },
      { columnName: 'OnboardingAdmin', label: 'Onboarding Admin', template: this.checkboxColumnTemplate },
      { columnName: 'OnboardingSA', label: 'Onboarding SA', template: this.checkboxColumnTemplate },
      { columnName: 'ManageXML', label: 'Manage XML', template: this.checkboxColumnTemplate },
      {
        columnName: 'ManageEligibilityUpload',
        label: 'Manage Elibility Upload',
        template: this.checkboxColumnTemplate,
      },
      { columnName: 'ReplicationStatus', label: 'Replication Status', template: this.checkboxColumnTemplate },
      { columnName: 'Cms', label: 'CMS', template: this.checkboxColumnTemplate },
      { columnName: 'CmsAdmin', label: 'CMS Admin', template: this.checkboxColumnTemplate },
      { columnName: 'TotalLogins', label: 'Total Logins' },
      { columnName: 'FailedLogins', label: 'Failed Logins' },
    ];

    this.data$ = this.adminUsersService.GetAllAdminUsers(0, 10, this.getFilterAsNumber).pipe(
      tap(res => {
        this.pagination = { Index: 0, ActualPage: 0, Total: res.recordsFiltered, PageSize: 10 };
      }),
      this.filterWhenApiReturnsException(),
      this.mapFields()
    );
  }

  onStatusFilter(statusFilter: string) {
    this.statusFilter.next(statusFilter);

    this.data$ = this.adminUsersService
      .GetAllAdminUsers(0, 10, this.getFilterAsNumber, this.searchText.value, this.sortDirection, this.sortColumn)
      .pipe(
        tap(res => {
          this.pagination = { Index: 0, ActualPage: 0, Total: res.recordsFiltered, PageSize: 10 };
        }),
        this.filterWhenApiReturnsException(),
        this.mapFields()
      );
  }

  onSort(sort: Sort) {
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction === 'asc' ? 'asc' : 'dsc';
    this.data$ = this.adminUsersService
      .GetAllAdminUsers(0, 10, this.getFilterAsNumber, this.searchText.value, this.sortDirection, this.sortColumn)
      .pipe(this.filterWhenApiReturnsException(), this.mapFields());
  }

  onPagination($event: PageEvent) {
    this.data$ = this.adminUsersService
      .GetAllAdminUsers(
        $event.pageIndex,
        $event.pageSize,
        this.getFilterAsNumber,
        this.searchText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        tap(res => {
          this.pagination = {
            Index: $event.pageIndex,
            ActualPage: $event.pageIndex,
            Total: res.recordsFiltered,
            PageSize: $event.pageSize,
          };
        }),
        this.filterWhenApiReturnsException(),
        this.mapFields()
      );
  }

  onSearch(searchedText: string) {
    this.searchText.next(searchedText);
    this.data$ = this.adminUsersService
      .GetAllAdminUsers(0, 10, this.getFilterAsNumber, searchedText, this.sortDirection, this.sortColumn)
      .pipe(
        tap(res => {
          this.pagination = { Index: 0, ActualPage: 0, Total: res.recordsFiltered, PageSize: 10 };
        }),
        this.filterWhenApiReturnsException(),
        this.mapFields()
      );
  }

  private mapFields() {
    return map<AdminUsersApiResponse, AdminTable[]>(res => {
      let adminTable = res.data.map<AdminTable>(adminUser => ({
        ManageCustomerProfile: adminUser.UserRights.findIndex(x => x.RightCode === 'EmployerEdit') >= 0,
        ManageEmployeeData: adminUser.UserRights.findIndex(x => x.RightCode === 'EmployeeEdit') >= 0,
        SupplyAdministration: adminUser.UserRights.findIndex(x => x.RightCode === 'SupplyAdmin') >= 0,
        ManageInstructions: adminUser.UserRights.findIndex(x => x.RightCode === 'ManageInstructions') >= 0,
        ManageUserRegistration: adminUser.UserRights.findIndex(x => x.RightCode === 'ManageUserRegistration') >= 0,
        ManageEmailTemplate: adminUser.UserRights.findIndex(x => x.RightCode === 'ManageEmailTemplate') >= 0,
        ManageErUser: adminUser.UserRights.findIndex(x => x.RightCode === 'ManageERUser') >= 0,
        DownloadLanveraList: adminUser.UserRights.findIndex(x => x.RightCode === 'LanveraDownload') >= 0,
        ManageMasterProducerEdit: adminUser.UserRights.findIndex(x => x.RightCode === 'MasterProducerEdit') >= 0,
        ManageMasterProducerView: adminUser.UserRights.findIndex(x => x.RightCode === 'MasterProducerView') >= 0,
        ManageMasterProducerUsers:
          adminUser.UserRights.findIndex(x => x.RightCode === 'ManageMasterProducerUsers') >= 0,
        ManageEmailLog: adminUser.UserRights.findIndex(x => x.RightCode === 'ManageEmailLog') >= 0,
        ViewGA: adminUser.UserRights.findIndex(x => x.RightCode === 'ViewGA') >= 0,
        EditGA: adminUser.UserRights.findIndex(x => x.RightCode === 'EditGA') >= 0,
        ManageRegionProducts: adminUser.UserRights.findIndex(x => x.RightCode === 'ProductManagement') >= 0,
        ManageWebAnalytical: adminUser.UserRights.findIndex(x => x.RightCode === 'WebAnalytical') >= 0,
        ManageReports: adminUser.UserRights.findIndex(x => x.RightCode === 'ReportsManagement') >= 0,
        AccessToAlliedAdministratorsGroups:
          adminUser.UserRights.findIndex(x => x.RightCode === 'AccessAlliedAdminGroups') >= 0,
        ManageEmails: adminUser.UserRights.findIndex(x => x.RightCode === 'ManageEmails') >= 0,
        ManageEligibility: adminUser.UserRights.findIndex(x => x.RightCode === 'ManageEligibility') >= 0,
        Onboarding: adminUser.UserRights.findIndex(x => x.RightCode === 'Onboarding') >= 0,
        OnboardingAdmin: adminUser.UserRights.findIndex(x => x.RightCode === 'OnboardingAdmin') >= 0,
        OnboardingSA: adminUser.UserRights.findIndex(x => x.RightCode === 'OnboardingSA') >= 0,
        ManageXML: adminUser.UserRights.findIndex(x => x.RightCode === 'ManageXML') >= 0,
        ManageEligibilityUpload: adminUser.UserRights.findIndex(x => x.RightCode === 'ManageEligibilityUpload') >= 0,
        ReplicationStatus: adminUser.UserRights.findIndex(x => x.RightCode === 'ReplicationStatus') >= 0,
        Cms: adminUser.UserRights.findIndex(x => x.RightCode === 'CMS') >= 0,
        CmsAdmin: adminUser.UserRights.findIndex(x => x.RightCode === 'CMSAdmin') >= 0,
        FailedLogins: adminUser.Failed_login,
        TotalLogins: adminUser.Total_login,
        UserName: adminUser.Username,
        FirstName: adminUser.FirstName,
        LastName: adminUser.LastName,
        UserId: adminUser.UserId + '',
        Disabled: adminUser.Disabled ? 'Y' : 'N',
      }));
      return adminTable as AdminTable[];
    });
  }

  private filterWhenApiReturnsException() {
    return map<AdminUsersApiResponse, AdminUsersApiResponse>(res => {
      // TODO Handling the error response that is returned with status 200 from the api. This should be fixed in the back end
      if (!res.data) return { data: [], recordsFiltered: 0, recordsTotal: 0 } as AdminUsersApiResponse;
      return res;
    });
  }

  private mapDataToTable(adminTable: AdminTable[]) {
    return adminTable.map(adminUser => ({
      UserId: adminUser.UserId,
      UserName: adminUser.UserName,
      FirstName: adminUser.FirstName,
      LastName: adminUser.LastName,
      Disabled: adminUser.Disabled,
      ManageCustomerProfile: adminUser.ManageCustomerProfile,
      ManageEmployeeData: adminUser.ManageEmployeeData,
      SupplyAdministration: adminUser.SupplyAdministration,
      ManageInstructions: adminUser.ManageInstructions,
      ManageUserRegistration: adminUser.ManageUserRegistration,
      ManageEmailTemplate: adminUser.ManageEmailTemplate,
      ManageErUser: adminUser.ManageErUser,
      DownloadLanveraList: adminUser.DownloadLanveraList,
      ManageMasterProducerEdit: adminUser.ManageMasterProducerEdit,
      ManageMasterProducerView: adminUser.ManageMasterProducerView,
      ManageProducerUsers: adminUser.ManageMasterProducerUsers,
      ManageEmailLog: adminUser.ManageEmailLog,
      ViewGA: adminUser.ViewGA,
      EditGA: adminUser.EditGA,
      ManageRegionProducts: adminUser.ManageRegionProducts,
      ManageWebAnalytical: adminUser.ManageWebAnalytical,
      ManageReports: adminUser.ManageReports,
      AccessToAlliedAdministratorsGroups: adminUser.AccessToAlliedAdministratorsGroups,
      ManageEmails: adminUser.ManageEmails,
      ManageEligibility: adminUser.ManageEligibility,
      Onboarding: adminUser.Onboarding,
      OnboardingAdmin: adminUser.OnboardingAdmin,
      OnboardingSA: adminUser.OnboardingSA,
      ManageXML: adminUser.ManageXML,
      ManageEligiblityUpload: adminUser.ManageEligibilityUpload,
      ReplicationStatus: adminUser.ReplicationStatus,
      Cms: adminUser.Cms,
      CmsAdmin: adminUser.CmsAdmin,
      TotalLogins: adminUser.TotalLogins,
      FailedLogins: adminUser.FailedLogins,
    }));
  }

  get getFilterAsNumber() {
    return (
      new Map<string, number>([
        ['Active', 1],
        ['Disable', 2],
        ['All', 3],
      ]).get(this.statusFilter.value) || 3
    );
  }
}

type AdminTable = {
  UserId: string;
  UserName: string;
  FirstName: string;
  LastName: string;
  Disabled: string;

  ManageCustomerProfile: boolean;
  ManageEmployeeData: boolean;
  SupplyAdministration: boolean;
  ManageInstructions: boolean;
  ManageUserRegistration: boolean;
  ManageEmailTemplate: boolean;
  ManageErUser: boolean;
  DownloadLanveraList: boolean;
  ManageMasterProducerEdit: boolean;
  ManageMasterProducerView: boolean;
  ManageMasterProducerUsers: boolean;
  ManageEmailLog: boolean;
  ViewGA: boolean;
  EditGA: boolean;
  ManageRegionProducts: boolean;
  ManageWebAnalytical: boolean;
  ManageReports: boolean;
  AccessToAlliedAdministratorsGroups: boolean;
  ManageEmails: boolean;
  ManageEligibility: boolean;
  Onboarding: boolean;
  OnboardingAdmin: boolean;
  OnboardingSA: boolean;
  ManageXML: boolean;
  ManageEligibilityUpload: boolean;
  ReplicationStatus: boolean;
  Cms: boolean;
  CmsAdmin: boolean;

  TotalLogins: number;
  FailedLogins: number;
};
