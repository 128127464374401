import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, from, lastValueFrom } from 'rxjs';
import { StaticPage } from 'src/app/core/helpers/view-helper';
import { ViewType } from 'src/app/core/models/user-view.model';
import { EmailService } from 'src/app/core/services/http/email.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-employer-home',
  templateUrl: './employer-home.component.html',
  styleUrls: ['../../home.component.scss'],
})
export class EmployerHomeComponent implements OnInit {
  staticPageAsync$: Observable<EmployersDashboardPagesHtmlAsString>;
  showAbsNewsFeed: boolean;

  @ViewChild('content') contentHtml: ElementRef;

  constructor(private emailService: EmailService, private viewService: UserViewService) {}

  ngOnInit() {
    this.loadEmployersHome();
  }

  private loadEmployersHome() {
    let employerDashboardLId = this.viewService.GetUserStaticPageIdByName(StaticPage.EmployerDashboardL);
    let employerDashboardRId = this.viewService.GetUserStaticPageIdByName(StaticPage.EmployerDashboardR);
    if (!employerDashboardLId || !employerDashboardRId) return;
    this.staticPageAsync$ = from(
      this.getEmployerDashboardPagesHtmlAsString(employerDashboardLId, employerDashboardRId)
    );
  }

  async getEmployerDashboardPagesHtmlAsString(
    erDashboardL: number,
    erDashboardR: number
  ): Promise<EmployersDashboardPagesHtmlAsString> {
    let employerDashboardL = (await lastValueFrom(this.emailService.GetDashboard(erDashboardL))).Data.TextEditor;
    let employerDashboardR = (await lastValueFrom(this.emailService.GetDashboard(erDashboardR))).Data.TextEditor;

    return { employerDashboardL, employerDashboardR };
  }
}
type EmployersDashboardPagesHtmlAsString = { employerDashboardL: string; employerDashboardR: string };
