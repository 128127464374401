import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fader } from 'src/app/route-animations';

@Component({
  selector: 'app-authentication',
  // templateUrl: './authentication.component.html',
  animations: [fader],
  styleUrls: ['./authentication.component.scss'],
  template: `
    <div ngClass="authentication-container">
      <div ngClass="authentication-header">
        <div ngClass="image-container">
          <a href="https://www.alliedadministrators.com/" style="cursor:pointer" target="_blank">
            <img ngClass="logo-header" src="assets/img/header.jpg" />
          </a>
        </div>
        <div ngClass="image-container">
          <a href="https://www1.deltadentalins.com/" style="cursor:pointer" target="_blank">
            <img ngClass="logo" src="assets/img/dd_logo.png" />
          </a>
        </div>
      </div>
      <div ngClass="authentication-background">
        <div ngClass="authentication-content" [@routeAnimations]="prepareRoute(outlet)">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
    </div>
  `,
})
export class AuthenticationComponent implements OnInit {
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  constructor() {}

  ngOnInit(): void {}
}
