<app-modal [open]="openMfaAskModal" [modalProps]="modalMfaProps" modalHeader="2FA Opt In">
  <div ngClass="mfaModal_inner">
    <span>Would you like to sign up for Two Factor Authentication?</span>
    <span>Emails will be sent to {{ mfaEmail }}</span>
  </div>
</app-modal>

<!-- TODO create password modal here -->
<!-- Integrar service nova que ta no outro navegador com o exemplo de req -->
<app-modal [open]="OpenPasswordModal.value" [modalProps]="newPasswordModal" modalHeader="Change Your Password">
  <div
    style="display: flex; flex-direction: column; gap: 10px; padding: 10px"
    ngClass="change_password_after_reset_wrapper">
    <form [formGroup]="changePasswordAfterResetForm">
      <app-form-input
        fieldName="newPassword"
        formControlName="newPassword"
        [parentForm]="changePasswordAfterResetForm"
        [enableVisibilityToggle]="true"
        [Wide]="true"
        type="password"></app-form-input>
      <span>* Password must have one uppercase, one lowercase,8 characters, 1 number and/or special character.</span>
    </form>
    <custom-button
      [forceCapitalization]="true"
      [IsLoading]="changePasswordAfterResetLoading"
      [disabled]="changePasswordAfterResetLoading"
      (onClick)="onChangePasswordAfterReset()"
      label="SAVE"
      style="align-self: flex-end"></custom-button>
  </div>
</app-modal>
<!-- <app-change-password [open]="OpenPasswordModal.value" (onClose)="onPasswordModalClose()"></app-change-password> -->

<div ngClass="signin_container">
  <div *ngIf="showRedirectDisplay">
    <div class="login_heading welcome redirect_container">
      <span>We are redirecting you back...</span>
      <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
    </div>
  </div>
  <div *ngIf="!showRedirectDisplay">
    <div ngClass="signin_inner_wrapper">
      <div ngClass="signin_welcome_text">Welcome to Delta Dental's Small Business Program</div>
      <ng-container *ngIf="!mfaEnabled; else showMfaForm">
        <form ngClass="signin_form " [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <app-form-input
            ngClass="form_item mat-field-dark-theme"
            type="text"
            [primary]="true"
            title="User Name"
            [Wide]="true"
            placeholder="username"
            formControlName="username"
            [parentForm]="loginForm"
            fieldName="username"
            (blur)="onTouched()">
          </app-form-input>
          <app-form-input
            ngClass="form_item mat-field-dark-theme"
            [primary]="true"
            [Wide]="true"
            type="password"
            title="Password"
            placeholder="password"
            fieldName="password"
            formControlName="password"
            [parentForm]="loginForm"
            (blur)="onTouched()">
          </app-form-input>

          <button ngClass="form_item sign_in_button" type="submit" class="btn">
            <span>LOGIN</span>
            <span *ngIf="loading | async">
              <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
            </span>
          </button>
          <div ngClass="links_wrapper">
            <a class="link_item" routerLink="/auth/signup">Register</a>
            <a class="link_item" routerLink="/auth/forgot-password">Forgot Password?</a>
          </div>
        </form>
      </ng-container>
      <ng-template #showMfaForm>
        <form ngClass="signin_form" [formGroup]="mfaForm" (ngSubmit)="onSubmitMfaDigits()">
          <app-form-input
            ngClass="form_item mat-field-dark-theme"
            type="text"
            [primary]="true"
            title="2FA 6 digit code"
            [Wide]="true"
            placeholder="mfaDigits"
            formControlName="mfaDigits"
            [parentForm]="mfaForm"
            fieldName="mfaDigits"
            (blur)="onTouched()">
          </app-form-input>

          <span style="font-size: 12px; font-weight: 400; color: white"
            >A 6 digit code have been sent to your email to verify your identity</span
          >

          <button ngClass="form_item sign_in_button" type="submit" class="btn">
            <span>SUBMIT</span>
            <span *ngIf="loading | async">
              <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
            </span>
          </button>
        </form>
      </ng-template>
    </div>

    <div ngClass="information_container">
      <div ngClass="information_text">Allied Administrators Website for Delta Dental's Small Business Program.</div>
      <div ngClass="information_text">
        Copyright 2023 Allied Administrators All Rights Reserved.
        <app-term-of-use-modal></app-term-of-use-modal>
        <!-- <a class="term_of_use_link" (click)="openTermOfUseModal = true">Term of Use</a> -->
      </div>

      <div ngClass="information_text">Software Version {{currentVersion | number: '1.1'}}</div>
    </div>
  </div>
</div>
