import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-forgot-password',
  styleUrls: ['./forgot-password.component.scss', '../../forms.scss', '../../styles.scss'],
  template: `
    <div ngClass="forgot_password_container">
      <div ngClass="navigation">
        <a class="go_back_link" routerLink="/auth/signin">
          <fa-icon [icon]="faArrowLeft"></fa-icon>
        </a>
      </div>
      <div ngClass="forgot_password_header">
        <span ngClass="title">Reset your password</span>
        <span ngClass="description"
          >Please enter your username and the email address we have on file for your account. We will then send you an
          email with a new password.</span
        >
      </div>
      <form
        ngClass="custom_form"
        [formGroup]="ForgotPasswordFormGroup"
        (ngSubmit)="OnSubmit(ForgotPasswordFormGroup.value)">
        <div ngClass="form_group_inline">
          <app-form-input
            fieldName="username"
            ngClass="form_item wide"
            title="User Name"
            [Wide]="true"
            placeholder="username"
            formControlName="username"
            [parentForm]="ForgotPasswordFormGroup">
          </app-form-input>
          <app-form-input
            fieldName="email"
            title="Your Email"
            ngClass="form_item wide"
            [Wide]="true"
            placeholder=" "
            formControlName="email"
            [parentForm]="ForgotPasswordFormGroup"></app-form-input>
        </div>
        <!-- <button ngClass="form_item forms_button wide" type="submit" class="btn">
          <span>Submit</span>
        </button> -->
        <custom-button
          [forceCapitalization]="true"
          ngClass="form_item forms_button"
          type="submit"
          class="btn"
          label="SUBMIT"
          [primary]="true"
          [disabled]="submitButtonDisabled"
          [IsLoading]="isLoading"
          (onClick)="OnSubmit(ForgotPasswordFormGroup.value)"></custom-button>
      </form>
    </div>
  `,
})
export class ForgotPasswordComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  submitButtonDisabled: boolean = false;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ForgotPasswordFormGroup: FormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  OnSubmit(values: ForgotPasswordForm) {
    if (this.ForgotPasswordFormGroup.valid) {
      this.isLoading = true;
      this.submitButtonDisabled = true;

      this.authService.Reset(values.username, values.email).subscribe(response => {
        let { status: passwordRequestedSuccessfully } = response;

        if (!passwordRequestedSuccessfully) {
          this.notificationService.error(response.message);
          this.isLoading = false;
          this.submitButtonDisabled = false;
          return;
        }

        this.notificationService.success(response.message);
        this.router.navigate(['/auth/signin']);
        this.isLoading = false;
        this.submitButtonDisabled = false;
      });
    }
  }
  ngOnInit() {}
}

interface ForgotPasswordForm {
  username: string;
  email: string;
}
