<app-modal [modalProps]="modalProps" [open]="openUploadDocumentModal" modalHeader="Select file to upload">
  <div ngClass="upload_modal">
    <app-drag-drop-download (onFileDropped)="onUploadDocument($event)"></app-drag-drop-download>
  </div>
</app-modal>
<app-content-card CustomTitle="Documents">
  <div ngClass="flex column">
    <div style="padding: 16px">
      <p>
        In addition to the downloadable documents available here, please visit our Supplies page for more documents
        <br />
        and resources including, but not limited to, the following:
      </p>
      <ul>
        <li>Enrollment forms</li>
        <li>Notices of Privacy Practices</li>
        <li>Instructions on how to find a dentist</li>
      </ul>
      <p>* Please note that the documents below are part of the initial Welcome Kit and are subject to change.</p>
    </div>
    <custom-button [forceCapitalization]="true"
      *ngIf="showButtons.ShowUploadDocumentButton"
      style="align-self: flex-end; padding: 10px"
      label="+ UPLOAD DOCUMENT"
      (onClick)="openModal()"></custom-button>
  </div>
  <div ngClass="flex column">
    <app-table
      [DisplayedColumns]="displayedColumns"
      [Rows]="isLoading ? undefined : rows$"
      [EnableLocalSearch]="true"
      [EnablePagination]="true"
      [EnableLocalSort]="true"
      [IsLoading]="isLoading"
      emptyRowsTextMessage="No data available in table"
      [EnableSearch]="true"></app-table>
    <custom-button [forceCapitalization]="true"
      *ngIf="showButtons.ShowSendNotificationButton"
      style="align-self: flex-start; padding: 10px"
      [label]="sendNotificationButtonLabel"
      (onClick)="onSendNotification()"></custom-button>
  </div>
</app-content-card>
<app-content-card CustomTitle="Archived Documents">
  <app-table
    [DisplayedColumns]="archivedDocumentsDisplayedColumns"
    [Rows]="isLoading ? undefined : archivedRows$"
    [EnableLocalSort]="true"
    [EnableLocalSearch]="true"
    emptyRowsTextMessage="No data available in table"
    [IsLoading]="isLoading"
    [EnableSearch]="true"></app-table>
</app-content-card>

<!-- VIEW JOURNAL -->
<div style="display:flex; justify-content: center;">

<custom-button [forceCapitalization]="true"
        *ngIf="shouldShowViewJournal()"
        label="VIEW JOURNAL" 
        [color]="'secondary'"
        [Wide]="false"
        (onClick)="openJournal = true"
        [icon]="historyIcon"></custom-button>
  </div>
  <app-view-journal [openJournal]="openJournal" [journalInput]="journalInput"></app-view-journal>


<ng-template #actionColumnTemplate let-data>
  <a (click)="onDownloadDocument(data.FileId, data.FileName)" matTooltip="Download">
    <i class="md-icon material-icons icon">cloud_download</i></a
  >
  <a *ngIf="showButtons.ShowDeleteRowButton" (click)="onDeleteFIle(data.FileId, data.FileName)" matTooltip="Delete"
    ><i class="md-icon material-icons icon">delete</i></a
  >
  <a *ngIf="showButtons.ShowArchiveRowButton" (click)="onArchiveFile(data.FileId, data.FileName)" matTooltip="Archive"
    ><i class="md-icon material-icons icon">archive</i></a
  >
</ng-template>

<ng-template #archivedActionColumnTemplate let-data>
  <a (click)="onDownloadDocument(data.FileId, data.FileName)" matTooltip="Download">
    <i class="md-icon material-icons icon">cloud_download</i></a
  >
  <a *ngIf="showButtons.ShowDeleteRowButton" (click)="onDeleteFIle(data.FileId, data.FileName)" matTooltip="Delete"
    ><i class="md-icon material-icons icon">delete</i></a
  >
  <a *ngIf="showButtons.ShowUnarchiveRowButton" (click)="onUnarchiveFile(data.FileId, data.FileName)" matTooltip="Unarchive"
    ><i class="md-icon material-icons icon">unarchive</i></a
  >
</ng-template>

<ng-template #typeColTemplate let-data>
  {{ data.split('.')[1].toUpperCase() }}
</ng-template>
<ng-template #fileNameColTemplate let-data>
  <a ngClass="fileName_link" (click)="onDownloadDocument(data.FileId, data.FileName)">{{ data.FileName }}</a>
</ng-template>
